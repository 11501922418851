/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';

interface PrinterOptions {
  port: string;
  emulation: string;
}

@Injectable({
  providedIn: 'root',
})
export class StarPrinterService {
  public async isConnected(options: PrinterOptions): Promise<any> {
    const connected = await this.connect(options);
    if (connected) {
      await this.disconnect();
    }
    return { isConnected: connected };
  }

  public portDiscovery(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (window.starprnt) {
        window.starprnt.portDiscovery(
          'All',
          success => {
            console.log(success);
            resolve(success);
          },
          error => {
            reject(error);
          }
        );
      } else {
        resolve([]);
      }
    });
  }

  public connect(options: PrinterOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      if (window.starprnt) {
        const { port, emulation } = options;
        window.starprnt.connect(port, emulation, false, (err, success) => {
          if (err) {
            reject(err);
          } else {
            resolve(success);
          }
        });
      } else {
        resolve(true);
      }
    });
  }

  public disconnect(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (window.starprnt) {
        window.starprnt.disconnect(
          success => {
            resolve(success);
          },
          error => {
            reject(error);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  public openCashDrawer(options: PrinterOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      if (window.starprnt) {
        window.starprnt.openCashDrawer(
          options.port,
          options.emulation,
          success => {
            resolve(success);
          },
          error => {
            reject(error);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  public print(commands: any[], options: PrinterOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      if (window.starprnt) {
        window.starprnt.print(
          options.port,
          options.emulation,
          commands,
          success => {
            resolve(success);
          },
          error => {
            reject(error);
          }
        );
      } else {
        reject({ message: 'Printer not connected. Please check port settings.' });
      }
    });
  }
}
