export enum FulfillmentMode {
  NONE = 'none',
  LOCATION = 'location',
  STATION = 'station',
  LOCATION_STATION = 'location-station',
}

export enum InventoryMode {
  NONE = 'none',
  LOCATION = 'location',
  STAND_SHEET = 'stand-sheet',
}
