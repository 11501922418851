/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { PhonePipe } from '@app/pipes/phone.pipe';
import {
  OrderData,
  OrderDestinationData,
  OrderedItem,
  OrderedItemModifier,
} from '../../interfaces';
import { PosPrinter } from '../../models';
import { DiningOptionsBehavior, PrinterPaperWidth, TransactionCardScheme } from '../../enums';
import {
  AlignmentPosition,
  CutPaperAction,
  FontStyleType,
  InternationalType,
  PrintCommand,
  PrintCommandType,
  QrCodeLevel,
  QRCodeModel,
} from './pinter.types';

export interface ReceiptOptions {
  header?: string;
  footer?: string;
  showTipOptions?: boolean;
  tipOptions?: number[];
  useTransactionAmountForTip?: boolean;
  useAdditionalTipText?: boolean;
}

export type PrintCommands = PrintCommand[];

@Injectable({
  providedIn: 'root',
})
export class ReceiptGeneratorService {
  constructor(
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private phonePipe: PhonePipe
  ) {}

  private static paperWidthFromPrinterModel(model: string): number {
    switch (model) {
      case 'SM-L200':
      case 'mPOP':
        return PrinterPaperWidth.MM32;
      default:
        return PrinterPaperWidth.MM48;
    }
  }

  private cleanText(text: string): string {
    if (!text) {
      return '';
    }
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * V2
   */
  public salesProps = [
    { prop: 'salesTotal', name: 'Sales' },
    { prop: 'discountTotal', name: 'Discounts' },
    { prop: 'taxTotal', name: 'Taxes' },
    { prop: 'gratuityTotal', name: 'Gratuities' },
    { prop: 'refundTotal', name: 'Refunds' },
    { prop: 'tipRefundTotal', name: 'Gratuity Refunds' },
  ];
  public transactionsSummary = [
    { prop: 'tenderName', name: 'Tender' },
    { prop: 'total', name: 'Total', type: 'currency' },
  ];
  public netTenderSummaryFields = [
    { prop: 'name', name: 'Tender' },
    { prop: 'amount', name: 'Net Total', type: 'currency' },
  ];
  public refundProps = [
    { prop: 'refunds', name: 'Refunds' },
    { prop: 'netTendered', name: 'Net Tendered' },
    { prop: 'tipRefunds', name: 'Refunded Gratuities' },
  ];

  currencyFromCents(amount: number): string {
    return this.currencyPipe.transform(amount * 0.01);
  }

  centerLine(width: number, text: string) {
    const diff = width - text.length;
    const spacer = this.createSpacer(diff * 0.5);
    return spacer + text;
  }

  getCustomerReceiptPaymentInfoV2(
    width: number,
    order: any,
    transaction: any,
    receiptConfig: ReceiptOptions
  ): PrintCommands {
    const commands: PrintCommands = [];
    if (!transaction) {
      return commands;
    }
    switch (transaction.cardScheme) {
      case TransactionCardScheme.CASH: {
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(
            width,
            'DATE',
            this.datePipe.transform(transaction.finishedAt, 'MM/dd/yyyy')
          ),
        });
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(
            width,
            'TIME',
            this.datePipe.transform(transaction.finishedAt, 'hh:mm a')
          ),
        });
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(
            width,
            'CASH',
            this.currencyFromCents(transaction.amount)
          ),
        });
        break;
      }
      case TransactionCardScheme.MEMBER: {
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(
            width,
            'DATE',
            this.datePipe.transform(transaction.finishedAt, 'MM/dd/yyyy')
          ),
        });
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(
            width,
            'TIME',
            this.datePipe.transform(transaction.finishedAt, 'hh:mm a')
          ),
        });
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(
            width,
            'MEMBER CHARGE',
            this.currencyFromCents(transaction.amount)
          ),
        });
        break;
      }
      case TransactionCardScheme.VISA:
      case TransactionCardScheme.MASTERCARD:
      case TransactionCardScheme.MAESTRO:
      case TransactionCardScheme.AMEX:
      case TransactionCardScheme.JCB:
      case TransactionCardScheme.DINERS:
      case TransactionCardScheme.DISCOVER: {
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(
            width,
            'DATE',
            this.datePipe.transform(transaction.finishedAt, 'MM/dd/yyyy')
          ),
        });
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(
            width,
            'TIME',
            this.datePipe.transform(transaction.finishedAt, 'hh:mm a')
          ),
        });
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(
            width,
            'TYPE',
            transaction.type.toUpperCase()
          ),
        });
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(
            width,
            'RESULT',
            transaction.result.toUpperCase()
          ),
        });
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(width, 'ACT #', transaction.maskPan),
        });
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(
            width,
            'MERCHANT REF',
            transaction.cardScheme.toUpperCase()
          ),
        });
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(
            width,
            'NAME ON CARD',
            transaction.nameOnCard || 'CARDHOLDER'
          ),
        });
        transaction.receiptDetails?.forEach((receiptLine: { label: string; value: string }) => {
          commands.push({
            [PrintCommandType.APPEND]: this.createTotalsLine(
              width,
              receiptLine.label.toUpperCase(),
              receiptLine.value.toUpperCase()
            ),
          });
        });
        commands.push({
          [PrintCommandType.APPEND]: this.createTotalsLine(
            width,
            'AMOUNT',
            this.currencyFromCents(transaction.amount || transaction.amountAuthorized)
          ),
        });
        break;
      }
    }
    const amount = receiptConfig.useTransactionAmountForTip
      ? transaction?.amountAuthorized
      : order.total;
    if (
      transaction &&
      receiptConfig.showTipOptions &&
      !transaction.gratuity &&
      order.orderDestination.behaviour === DiningOptionsBehavior.TABLE &&
      [
        TransactionCardScheme.VISA,
        TransactionCardScheme.MASTERCARD,
        TransactionCardScheme.MAESTRO,
        TransactionCardScheme.AMEX,
        TransactionCardScheme.JCB,
        TransactionCardScheme.DINERS,
        TransactionCardScheme.DISCOVER,
      ].includes(transaction.cardScheme)
    ) {
      commands.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
      commands.push({
        [PrintCommandType.APPEND]: this.createTotalsLine(
          width,
          '',
          `+ ${receiptConfig.useAdditionalTipText ? 'Additional ' : ''}Tip ________________`
        ),
      });
      commands.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
      commands.push({
        [PrintCommandType.APPEND]: this.createTotalsLine(width, '', '= Total ________________'),
      });
      commands.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
      commands.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
      commands.push({
        [PrintCommandType.APPEND]: this.centerLine(width, `x${'_'.repeat(width - 3)}`),
      });
      commands.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
      commands.push({ [PrintCommandType.APPEND]: this.centerLine(width, 'Cardholder Signature') });
      commands.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });

      const gratValues = receiptConfig.tipOptions || [18, 20, 22];
      commands.push({ [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.CENTER });
      commands.push({ [PrintCommandType.APPEND]: '-----------------' });
      commands.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
      commands.push({ [PrintCommandType.APPEND]: 'Suggested Tip Amount' });
      commands.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
      gratValues.forEach((grat: number) => {
        commands.push({
          [PrintCommandType.APPEND]: `${Math.ceil(grat)}% - ${this.currencyFromCents(
            amount * (grat / 100)
          )}`,
        });
        commands.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
      });
    }
    return commands;
  }

  private getHeader(
    width: number,
    order: any,
    skipAddress = false,
    shiftEmployeeName?: string
  ): PrintCommands {
    const commands: PrintCommands = [
      { [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.CENTER },
    ];
    if (!skipAddress) {
      commands.push(
        { [PrintCommandType.APPEND]: `${order.venue.name}\n` },
        { [PrintCommandType.APPEND]: `${order.venue.address.line1}\n` },
        {
          [PrintCommandType.APPEND]: `${order.venue.address.city}, ${order.venue.address.state}, ${order.venue.address.zip}\n`,
        },
        {
          [PrintCommandType.APPEND]: order.venue.phone
            ? `${this.phonePipe.transform(order.venue.phone)}\n`
            : '',
        },
        { [PrintCommandType.APPEND_LINE_FEED]: 1 }
      );
    }
    commands.push(
      { [PrintCommandType.APPEND]: order.guest?.name ? `Customer: ${order.guest.name}\n` : '' },
      { [PrintCommandType.APPEND_LINE_FEED]: 1 },
      { [PrintCommandType.APPEND]: `Server: ${shiftEmployeeName || 'Unknown'}\n` },
      { [PrintCommandType.APPEND_LINE_FEED]: 1 },
      {
        [PrintCommandType.APPEND]: `${this.createOrderDestination(
          order.orderDestination,
          width
        )}\n`,
      },
      {
        [PrintCommandType.APPEND]: this.createTotalsLine(
          width,
          this.datePipe.transform(order.created, 'MM/dd/yyyy'),
          this.datePipe.transform(order.created, 'hh:mm:ss a')
        ),
      },
      { [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.LEFT }
    );
    return commands;
  }

  private getOrderedItems(
    width: number,
    receiptConfig: ReceiptOptions,
    order: any,
    transaction?: any
  ): PrintCommands {
    return [
      { [PrintCommandType.APPEND]: `Description${this.createSpacer(width - 16)}Total\n` },
      ...order.items.map((item: OrderedItem) => {
        return {
          [PrintCommandType.APPEND]: `${this.createLineItem(
            width,
            item.quantity,
            item.name,
            this.currencyFromCents(item.price),
            item.modifiers
          )}\n`,
        };
      }),
      { [PrintCommandType.APPEND_LINE_FEED]: 1 },
      {
        [PrintCommandType.APPEND]: `${this.createTotalsLine(
          width,
          'Subtotal:',
          this.currencyFromCents(order.subTotal)
        )}\n`,
      },
      {
        [PrintCommandType.APPEND]: `${this.createTotalsLine(
          width,
          'Discount:',
          this.currencyFromCents(order.discount)
        )}\n`,
      },
      {
        [PrintCommandType.APPEND]: `${this.createTotalsLine(
          width,
          'Tax:',
          this.currencyFromCents(order.taxTotal + order.taxIncludedTaxTotal)
        )}\n`,
      },
      {
        [PrintCommandType.APPEND]: `${this.createTotalsLine(
          width,
          'Service Fee:',
          this.currencyFromCents(order.serviceFee)
        )}\n`,
      },
      {
        [PrintCommandType.APPEND]: `${this.createTotalsLine(
          width,
          'Gratuity:',
          this.currencyFromCents(order.gratuity)
        )}\n`,
      },
      ...this.createTotalRefundLine(
        width,
        order.total,
        order.refundTotal,
        order,
        transaction,
        receiptConfig
      ),
    ];
  }

  public receipt(
    printer: PosPrinter,
    receiptConfig: ReceiptOptions,
    order: any,
    employee?: any,
    transaction?: any
  ): PrintCommands {
    const width =
      printer.paperWidth || ReceiptGeneratorService.paperWidthFromPrinterModel(printer.model) || 32;

    return [
      { [PrintCommandType.APPEND_INTERNATIONAL]: InternationalType.USA },
      { [PrintCommandType.APPEND_FONT_STYLE]: FontStyleType.FONT_A },
      {
        [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.CENTER,
      },
      {
        [PrintCommandType.APPEND]: `Order: ${order.orderNumber}`,
      },
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      ...this.getHeader(width, order, false, employee?.name),
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      ...this.getOrderedItems(width, receiptConfig, order, transaction),
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      { [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.CENTER },
      {
        [PrintCommandType.APPEND]: receiptConfig.footer
          ? `${this.breakWord(receiptConfig.footer, width, false).join('\n')}`
          : '',
      },
      { [PrintCommandType.APPEND_LINE_FEED]: receiptConfig.footer ? 2 : 0 },
      {
        [PrintCommandType.APPEND]: `Powered By\nPartake\n\nhttps://partake.golf`,
      },
      { [PrintCommandType.APPEND_CUT_PAPER]: CutPaperAction.FULL_CUT_WITH_FEED },
    ];
  }

  public getTestReceipt(printer: PosPrinter): PrintCommands {
    const width =
      printer.paperWidth || ReceiptGeneratorService.paperWidthFromPrinterModel(printer.model) || 32;
    return [
      { [PrintCommandType.APPEND_INTERNATIONAL]: InternationalType.USA },
      { [PrintCommandType.APPEND_FONT_STYLE]: FontStyleType.FONT_A },
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      { [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.CENTER },
      { [PrintCommandType.APPEND]: 'Test Receipt' },
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      {
        [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.LEFT,
        data: this.createLineItem(width, 1, 'Test Item', this.currencyFromCents(100), []),
      },
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      {
        [PrintCommandType.APPEND_QR_CODE]: 'https://partaketechnologies.zendesk.com/',
        QrCodeModel: QRCodeModel.NO_2,
        QrCodeLevel: QrCodeLevel.H,
        cell: 40,
        alignment: AlignmentPosition.CENTER,
      },
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      {
        [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.CENTER,
      },
      {
        [PrintCommandType.APPEND]: `Powered By\nPartake\n\nhttps://partake.golf`,
      },
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      { [PrintCommandType.APPEND_CUT_PAPER]: CutPaperAction.FULL_CUT_WITH_FEED },
    ];
  }

  /**
   *
   * @param printer the printer for sizing
   * @param order the order to be printed
   * @param station the station to filter items if necessary
   * @param ignoreSaved for partial printing of items
   */
  public kitchenReceipt(
    printer: PosPrinter,
    order: OrderData,
    station: string,
    ignoreSaved: boolean,
    employee?: any
  ): PrintCommands {
    const commands: PrintCommands = [];
    if (!order?.items) {
      return commands;
    }
    const width =
      printer.paperWidth || ReceiptGeneratorService.paperWidthFromPrinterModel(printer.model) || 32;

    const latestSendEventId = order.latestSendEventId;

    let items = order.items.filter(item => {
      if (ignoreSaved && item.sendEventId === latestSendEventId) {
        return true;
      }
      if (ignoreSaved && item.sendEventId !== latestSendEventId) {
        return false;
      }
      return true;
    });

    if (!items.length) {
      return commands;
    }

    if (station) {
      items = items.filter((item: OrderedItem) => {
        // Print based on station
        if (item.stations.indexOf(station) > -1) {
          return true;
        }
        return false;
      });
    }

    if (!items.length) {
      return commands;
    }

    const lineItems = [];

    items.forEach((item: OrderedItem) => {
      lineItems.push(
        ...this.createKitchenLineItem(
          width,
          item.quantity,
          item.name,
          item.modifiers,
          item.instructions
        )
      );
    });

    return [
      { [PrintCommandType.APPEND_INTERNATIONAL]: InternationalType.USA },
      { [PrintCommandType.APPEND_FONT_STYLE]: FontStyleType.FONT_A },
      {
        [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.CENTER,
      },
      {
        [PrintCommandType.APPEND_EMPHASIS]: 'Order',
      },
      { [PrintCommandType.APPEND_LINE_FEED]: 1 },
      {
        [PrintCommandType.APPEND_EMPHASIS]: `${order.orderNumber}`,
      },
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      ...this.getHeader(width, order, true, employee?.name),
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      {
        [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.LEFT,
      },
      ...lineItems,
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      { [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.CENTER },
      { [PrintCommandType.APPEND_CUT_PAPER]: CutPaperAction.FULL_CUT_WITH_FEED },
    ];
  }

  /**
   *
   * @param printer the printer for sizing
   * @param shift the shift we are printing for
   * @param reportData the summary for the shift we are printing
   */
  public shiftReport(printer: PosPrinter, shift: any, reportData: any): PrintCommands {
    const width =
      printer.paperWidth || ReceiptGeneratorService.paperWidthFromPrinterModel(printer.model) || 32;

    const lineItems = this.createShiftLineItems(width, shift, reportData);
    if (!lineItems.length) {
      return [];
    }

    return [
      { [PrintCommandType.APPEND_INTERNATIONAL]: InternationalType.USA },
      { [PrintCommandType.APPEND_FONT_STYLE]: FontStyleType.FONT_A },
      {
        [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.CENTER,
      },
      {
        [PrintCommandType.APPEND]: `Shift: ${shift.shiftId}`,
      },
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      {
        [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.LEFT,
      },
      {
        [PrintCommandType.APPEND]: `Opened: ${this.datePipe.transform(
          shift.openTime,
          'MM/dd/yyyy hh:mm:ss a'
        )}`,
      },
      { [PrintCommandType.APPEND_LINE_FEED]: 1 },
      {
        [PrintCommandType.APPEND]: `Closed: ${this.datePipe.transform(
          shift.closeTime,
          'MM/dd/yyyy hh:mm:ss a'
        )}`,
      },
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      ...lineItems,
      { [PrintCommandType.APPEND_LINE_FEED]: 2 },
      { [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.CENTER },
      { [PrintCommandType.APPEND_CUT_PAPER]: CutPaperAction.FULL_CUT_WITH_FEED },
    ];
  }

  private createOrderDestination(orderDestination: OrderDestinationData, width: number): string {
    if (!orderDestination) {
      return null;
    }
    const parts = [];
    if (orderDestination.behaviour) {
      parts.push(...this.breakWord(orderDestination.behaviour, width, false));
    }
    if (
      orderDestination.behaviour === DiningOptionsBehavior.TABLE &&
      orderDestination.location?.option4
    ) {
      if (orderDestination.location?.option2) {
        parts.push(...this.breakWord(orderDestination.location.option2, width, false));
      }
      if (orderDestination.location?.option4) {
        parts.push(...this.breakWord(orderDestination.location.option4, width, false));
      }
    } else {
      if (orderDestination.location?.option1) {
        parts.push(...this.breakWord(orderDestination.location.option1, width, false));
      }
      if (orderDestination.location?.option2) {
        parts.push(...this.breakWord(orderDestination.location.option2, width, false));
      }
      if (orderDestination.location?.option3) {
        parts.push(...this.breakWord(orderDestination.location.option3, width, false));
      }
      if (orderDestination.location?.option4) {
        parts.push(...this.breakWord(orderDestination.location.option4, width, false));
      }
    }
    if (orderDestination.selectedDate) {
      parts.push(
        ...this.breakWord(`Selected Date: ${orderDestination.selectedDate}`, width, false)
      );
    }
    if (orderDestination.selectedTimeString) {
      parts.push(
        ...this.breakWord(`Selected Time: ${orderDestination.selectedTimeString}`, width, false)
      );
    }
    return parts.join('\n');
  }

  private createTotalsLine(width: number, title: string, value: string) {
    const titleLen = title.length;
    const valueLen = value.length;
    const len = titleLen + valueLen;
    const diff = width - len;
    const spacer = this.createSpacer(diff);
    return title + spacer + value;
  }

  private createTotalRefundLine(
    width: number,
    total: number,
    refundTotal: number,
    order: any,
    transaction: any,
    receiptConfig: ReceiptOptions
  ): PrintCommands {
    const commands: PrintCommands = [];

    commands.push({
      [PrintCommandType.APPEND]: this.createTotalsLine(
        width,
        'Total:',
        this.currencyFromCents(total)
      ),
    });
    if (refundTotal) {
      commands.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
      commands.push({
        [PrintCommandType.APPEND]: `${this.createTotalsLine(
          width,
          'Refund:',
          this.currencyFromCents(refundTotal)
        )}`,
      });
      commands.push({
        [PrintCommandType.APPEND]: `${this.createTotalsLine(
          width,
          'New Total:',
          this.currencyFromCents(total - refundTotal)
        )}`,
      });
    }

    if (transaction) {
      commands.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
      const transactionInfo = this.getCustomerReceiptPaymentInfoV2(
        width,
        order,
        transaction,
        receiptConfig
      );
      if (transactionInfo.length) {
        commands.push(...transactionInfo);
      }
    }
    return commands;
  }

  private createLineItem(
    width: number,
    quantity: number | string,
    description: string,
    value: string,
    modifiers: OrderedItemModifier[] = []
  ): string {
    const qLen = quantity.toString().length;
    const valueLen = value.length;
    const qSpacer = this.createSpacer(5 - qLen);
    const desc = this.breakWord(description, width - 15);
    const valSpacer = this.createSpacer(width - 5 - desc[0].length - valueLen);
    let text = `${quantity}${qSpacer}${desc[0]}${valSpacer}${value}`;
    if (desc.length > 1) {
      for (let i = 1; i < desc.length; i++) {
        text += `\n${desc[i]}`;
      }
    }
    modifiers?.forEach(modifier => {
      text += `\n${this.createSpacer(5)}+ ${this.breakWord(
        modifier.name?.toUpperCase(),
        width - 5
      ).join('\n')}`;
    });
    return this.cleanText(text);
  }

  private createKitchenLineItem(
    width: number,
    quantity: number,
    description: string,
    modifiers: OrderedItemModifier[] = [],
    instructions: string
  ): PrintCommands {
    const qLen = quantity.toString().length;
    const qSpacer = this.createSpacer(5 - qLen);
    const desc = this.breakWord(description, width - 6);
    let text = `${quantity}${qSpacer}${desc[0]}`;
    if (desc.length > 1) {
      for (let i = 1; i < desc.length; i++) {
        text += `\n${desc[i]}`;
      }
    }
    const commands: PrintCommands = [];
    commands.push({
      [PrintCommandType.APPEND]: this.cleanText(text).toUpperCase(),
    });
    commands.push({
      [PrintCommandType.APPEND_LINE_FEED]: 1,
    });
    let modifierLineText = '';
    modifiers?.forEach((modifier, i) => {
      modifierLineText += `${i ? '\n' : ''}${this.createSpacer(5)}+ ${this.breakWord(
        this.cleanText(modifier.name?.toUpperCase()),
        width - 5
      ).join('\n')}`;
    });
    commands.push({
      [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition.LEFT,
    });
    if (modifierLineText) {
      commands.push({
        [PrintCommandType.APPEND_INVERT]: modifierLineText,
      });
    }
    if (instructions) {
      commands.push({
        [PrintCommandType.APPEND_INVERT]: `\n${this.createSpacer(5)}-  ${this.breakWord(
          this.cleanText(instructions),
          width - 5
        ).join('\n')}`,
      });
    }
    if (modifierLineText || instructions) {
      commands.push({
        [PrintCommandType.APPEND_LINE_FEED]: 2,
      });
    } else {
      commands.push({
        [PrintCommandType.APPEND_LINE_FEED]: 1,
      });
    }
    return commands;
  }

  private createShiftLineItems(width: number, shift: any, summary: any): PrintCommands {
    const items: PrintCommands = [];
    const salesSummary = summary.salesSummary || {};
    const transactions = summary.saleTransactionSummaries || {};
    const refunds = summary.refundTransactionSummaries || {};
    const netTenderSummary = summary.netTenderSummary || {};
    const menuItemCategorySummaries = summary.menuItemCategorySummaries || [];
    const modifierCategorySummaries = summary.modifierCategorySummaries || [];
    const itemsSummaries = summary.itemsSummaries || [];
    const modifiersSummmaries = summary.modifiersSummmaries || [];

    // ORDER TYPES
    this.salesProps.forEach(saleProp => {
      if (salesSummary[saleProp.prop]) {
        if (salesSummary[saleProp.prop]) {
          items.push({
            [PrintCommandType.APPEND]: this.createReportLine(
              width,
              saleProp.name,
              this.currencyFromCents(salesSummary[saleProp.prop])
            ),
          });
          items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
        }
      }
    });
    items.push({
      [PrintCommandType.APPEND]: this.createReportLine(
        width,
        '# Orders',
        `${salesSummary.orderCount}`
      ),
    });
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });

    // OPEN CLOSE
    if (shift.openingBy && shift.closeBy) {
      items.push({ [PrintCommandType.APPEND]: 'Opened By' });
      items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
      items.push({
        [PrintCommandType.APPEND]: `${shift.openingBy.employeeId} ${shift.openingBy.name}`,
      });
      items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
      items.push({ [PrintCommandType.APPEND]: 'Closed By' });
      items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
      items.push({
        [PrintCommandType.APPEND]: `${shift.closeBy.employeeId} ${shift.closeBy.name}`,
      });
    } else if (shift.employee) {
      items.push({ [PrintCommandType.APPEND]: 'Employee' });
      items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
      items.push({
        [PrintCommandType.APPEND]: `${shift.employee.employeeId} ${shift.employee.name}`,
      });
    }

    // Sales Tender SUMMARY
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
    items.push({ [PrintCommandType.APPEND]: 'Transactions By Tender' });
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    transactions.forEach((tender: { tenderName: string; total: number }) => {
      const value = this.currencyFromCents(tender.total);
      items.push({
        [PrintCommandType.APPEND]: this.createReportLine(width, tender.tenderName, value),
      });
      items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    });
    if (!transactions.length) {
      items.push({ [PrintCommandType.APPEND]: 'No Sales' });
    }
    // Sales Tender SUMMARY
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
    items.push({ [PrintCommandType.APPEND]: 'Refunds By Tender' });
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    refunds.forEach((tender: { tenderName: string; total: number }) => {
      const value = this.currencyFromCents(tender.total);
      items.push({
        [PrintCommandType.APPEND]: this.createReportLine(width, tender.tenderName, value),
      });
      items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    });
    if (!refunds.length) {
      items.push({ [PrintCommandType.APPEND]: 'No Refunds' });
      items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    }
    // Sales Tender SUMMARY
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    items.push({ [PrintCommandType.APPEND]: 'Net Tenders' });
    netTenderSummary.forEach((tender: { name: string; amount: number }) => {
      const value = this.currencyFromCents(tender.amount);
      items.push({ [PrintCommandType.APPEND]: this.createReportLine(width, tender.name, value) });
      items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    });
    if (!netTenderSummary.length) {
      items.push({ [PrintCommandType.APPEND]: 'No Sales' });
      items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    }

    // CATEGORY SALES SUMMARY
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    items.push({ [PrintCommandType.APPEND]: 'Item Category Sales Summary' });
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
    items.push({
      [PrintCommandType.APPEND]: this.createLineItem(width, 'Qty', 'Category', 'Sales'),
    });
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    menuItemCategorySummaries.forEach(
      (item: { name: string; quantity: number; salesTotal: number; discountTotal: number }) => {
        const sales = item.salesTotal - item.discountTotal;
        const value = this.currencyFromCents(sales);
        items.push({
          [PrintCommandType.APPEND]: this.createLineItem(width, item.quantity, item.name, value),
        });
        items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
      }
    );
    if (!menuItemCategorySummaries.length) {
      items.push({ [PrintCommandType.APPEND]: 'No Sales' });
      items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    }
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    items.push({ [PrintCommandType.APPEND]: 'Mofifier Category Sales Summary' });
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
    items.push({
      [PrintCommandType.APPEND]: this.createLineItem(width, 'Qty', 'Category', 'Sales'),
    });
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    modifierCategorySummaries.forEach(
      (item: { name: string; quantity: number; salesTotal: number; discountTotal: number }) => {
        const sales = item.salesTotal - item.discountTotal;
        const value = this.currencyFromCents(sales);
        items.push({
          [PrintCommandType.APPEND]: this.createLineItem(width, item.quantity, item.name, value),
        });
        items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
      }
    );
    if (!modifierCategorySummaries.length) {
      items.push({ [PrintCommandType.APPEND]: 'No Sales' });
      items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    }

    // ITEM SALES SUMMARY
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    items.push({ [PrintCommandType.APPEND]: 'Item Product Sales Summary' });
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
    items.push({
      [PrintCommandType.APPEND]: this.createLineItem(width, 'Qty', 'Product', 'Sales'),
    });
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    itemsSummaries.forEach(
      (item: { name: string; quantity: number; salesTotal: number; discountTotal: number }) => {
        const sales = item.salesTotal - item.discountTotal;
        const value = this.currencyFromCents(sales);
        items.push({
          [PrintCommandType.APPEND]: this.createLineItem(width, item.quantity, item.name, value),
        });
        items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
      }
    );
    if (!itemsSummaries.length) {
      items.push({ [PrintCommandType.APPEND]: 'No Sales' });
      items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    }
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    items.push({ [PrintCommandType.APPEND]: 'Mofifier Product Sales Summary' });
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 2 });
    items.push({
      [PrintCommandType.APPEND]: this.createLineItem(width, 'Qty', 'Product', 'Sales'),
    });
    items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    modifiersSummmaries.forEach(
      (item: { name: string; quantity: number; salesTotal: number; discountTotal: number }) => {
        const sales = item.salesTotal - item.discountTotal;
        const value = this.currencyFromCents(sales);
        items.push({
          [PrintCommandType.APPEND]: this.createLineItem(width, item.quantity, item.name, value),
        });
        items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
      }
    );
    if (!modifiersSummmaries.length) {
      items.push({ [PrintCommandType.APPEND]: 'No Sales' });
      items.push({ [PrintCommandType.APPEND_LINE_FEED]: 1 });
    }
    return items;
  }

  private createReportLine(width: number, desc: string, value: string) {
    const spacer = this.createSpacer(width - desc.length - value.length);
    return desc + spacer + value;
  }

  private createSpacer(val: number) {
    if (!val) {
      return '';
    }
    return ' '.repeat(val);
  }

  private breakWord(content: string, maxLineLength: number, addSpace = true): string[] {
    const lines: string[] = [];
    if (!content) {
      return lines;
    }
    const words = content.split(' ');
    let text = '';
    let lineLength = 0;
    words.forEach(word => {
      const wordLength = word.length;
      if (lineLength + wordLength > maxLineLength) {
        lines.push(text);
        text = `${addSpace ? '     ' : ''}${word} `;
        lineLength = text.length;
      } else {
        text += `${word} `;
        lineLength = text.length;
      }
    });
    lines.push(text);
    return lines;
  }
}
