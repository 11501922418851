import { Pipe, PipeTransform } from '@angular/core';
import { DiningOptionsBehavior } from '../enums';

@Pipe({
  name: 'orderType',
})
export class OrderTypePipe implements PipeTransform {
  transform(type: DiningOptionsBehavior): string {
    let orderType = '';
    switch (type) {
      case DiningOptionsBehavior.PICKUP:
        orderType = 'Pickup';
        break;
      case DiningOptionsBehavior.DELIVERY:
        orderType = 'Delivery';
        break;
      case DiningOptionsBehavior.FAST_CLOSE:
        orderType = 'Fast Close';
        break;
      case DiningOptionsBehavior.TAB:
        orderType = 'Tab';
        break;
      case DiningOptionsBehavior.TABLE:
        orderType = 'Table';
        break;
    }
    return orderType;
  }
}
