import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ToastController } from '@ionic/angular';
import * as Sentry from '@sentry/angular';
import { OrderData, RealmEvent } from '../../../interfaces';
import { LocationData, Order, Venue } from '../../../models';
import {
  addOrder,
  clearOrders,
  loadOrders,
  newMessageForOrder,
  removeOrder,
  setOrders,
  updateOrder,
} from './open-order.actions';
import { AppState, errorHandler } from '../app.state';
import { OrderService } from '../../../providers/order/order.service';
import {
  OrderSocketService,
  SocketQuery,
  SocketResponse,
} from '../../../providers/order/order-socket.service';
import { SupportService } from '../../../providers/support/support.service';
import { BeepService } from '../../../providers/audio/beep.service';
import { LoadingService } from '../../../providers/general/loading.service';
import { OrderStatus } from '../../../enums';
import { RealmService } from '../../../providers';

@Injectable()
export class OpenOrderEffects {
  loadOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrders),
      mergeMap((payload: { location: LocationData; venue: Venue; hideLoading?: true }) => {
        const { location, venue, hideLoading } = payload;
        this.location = location;
        this.venue = venue;
        const query = {
          ...(venue.data.shareTabs && { venue: venue.id }),
          ...(!venue.data.shareTabs && { restaurant: location._id }),
        };
        if (!hideLoading) {
          this.loader.show();
        }
        return this.ordersService.getOpenOrders(query).pipe(
          map((orderResponse: OrderData[]) => {
            this.listenToOrders({ location, venue });
            this.listenToStatusChanges();
            this.listenToMessageReadStatus();
            this.startReloadOrders();
            this.loader.hide();
            return setOrders({ orders: orderResponse.map(orderData => new Order(orderData)) });
          }),
          catchError(errorHandler)
        );
      })
    )
  );

  clearOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearOrders),
      mergeMap(() => {
        this.clearOrderListener();
        OpenOrderEffects.stopReloadOrders();
        return [setOrders({ orders: [] })];
      })
    )
  );

  private socketListener: Subscription;

  private statusListener: Subscription;

  private messageListener: Subscription;

  private realmListener: Subscription;

  private location: LocationData;

  private venue: Venue;

  private checkingOrdersById: Record<string, OrderData> = {};

  private static interval: any;

  constructor(
    private actions$: Actions,
    private store: Store,
    private ordersService: OrderService,
    private socketService: OrderSocketService,
    private supportService: SupportService,
    private toastCtrl: ToastController,
    private beepService: BeepService,
    private realmService: RealmService,
    private loader: LoadingService
  ) {}

  private startReloadOrders() {
    if (!OpenOrderEffects.interval) {
      OpenOrderEffects.interval = setInterval(() => {
        this.store
          .select((state: AppState) => state.orders)
          .subscribe(openOrdersInfo => {
            const { lastUpdated } = openOrdersInfo;
            if (!lastUpdated || lastUpdated.getTime() < new Date().getTime() - 30 * 1000) {
              this.store.dispatch(
                loadOrders({ location: this.location, venue: this.venue, hideLoading: true })
              );
            }
          });
      }, 15 * 1000);
    }
  }

  private static stopReloadOrders() {
    clearInterval(OpenOrderEffects.interval);
    OpenOrderEffects.interval = null;
  }

  private async showMessageToast(order: Order) {
    const toast = await this.toastCtrl.create({
      message: `New message for order ${order.data.orderNumber}.`,
      color: 'secondary',
      duration: 5000,
      position: 'top',
    });
    await toast.present();
  }

  private static getOpenOrdersQuery(venue: Venue, location: LocationData): SocketQuery {
    if (venue.data.shareTabs) {
      return { venue: venue.id };
    }
    return { restaurant: location._id };
  }

  private clearOrderListener() {
    this.socketListener?.unsubscribe();
    this.socketService.disconnect();
    this.realmListener?.unsubscribe();
    this.realmService.stopListeningToOrders();
  }

  private async setupRealmListener() {
    if (this.realmListener) {
      return;
    }
    const realmObservable = await this.realmService.init();
    this.realmListener = realmObservable.subscribe((event: RealmEvent) => {
      if (event.collection === 'orders') {
        switch (event.type) {
          case 'update':
            this.checkForChanges(event.data);
            break;
        }
      }
    });
    const query = {
      orgId: this.venue.data.orgId,
      venueId: this.venue.id,
      ...(this.venue.data.shareTabs &&
        this.location && {
          locationId: this.location._id,
        }),
    };
    await this.realmService.listenToOrders(query);
  }

  private listenToOrders(options: { location: LocationData; venue: Venue }) {
    if (!this.socketListener) {
      this.handleSocket();
      const query = OpenOrderEffects.getOpenOrdersQuery(options.venue, options.location);
      this.socketService.setupSocket(query);
    }
    this.setupRealmListener();
  }

  private listenToStatusChanges() {
    if (!this.statusListener) {
      this.statusListener = this.ordersService.getStatusListener().subscribe(orderData => {
        this.store.dispatch(
          updateOrder({
            orderId: orderData._id,
            update: {
              status: orderData.status,
            },
          })
        );
      });
    }
  }

  private listenToMessageReadStatus() {
    if (!this.messageListener) {
      this.messageListener = this.supportService.getReadMessageListener().subscribe(orderId => {
        console.log('Messages read', orderId);
        // this.orders.forEach(order => {
        //   if (order.id === orderId && order.messages) {
        //     order.messages.unread = 0;
        //   }
        // });
      });
    }
  }

  private handleSocket() {
    this.socketListener = this.socketService
      .getSocketListener()
      .subscribe((observer: SocketResponse) => {
        switch (observer.event) {
          case 'connection':
            console.log(observer);
            break;
          case 'joined-room':
            console.log(observer);
            break;
          case 'new-order':
            this.handleNewOrder(observer.data);
            break;
          case 'new-message':
            this.handleNewMessage(observer.data);
            break;
          case 'order-status-update':
            this.handleStatusUpdate(observer.data);
        }
      });
  }

  private handleNewOrder(order: OrderData) {
    const restaurantId = (order.restaurant as any)?._id || order.restaurant;
    // Check if consumer order
    if (order.isConsumerOrder && restaurantId === this.location?._id) {
      this.addToOpenOrders(order);
      if (order.status !== 'point-of-sale') {
        this.beepService.start();
      }
      return;
    }
    if (!order.isConsumerOrder) {
      this.addToOpenOrders(order);
      return;
    }
    if ([OrderStatus.DELIVERED, OrderStatus.CANCELLED].includes(order.status)) {
      this.removeFromOpenOrders(order);
    }
  }

  private async checkForChanges(orderData: OrderData) {
    if (this.checkingOrdersById[orderData._id]) {
      return;
    }
    this.checkingOrdersById[orderData._id] = orderData;
    if ([OrderStatus.DELIVERED, OrderStatus.CANCELLED].includes(orderData.status)) {
      return;
    }
    try {
      const fetchedOrderData = await this.ordersService.findById(orderData._id).toPromise();
      this.store.dispatch(updateOrder({ orderId: orderData._id, update: fetchedOrderData }));
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      delete this.checkingOrdersById[orderData._id];
    }
  }

  private handleNewMessage(message: { order: string }) {
    this.store.dispatch(newMessageForOrder({ orderId: message.order }));
    // this.showMessageToast(order);
  }

  private handleStatusUpdate(orderData: OrderData) {
    if ([OrderStatus.DELIVERED, OrderStatus.CANCELLED].includes(orderData.status)) {
      this.removeFromOpenOrders(orderData);
      return;
    }
    this.store.dispatch(updateOrder({ orderId: orderData._id, update: orderData }));
  }

  private addToOpenOrders(order: OrderData) {
    this.store.dispatch(addOrder({ order: new Order(order) }));
  }

  private removeFromOpenOrders(order: OrderData) {
    this.store.dispatch(removeOrder({ orderId: order._id }));
  }
}
