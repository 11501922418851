import { createReducer, on } from '@ngrx/store';
import { LocationData } from '../../../models';
import { retrievedLocation, loadedAllLocations } from './locations.actions';

export const locationInitialState: Readonly<LocationData> = null;
export const allLocationsInitialState: ReadonlyArray<LocationData> = [];

export const allLocationsReducer = createReducer(
  allLocationsInitialState,
  on(loadedAllLocations, (state, { locations }) => locations)
);

export const locationReducer = createReducer(
  locationInitialState,
  on(retrievedLocation, (state, { location }) => location)
);
