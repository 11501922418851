import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthCodeService } from '@app/providers/auth/auth.code.service';
import { ShiftService } from '@app/providers/shift/shift.service';

@Injectable({
  providedIn: 'root',
})
export class AuthCodeGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _shiftService: ShiftService,
    private _authCodeService: AuthCodeService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise<boolean>(resolve => {
      (async () => {
        const activeShift = this._shiftService.getShift();
        const proceed = await this._authCodeService.show();
        if (!proceed && activeShift) {
          this._router.navigate(['/orders']);
          resolve(false);
        } else if (!proceed) {
          this._router.navigate(['/dashboard']);
          resolve(false);
        } else {
          resolve(true);
        }
      })();
    });
  }
}
