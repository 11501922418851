import { createAction, props } from '@ngrx/store';
import { MenuItemStation } from '../../../models';

export enum StationAction {
  LOAD_ALL_STATIONS = 'Load Org Stations',
  LOADED_ALL_STATIONS = 'Loaded Org Stations',
  SELECT_STATION = 'Select Active Station',
  SELECT_STATION_WITH_ID = 'Select station with id',
}

export const loadAllStations = createAction(StationAction.LOAD_ALL_STATIONS);

export const loadedAllStations = createAction(
  StationAction.LOADED_ALL_STATIONS,
  props<{ stations: MenuItemStation[] }>()
);

export const selectStation = createAction(
  StationAction.SELECT_STATION,
  props<{ station: MenuItemStation }>()
);
