import { PrinterStatus, PrinterRole, PrinterFulfillmentMode } from '../../enums';
import { AbstractModel } from '../abstract.model';
import { LocationData, MenuItemStationData } from '../concession';

export interface PrinterData {
  name: string;
  printerId: number;
  printerRole: PrinterRole[];
  fulfillmentMode: PrinterFulfillmentMode;
  status: PrinterStatus;
  locationIds: LocationData[];
  stationIds: MenuItemStationData[];
  locationStationIds: {
    locationId: LocationData;
    stationId: MenuItemStationData;
  }[];
  lastJobId?: string | string;
  lastJobTime?: Date;
  macAddress?: string;
  serialNumber?: string;
  webhookUrl?: string;
}

export class Printer extends AbstractModel<PrinterData> {
  constructor(public data: PrinterData) {
    super(data);
  }
}
