import { DiningOptionUsageType, PricingStrategy } from '../../enums';
import * as StringHelper from '../../helpers/string.helper';
import { IImageFile } from '../../interfaces';
import { AbstractModel } from '../abstract.model';
import { DiningOptionData } from './dining-option';
import { LocationData } from './location';
import { MenuItemStationData } from './menu-item-station';
import { ModifierGroupData } from './modifier-group';
import { PriceOptionData } from './price-option';
import { ProductData } from './product';
import { PosButton } from './pos-button';

export type WithInventoryItem<T> = T & { inventoryItem: any };

export interface MenuItemData {
  restaurant: LocationData['_id'] | LocationData;
  product: ProductData['_id'] | ProductData;
  name: string;
  posName: string;
  description: string;
  isOpen: boolean;
  pricingStrategy: PricingStrategy;
  priceOptions: PriceOptionData[];
  diningOptionUsageType: DiningOptionUsageType;
  diningOptions: DiningOptionData['_id'][] | DiningOptionData[];
  stations: MenuItemStationData['_id'][] | MenuItemStationData[];
  discounts: string[] | any[];
  isPublic: boolean;
  images: IImageFile[];
  availableQuantity: number;

  // Stuff that comes from the product
  inventoryItem?: any;
  modifiers?: ModifierGroupData[];
  isAlcoholic?: boolean;
  taxCodes: any[];

  /**
   * Only used on the POS and comes from the menu section
   */
  posButton: PosButton;

  /**
   * @deprecated
   */
  price: number;
}

export type MenuItemDataWithImages = MenuItemData & {
  imageUrl?: string;
  fullImageUrl?: string;
};

export class MenuItem extends AbstractModel<MenuItemData> {
  constructor(public data: MenuItemData) {
    super(data);
  }

  public displayPrice(showRange = true): string {
    if (!this.data.priceOptions?.length) {
      return `${(this.data.price * 0.01).toLocaleString('en-us')}`;
    }
    const prices = this.data.priceOptions.map(priceOption => {
      return priceOption.price;
    });
    const min = Math.min(...prices);
    const max = Math.max(...prices);
    if (min === max) {
      return `${StringHelper.currencyFromPrice(min)}`;
    }
    if (showRange) {
      return `${StringHelper.currencyFromPrice(min)}-${StringHelper.currencyFromPrice(max)}`;
    }
    return `${StringHelper.currencyFromPrice(min)}+`;
  }
}
