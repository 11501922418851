import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiClient } from '../../../clients';
import { ApiDomain, ApiResources, ApiVersion } from '../../../enums';
import { MenuItemStation, MenuItemStationData } from '../../../models';
import { ModeService } from '../../../providers/config/mode.service';
import { errorHandler } from '../app.state';
import { loadedAllStations, StationAction } from './station.actions';

@Injectable()
export class StationEffects {
  private apiClient: ApiClient;

  loadStations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StationAction.LOAD_ALL_STATIONS),
      mergeMap((payload: any) => {
        return this.apiClient.get({}).pipe(
          map((stationResult: any) => {
            return loadedAllStations({
              stations: stationResult.data?.data?.map((stationData: MenuItemStationData) => {
                return new MenuItemStation(stationData) || [];
              }),
            });
          }),
          catchError(errorHandler)
        );
      })
    )
  );

  constructor(httpClient: HttpClient, modeService: ModeService, private actions$: Actions) {
    this.apiClient = new ApiClient(httpClient, modeService.getMode()).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.MENU_ITEM_STATION
    );
  }
}
