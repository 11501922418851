import { createAction, props } from '@ngrx/store';
import { OrderDestinationData } from '../../../interfaces';
import { DiningOption, Order } from '../../../models';

export enum ActiveOrderAction {
  START_ACTIVE_ORDER = '[Order] Start Active Order',
  LOAD_ACTIVE_ORDER = '[Order] Load Active Order',
  LOAD_ACTIVE_ORDER_SUCCESS = '[Order] Load Active Order Success',
}

export const getActiveOrder = createAction(
  ActiveOrderAction.LOAD_ACTIVE_ORDER,
  props<{ orderId: string }>()
);

export const retrievedActiveOrder = createAction(
  ActiveOrderAction.LOAD_ACTIVE_ORDER_SUCCESS,
  props<{ order: Order }>()
);

export const startActiveOrder = createAction(
  ActiveOrderAction.START_ACTIVE_ORDER,
  props<{
    shiftId: string;
    location: any;
    guest: any;
    orderDestination: OrderDestinationData;
    diningOption: DiningOption;
    member?: string;
  }>()
);
