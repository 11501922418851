import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Printer } from '../../models';

@Component({
  selector: 'app-select-printer-modal',
  templateUrl: './select-printer-modal.component.html',
  styleUrls: ['./select-printer-modal.component.scss'],
})
export class SelectPrinterModalComponent {
  @Input() title: string;

  @Input() printers: Printer[] = [];

  constructor(private modalCtrl: ModalController) {}

  public confirm(printer: Printer) {
    this.modalCtrl.dismiss({ printer });
  }

  public cancel() {
    this.modalCtrl.dismiss();
  }
}
