import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppInjector } from './app/app-injector.service';

import pkg from '../package.json';

const env = environment.production ? 'prod' : 'dev';

if (env === 'prod') {
  Sentry.init({
    dsn: 'https://1ca59a849f3246b69f514550ce611a16@o447922.ingest.sentry.io/5658763',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['/^//'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    release: `v${pkg.version}`,
    tracesSampleRate: 1.0,
    environment: env,
    denyUrls: [/^stripe\.com/i],
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(moduleRef => {
    AppInjector.setInjector(moduleRef.injector);
  })
  .catch(err => console.log(err));
