import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController, Platform } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { ApiDomain, ApiResources, ApiVersion, PointOfSaleType } from '../../enums';
import { ApiResponse, PointOfSale, PointOfSaleCreateParams, PointOfSaleData } from '../../models';
import { DeviceService } from './device.service';

import pkg from '../../../../package.json';
import { ApiClient } from '../../clients';
import { AppState, PointOfSaleActions, retrievedPointOfSale, showToast } from '../../state';
import { ModeService } from '../config';

@Injectable({
  providedIn: 'root',
})
export class PointOfSaleService {
  private apiClient: ApiClient;

  private nativeData: any;

  constructor(
    http: HttpClient,
    modeService: ModeService,
    private alertCtrl: AlertController,
    private deviceService: DeviceService,
    private actions$: Actions,
    private store: Store,
    private appVersion: AppVersion,
    private platform: Platform
  ) {
    this.apiClient = new ApiClient(http, modeService.getMode()).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.POINT_OF_SALE
    );
  }

  public async init() {
    try {
      await this.platform.ready();
      if (!this.platform.is('cordova')) {
        return;
      }
      const appName = await this.appVersion.getAppName();
      const packageName = await this.appVersion.getPackageName();
      const versionCode = await this.appVersion.getVersionCode();
      const versionNumber = await this.appVersion.getVersionNumber();
      this.nativeData = {
        appName,
        packageName,
        versionCode,
        versionNumber,
      };
    } catch (err) {
      console.log('Error getting native app version', err);
    }
  }

  public async nativeAppVersion(): Promise<string> {
    if (!this.nativeData?.versionNumber) {
      await this.init();
    }
    if (this.nativeData?.versionNumber) {
      return `${this.nativeData.versionNumber}.${this.nativeData?.versionCode}`;
    }
    return 'Web';
  }

  public needsSetup() {
    return this.actions$.pipe(ofType(PointOfSaleActions.NEED_TO_SETUP_POINT_OF_SALE), take(1));
  }

  public async registerPointOfSale(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Register Point of Sale',
      message:
        'Welcome to Partake. This device does not appear to be registered. Select a name to register this device as a Point of Sale.',
      backdropDismiss: false,
      inputs: [
        {
          type: 'text',
          name: 'name',
          label: 'Point of Sale Name',
          placeholder: 'e.g. Register 1 - Front of House',
        },
      ],
      buttons: ['Register Device'],
    });
    await alert.present();
    const { data } = await alert.onDidDismiss();
    if (!data?.values) {
      return this.registerPointOfSale();
    }
    const org = await this.store
      .pipe(
        select((state: AppState) => state.org),
        take(1)
      )
      .toPromise();
    const pointOfSaleData: PointOfSaleCreateParams = {
      name: data.values.name,
      deviceId: this.deviceService.getUUID(),
      type: PointOfSaleType.POS,
      appVersion: pkg.version,
      venueId: org.get('venueIds')[0]?._id || (org.get('venueIds')[0] as string),
      nativeAppVersion: await this.nativeAppVersion(),
    };
    try {
      const result: any = await this.apiClient
        .create<ApiResponse.RequestResult<PointOfSaleData>, PointOfSaleCreateParams>(
          pointOfSaleData
        )
        .toPromise();
      if (result.data) {
        this.store.dispatch(
          retrievedPointOfSale({
            pointOfSale: new PointOfSale(result.data),
          })
        );
      }
    } catch (error) {
      this.store.dispatch(
        showToast({
          toast: {
            message:
              error?.error?.message || 'Oops, something unexpected happened. Please try again.',
            color: 'warning',
          },
        })
      );
      return this.registerPointOfSale();
    }
  }
}
