export enum PromptForMember {
  NONE = 'none',
  START_OF_ORDER = 'start-of-order',
  START_OF_TRANSACTION = 'start-of-transaction',
}

export enum MemberStatus {
  ACTIVE = 'active',
  ACTIVE_NO_CHARGING = 'active-no-charging',
  FLAGGED = 'flagged',
  INACTIVE = 'inactive',
  SUSPENDED = 'suspended',
  RESIGNED = 'resigned',
}
