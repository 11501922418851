import { TableName, TableType } from '../../enums';
import { AbstractModel } from '../abstract.model';

export interface Table {
  id: string;
  name: string;
  type: TableType;
  tableName: TableName;
  minPartySize: number;
  maxPartySize: number;
  position: {
    rotation: number;
    x: number;
    y: number;
  };
}

export interface DiningArea {
  name: string;
  slug: string;
  width: number;
  length: number;
  tables: Table[];
}

export interface FloorPlanData {
  _id: string;
  name: string;
  locationId: string;
  diningAreas: DiningArea[];
  orgId?: string;
}

export class FloorPlan extends AbstractModel<FloorPlanData> {
  constructor(public data: FloorPlanData) {
    super(data);
  }
}
