import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paidStatus',
})
export class PaidStatusPipe implements PipeTransform {
  transform(value: boolean): string {
    if (value) {
      return 'Paid';
    }
    return 'Unpaid';
  }
}
