export enum PrintCommandType {
  APPEND_ENCODING = 'appendEncoding', // Characther encoding is used to getByte data from all subsequent commands. Default 'US-ASCII'
  APPEND_CODE_PAGE = 'appendCodePage', // Select command of the code page is generated and added to the commands property.
  APPEND_BYTES = 'appendBytes', // Data (Command) is added to the command buffer. Takes an array of bytes
  APPEND_RAW_BYTES = 'appendRawBytes', // Data (Command) is added to the command buffer. Takes an array of bytes.
  APPEND = 'append', // Data (Text) is added to the command buffer.
  APPEND_RAW = 'appendRaw', // Data (Text) is added to the command buffer.
  APPEND_CHARACTER_SPACE = 'appendCharacterSpace', // Set command of the character space is generated and added to the command buffer.
  APPEND_EMPHASIS = 'appendEmphasis', // Set command of the emphasis mode is generated and added to the command buffer.
  ENABLE_EMPHASIS = 'enableEmphasis', // Enable emphasis mode is generated and added to the command buffer.
  APPEND_INVERT = 'appendInvert', // Set command of the invert mode is generated and added to the command buffer.
  ENABLE_INVERT = 'enableInvert', // Enable invert mode is generated and added to the command buffer.
  APPEND_UNDERLINE = 'appendUnderline', // Set command of the underline mode is generated and added to the command buffer.
  ENABLE_UNDERLINE = 'enableUnderline', // Enable underline mode is generated and added to the command buffer.
  APPEND_INTERNATIONAL = 'appendInternational', // Select command of the international character mode is generated and added to the command buffer
  APPEND_LINE_FEED = 'appendLineFeed', // Line feed command is generated and added to the command buffer. Paper feed units (Units: Lines)
  APPEND_UNIT_FEED = 'appendUnitFeed', // Feed command is generated and added to the command buffer. Paper feed units (Units: Dots)
  APPEND_LINE_SPACE = 'appendLineSpace', // Set command of the line space is generated and added to the command buffer. Line spaces (Units: Dots)
  APPEND_FONT_STYLE = 'appendFontStyle', // Select command of the font style is generated and added to the command buffer.
  APPEND_CUT_PAPER = 'appendCutPaper', // Paper cut command is generated and added to the command buffer.
  APPEND_BLACK_MARK = 'appendBlackMark', // lack mark command is generated and added to the command buffer.
  /**
   * Absolute position command is generated and added to the command buffer. (Unit: Dots).
   * Send in conjunction with the data property to append absolute position just to that string.
   *
   * Example1: Append data with Absolute position {appendAbsolutePosition:40, data: "Text with absolute position"}.
   *
   * Example2: Append absolute position to subsequent commands: {appendAbsolutePosition:40}
   */
  APPEND_ABSOLUTE_POSITION = 'appendAbsolutePosition',
  /**
   * Alignment command is generated and added to the command buffer.Send in conjunction with the
   * data property to append alignment position just to that string. Choose the format of the
   * return value defined in AlignmentPosition.
   *
   * Example1 Append data with Alignment position: {appendAlignment:AlignmentPosition.Center, data: "Text with centered position"}.
   *
   * Example2 Append absolute position to subsequent commands: {appendAlignment:'Center'}
   */
  APPEND_ALIGNMENT = 'appendAlignment',
  /**
   * Print command of the logo is generated and added to the command buffer. The logo has to be
   * uploaded to the printer using the Star Print utility. Send in conjuction with the logoSize
   * property to set the logo size LogoSize.
   *
   * Example: {appendLogo:1} Example with LogoSize: {appendLogo:1, logoSize:'DoubleWidthDoubleHeight'}
   */
  APPEND_LOGO = 'appendLogo',
  /**
   * Print command of the barcode is generated and added to the command buffer.
   * Additional Properties: BarcodeSymbology, BarcodeWidth, height, hri, absolutePosition, alignment.
   *
   * Example: {appendBarcode:"BStar", BarcodeSymbology:BarcodeSymbology.Code128, BarcodeWidth:BarcodeWidth.Mode2, height:40, hri:true }.
   *
   * Example with absolutePosition: {appendBarcode:"BStar", BarcodeSymbology:BarcodeSymbology.Code128, BarcodeWidth:BarcodeWidth.Mode2, height:40, hri:true, absolutePosition:40 }.
   *
   * Example with alignment: {appendBarcode:"BStar", BarcodeSymbology:BarcodeSymbology.Code128, BarcodeWidth:BarcodeWidth.Mode2, height:40, hri:true, alignment:AlignmentPosition.Center }
   */
  APPEND_BARCODE = 'appendBarcode',
  /**
   * Select command of the multiple mode is generated and added to the command buffer.
   * Additional properties: width:number, height:number.
   *
   * Example: {appendMultiple:"   $156.95\n", width:2, height:2}
   */
  APPEND_MULTIPLE = 'appendMultiple',
  /**
   * Enable multiple mode is generated and added to the command buffer.
   * Additional properties: width:number, height:number.
   * Example: {enableMultiple:true, width:2, height:2} Disable Example: {enableMultiple:false}
   */
  ENABLE_MULTIPLE = 'enableMultiple',
  /**
   * Print command of the QR code is generated and added to the command buffer.
   * Additional Properties: QrCodeModel, QrCodeLevel, cell, absolutePosition, alignment.
   *
   * Example: {appendQrCode:"{BStar", QrCodeModel:"No2", QrCodeLevel:"L", cell: 8}
   *
   * Example with absolutePosition:  {appendQrCode:"{BStar", QrCodeModel:"No2", QrCodeLevel:"L", cell: 8, absolutePosition: 40 }
   *
   * Example with alignment: {appendQrCode:"{BStar", QrCodeModel:"No2", QrCodeLevel:"L", cell: 8, alignment:"Center" }
   */
  APPEND_QR_CODE = 'appendQrCode',
  /**
   * Print command of the bitmap is generated and added to the command buffer.
   * Takes a string image URI. This can be obtained via the camera or photo library or as a static
   * resource saved on the phone memory.
   * Additional Properties: diffusion, width, bothScale, rotation, absolutePosition, alignment.
   *
   * Example: {appendBitmap:uri, diffusion: true, width:576, bothScale: true}
   *
   * Example with absolutePosition: {appendBitmap:uri, diffusion: true, width:576, bothScale: true, absolutePosition: 40 }
   *
   * Example with alignment: {appendBitmap:uri, diffusion: true, width:576, bothScale: true, alignment:"Center" }
   */
  APPEND_BITMAP = 'appendBitmap',
  OPEN_CASH_DRAWER = 'openCashDrawer', // Sends a appendPeripheral command to the printer for channel number: Example: {openCashDrawer:1}
}

export enum Encoding {
  US_ACSII = 'US-ASCII', //	English
  WINDOWS_1252 = 'Windows-1252', // French, German, Portuguese, Spanish
  SHIFT_JIS = 'Shift-JIS', // Japanese
  WINDOWS_1251 = 'Windows-1251', // Russian
  SIMPLE_CHINESE = 'GB2312', // Simplified Chinese
  TRANDITIONAL_CHINESE = 'Big5', // Traditional Chinese
  UFT8 = 'UTF-8', // UFT8
}

export enum CodePageType {
  CP737 = 'CP737',
  CP772 = 'CP772',
  CP774 = 'CP774',
  CP851 = 'CP851',
  CP852 = 'CP852',
  CP855 = 'CP855',
  CP857 = 'CP857',
  CP858 = 'CP858',
  CP860 = 'CP860',
  CP861 = 'CP861',
  CP862 = 'CP862',
  CP863 = 'CP863',
  CP864 = 'CP864',
  CP865 = 'CP865',
  CP869 = 'CP869',
  CP874 = 'CP874',
  CP928 = 'CP928',
  CP932 = 'CP932',
  CP999 = 'CP999',
  CP1001 = 'CP1001',
  CP1250 = 'CP1250',
  CP1251 = 'CP1251',
  CP1252 = 'CP1252',
  CP2001 = 'CP2001',
  CP3001 = 'CP3001',
  CP3002 = 'CP3002',
  CP3011 = 'CP3011',
  CP3012 = 'CP3012',
  CP3021 = 'CP3021',
  CP3041 = 'CP3041',
  CP3840 = 'CP3840',
  CP3841 = 'CP3841',
  CP3843 = 'CP3843',
  CP3845 = 'CP3845',
  CP3846 = 'CP3846',
  CP3847 = 'CP3847',
  CP3848 = 'CP3848',
  UTF8 = 'UTF8',
  BLANK = 'Blank',
}

export enum InternationalType {
  UK = 'UK',
  USA = 'USA',
  LEGAL = 'Legal',
}

export enum FontStyleType {
  FONT_A = 'A', // (12 x 24 dots) / Specify 7 x 9 font (half dots)
  FONT_B = 'B', // (9 x 24 dots) / Specify 5 x 9 font (2P-1)
}

export enum CutPaperAction {
  FULL_CUT = 'FullCut',
  FULL_CUT_WITH_FEED = 'FullCutWithFeed',
  PARTIAL_CUT = 'PartialCut',
  PARTIAL_CUT_WITH_FEED = 'PartialCutWithFeed',
}

export enum BlackMarkType {
  VALID = 'Valid',
  INVALID = 'Invalid',
  VALID_WITH_DETECTION = 'ValidWithDetection',
}

export enum LogoSize {
  NORMAL = 'Normal',
  DOUBLE_WIDTH = 'DoubleWidth',
  DOUBLE_HEIGHT = 'DoubleHeight',
  DOUBLE_WIDTH_DOUBLE_HEIGHT = 'DoubleWidthDoubleHeight',
}

export enum AlignmentPosition {
  LEFT = 'Left',
  CENTER = 'Center',
  RIGHT = 'Right',
}

export enum BarcodeSymbology {
  CODE_128 = 'Code128',
  CODE_39 = 'Code39',
  CODE_93 = 'Code93',
  ITF = 'ITF',
  JAN8 = 'JAN8',
  JAN13 = 'JAN13',
  NW7 = 'NW7',
  UPCA = 'UPCA',
  UPCE = 'UPCE',
}

export enum BarcodeWidth {
  MODE_1 = 'Mode1',
  MODE_2 = 'Mode2',
  MODE_3 = 'Mode3',
  MODE_4 = 'Mode4',
  MODE_5 = 'Mode5',
  MODE_6 = 'Mode6',
  MODE_7 = 'Mode7',
  MODE_8 = 'Mode8',
  MODE_9 = 'Mode9',
}

export enum QRCodeModel {
  NO_1 = 'No1',
  NO_2 = 'No2',
}

export enum QrCodeLevel {
  H = 'H',
  L = 'L',
  M = 'M',
  Q = 'Q',
}

export enum BitmapConverterRotation {
  NORMAL = 'Normal',
  LEFT_90 = 'Left90',
  RIGHT_90 = 'Right90',
  ROTATE_180 = 'Rotate180',
}

export interface AppendEncodingCommand {
  [PrintCommandType.APPEND_ENCODING]: Encoding;
}

export interface AppendCodePageCommand {
  [PrintCommandType.APPEND_CODE_PAGE]: CodePageType;
}

export interface AppendBytesCommand {
  [PrintCommandType.APPEND_BYTES]: number[];
}

export interface ApendRawBytesCommand {
  [PrintCommandType.APPEND_RAW_BYTES]: number[];
}

export interface AppendCommand {
  [PrintCommandType.APPEND]: string;
}

export interface AppendRawCommand {
  [PrintCommandType.APPEND_RAW]: string;
}

export interface AppendCharacterSpaceCommand {
  [PrintCommandType.APPEND_CHARACTER_SPACE]: number;
}

export interface AppendEmphasisCommand {
  [PrintCommandType.APPEND_EMPHASIS]: string;
}

export interface EnableEmphasisCommand {
  [PrintCommandType.ENABLE_EMPHASIS]: boolean;
}

export interface AppendInvertCommand {
  [PrintCommandType.APPEND_INVERT]: string;
}

export interface EnableInvertCommand {
  [PrintCommandType.ENABLE_INVERT]: boolean;
}

export interface AppendUnderlineCommand {
  [PrintCommandType.APPEND_UNDERLINE]: string;
}

export interface EnableUnderlineCommand {
  [PrintCommandType.ENABLE_UNDERLINE]: boolean;
}

export interface AppendInternationalCommand {
  [PrintCommandType.APPEND_INTERNATIONAL]: InternationalType;
}

export interface AppendLineFeedCommand {
  [PrintCommandType.APPEND_LINE_FEED]: number;
}

export interface AppendUnitFeedCommand {
  [PrintCommandType.APPEND_UNIT_FEED]: number;
}

export interface AppendLineSpaceCommand {
  [PrintCommandType.APPEND_LINE_SPACE]: number;
}

export interface AppendFontStyleCommand {
  [PrintCommandType.APPEND_FONT_STYLE]: FontStyleType;
}

export interface AppendCutPaperCommand {
  [PrintCommandType.APPEND_CUT_PAPER]: CutPaperAction;
}

export interface AppendBlackMarkCommand {
  [PrintCommandType.APPEND_BLACK_MARK]: BlackMarkType;
}

export interface AppendAbsolutePositionCommand {
  [PrintCommandType.APPEND_ABSOLUTE_POSITION]: number;
  data?: string;
}

export interface AppendAlignmentCommand {
  [PrintCommandType.APPEND_ALIGNMENT]: AlignmentPosition;
  data?: string;
}

export interface AppendLogoCommand {
  [PrintCommandType.APPEND_LOGO]: number;
  logoSize?: LogoSize;
}

export interface AppendBarcodeCommand {
  [PrintCommandType.APPEND_BARCODE]: string;
  BarcodeSymbology: BarcodeSymbology;
  BarcodeWidth: BarcodeWidth;
  height?: number;
  width?: number;
  hri: boolean;
  absolutePosition?: number;
  alignment?: AlignmentPosition;
}

export interface AppendMultipleCommand {
  [PrintCommandType.APPEND_MULTIPLE]: string;
  width?: number;
  height?: number;
}

export interface EnableMultipleCommand {
  [PrintCommandType.ENABLE_MULTIPLE]: boolean;
  width?: number;
  height?: number;
}

export interface AppendQrCodeCommand {
  [PrintCommandType.APPEND_QR_CODE]: string;
  QrCodeModel: QRCodeModel;
  QrCodeLevel: QrCodeLevel;
  cell: number;
  absolutePosition?: number;
  alignment?: AlignmentPosition;
}

export interface AppendBitmapCommand {
  [PrintCommandType.APPEND_BITMAP]: string;
  diffusion?: boolean; // Default true
  width: number;
  bothScale?: boolean; // Default true
  rotation?: BitmapConverterRotation;
  absolutePosition?: number;
  alignment?: AlignmentPosition;
}

export interface OpenCashDrawerCommand {
  [PrintCommandType.OPEN_CASH_DRAWER]: number;
}

export type PrintCommand =
  | AppendEncodingCommand
  | AppendCodePageCommand
  | AppendBytesCommand
  | ApendRawBytesCommand
  | AppendCommand
  | AppendRawCommand
  | AppendCharacterSpaceCommand
  | AppendEmphasisCommand
  | EnableEmphasisCommand
  | AppendInvertCommand
  | EnableInvertCommand
  | AppendUnderlineCommand
  | EnableUnderlineCommand
  | AppendInternationalCommand
  | AppendLineFeedCommand
  | AppendUnitFeedCommand
  | AppendLineSpaceCommand
  | AppendFontStyleCommand
  | AppendCutPaperCommand
  | AppendBlackMarkCommand
  | AppendAbsolutePositionCommand
  | AppendAlignmentCommand
  | AppendLogoCommand
  | AppendBarcodeCommand
  | AppendMultipleCommand
  | EnableMultipleCommand
  | AppendQrCodeCommand
  | AppendBitmapCommand
  | OpenCashDrawerCommand;
