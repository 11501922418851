import { createAction, props } from '@ngrx/store';
import { LocationData } from '../../../models';

export enum LocationAction {
  LOAD_LOCATION = '[Location] Load Location',
  LOAD_LOCATION_SUCCESS = '[Location] Load Location Success',
  LOAD_ALL_LOCATIONS = '[Location] Load All Locations',
  LOADED_ALL_LOCATIONS_SUCCESS = '[Location] Loaded All Locations Success',
}

export const loadAllLocations = createAction(LocationAction.LOAD_ALL_LOCATIONS);

export const loadedAllLocations = createAction(
  LocationAction.LOADED_ALL_LOCATIONS_SUCCESS,
  props<{ locations: LocationData[] }>()
);

export const retrievedLocation = createAction(
  LocationAction.LOAD_LOCATION_SUCCESS,
  props<{ location: LocationData }>()
);
