import { createAction, props } from '@ngrx/store';
import { Org } from '../../../models';

export enum OrgActions {
  LOAD_ORG = '[Loading Org] Load Org',
  LOAD_ALL_ORGS = '[Loading All Orgs] Load All Orgs',
  LOAD_ORG_SUCCESS = '[Loading Org] Loaded Org Successfully',
  LOADED_ORGS_SUCCESS = '[Loaded Orgs] Loadeded All Orgs Successfully',
}

export const retrievedOrg = createAction(OrgActions.LOAD_ORG_SUCCESS, props<{ org: Org }>());

export const loadOrg = createAction(OrgActions.LOAD_ORG, props<{ orgId: string }>());

export const loadAllOrgs = createAction(OrgActions.LOAD_ALL_ORGS);

export const retrevedAllOrgs = createAction(
  OrgActions.LOADED_ORGS_SUCCESS,
  props<{ orgs: Org[] }>()
);
