import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { AuthService } from '@app/providers/auth/auth.service';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { AppState, selectPointOfSale, selectShift } from '../state';
import { PointOfSale } from '../models';
import { ApiHeader, AppMode, MenuFeature, OrderChannel } from '../enums';
import { DeviceService, PointOfSaleService } from '../providers';
import { LocalStorage } from '../helpers';

type InterceptorState = Pick<AppState, 'pointOfSale' | 'shift'>;

const getState = (state: AppState): InterceptorState => {
  return {
    pointOfSale: selectPointOfSale(state),
    shift: selectShift(state),
  };
};

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private orgId: string;

  private venueId: string;

  private locationId: string;

  private interceptorState$: Observable<InterceptorState> = this.store.pipe(select(getState));

  private pointOfSale: PointOfSale;

  private shiftId: string;

  private nativeAppVersion: string;

  private appMode: AppMode;

  private liveMode = 'true';

  constructor(
    private authService: AuthService,
    private device: DeviceService,
    private store: Store,
    private pointOfSaleService: PointOfSaleService
  ) {
    this.init();
  }

  private async loadIds() {
    this.orgId = await LocalStorage.getItem(ApiHeader.ORG_ID);
    this.venueId = await LocalStorage.getItem(ApiHeader.VENUE_ID);
    this.locationId = await LocalStorage.getItem(ApiHeader.LOCATION_ID);
    this.nativeAppVersion = await this.pointOfSaleService.nativeAppVersion();
    this.appMode = this.device.getSettings()?.mode;
    this.liveMode = (await LocalStorage.getItem(ApiHeader.LIVE_MODE)) || 'true';
  }

  private async init() {
    this.interceptorState$.subscribe(state => {
      this.pointOfSale = state.pointOfSale;
      if (state.shift) {
        this.locationId = (state.shift.restaurant as any)._id || state.shift.restaurant;
        this.shiftId = state.shift._id;
      } else {
        this.locationId = null;
        this.shiftId = null;
      }
    });
  }

  private async handle(req: HttpRequest<any>, next: HttpHandler) {
    await this.loadIds();
    const authToken = this.authService.getToken();
    const appVersion = [environment.appVersion];
    if (this.nativeAppVersion) {
      appVersion.push(this.nativeAppVersion);
    }
    let headers = req.headers
      .set(ApiHeader.API_VERSION, environment.apiVersion)
      .set(ApiHeader.APP_NAME, environment.app)
      .set(ApiHeader.APP_VERSION, appVersion.join('/'))
      .set(ApiHeader.APP_SCOPE, 'partake')
      .set(ApiHeader.APP_PATH, window.location.pathname)
      .set(ApiHeader.ORDER_CHANNEL, OrderChannel.POS)
      .set(ApiHeader.MENU_FEATURE, `${MenuFeature.TAX_INCLUDED}`)
      .set(ApiHeader.LIVE_MODE, this.liveMode);

    if (authToken) {
      headers = headers.set(ApiHeader.AUTH_TOKEN, authToken);
    }
    if (this.orgId) {
      headers = headers.set(ApiHeader.ORG_ID, this.orgId);
    }
    if (this.venueId) {
      headers = headers.set(ApiHeader.VENUE_ID, this.venueId);
    }
    if (this.locationId) {
      headers = headers.set(ApiHeader.LOCATION_ID, this.locationId);
    }
    if (this.pointOfSale) {
      headers = headers.set(ApiHeader.POS_ID, this.pointOfSale.id);
    }
    if (this.shiftId) {
      headers = headers.set(ApiHeader.SHIFT_ID, this.shiftId);
    }
    if (this.appMode) {
      headers = headers.set(ApiHeader.POS_APP_MODE, this.appMode);
    }

    const authRequest = req.clone({ headers });
    return next.handle(authRequest).toPromise();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return from(this.handle(req, next));
  }
}
