import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemCount',
})
export class ItemCountPipe implements PipeTransform {
  transform(items: Array<any>): number {
    let count = 0;
    if (items && items.length) {
      count = items.reduce((prev, curr) => {
        return prev + curr.quantity;
      }, 0);
    }
    return count;
  }
}
