import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { ShiftService } from '@app/providers/shift/shift.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveShiftGuard implements CanActivate {
  constructor(private _router: Router, private _shiftService: ShiftService) {}

  /**
   * @todo Shift API V2
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      (async () => {
        const shift = this._shiftService.getShift();
        if (!shift) {
          resolve(true);
        } else {
          const current = await this._shiftService.findOne(shift._id).toPromise();
          if (current.status === 'open') {
            this._router.navigate(['/orders']);
            reject(new Error('Shift is already open'));
          } else {
            resolve(true);
          }
        }
      })();
    });
  }
}
