import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'gratuity',
})
export class GratuityPipe implements PipeTransform {
  constructor(private _currencyPipe: CurrencyPipe) {}

  transform(value: number): string {
    if (!value) {
      return 'No Tip';
    }
    return `${this._currencyPipe.transform(value * 0.01, 'USD', 'symbol', '1.2-2')} Tip`;
  }
}
