import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { ShiftService } from '@app/providers/shift/shift.service';

@Injectable({
  providedIn: 'root',
})
export class ShiftGuard implements CanActivate {
  constructor(private _router: Router, private _shiftService: ShiftService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const shift = this._shiftService.getShift();
    if (!shift) {
      this._router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }
}
