import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { environment } from '@env/environment';
import { ModeService } from '../config/mode.service';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  private _readMessageListener = new Subject<string>();

  private domain: string;
  private path = 'support';
  private baseUrl: string;

  constructor(private _http: HttpClient, private _modeService: ModeService) {
    const mode = this._modeService.getMode();
    this.domain = environment[mode].url;
    this.baseUrl = `${this.domain}/${this.path}`;
  }

  public getReadMessageListener(): Observable<string> {
    return this._readMessageListener.asObservable();
  }

  public readMessages(orderId: string): void {
    this._readMessageListener.next(orderId);
  }

  public send(message: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}`, message);
  }

  public thread(orderId: string): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/${orderId}`);
  }

  public find(query: any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}`, { params: query });
  }

  public seatToRead(orderId: string): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${orderId}/set-to-read`, {});
  }

  public messages(body: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}/messages`, body);
  }
}
