<ion-header>
  <ion-toolbar>
    <ion-title>Reconnect to Card Reader</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-col
    *ngIf="!connected && !hasError"
    class="ion-align-self-center ion-text-center"
  >
    <ion-img
      class="card-reader-gif"
      src="assets/images/Partake_CC.gif"
    ></ion-img>
    <p>1. Turn card reader on.</p>
    <p>2. Move card reader close to device.</p>
  </ion-col>
  <ion-col
    *ngIf="connected"
    class="success-col ion-align-self-center ion-text-center"
  >
    <app-payment-success-icon></app-payment-success-icon>
  </ion-col>
  <ion-col
    *ngIf="hasError"
    class="success-col ion-align-self-center ion-text-center"
  >
    <div class="icon-container fadeIn">
      <ion-icon name="bluetooth"></ion-icon>
    </div>
    <p>{{ error }}</p>
  </ion-col>
</ion-content>

<ion-footer class="ion-padding">
  <ion-row>
    <ion-col *ngIf="!connected">
      <ion-button
        expand="block"
        color="medium"
        fill="outline"
        (click)="cancel()"
      >
        Not Using BBPOS Reader
      </ion-button>
    </ion-col>
    <ion-col *ngIf="connected">
      <ion-button
        expand="block"
        color="secondary"
        (click)="dismiss()"
      >
        Continue
      </ion-button>
    </ion-col>
    <ion-col *ngIf="hasError">
      <ion-button
        expand="block"
        color="light"
        (click)="retry()"
      >
        Retry
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
