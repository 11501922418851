import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModeService {
  private _KEY = 'pt-mode';

  constructor() {}

  public setMode(mode: string) {
    if (mode) {
      localStorage.setItem(this._KEY, mode);
    } else {
      localStorage.removeItem(this._KEY);
    }
    localStorage.removeItem('pt-shift');
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }

  public getMode() {
    const mode = localStorage.getItem(this._KEY);
    return mode || 'prod';
  }
}
