import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SearchModalComponent } from './search-modal.component';

interface SearchOpts {
  title: string;
  message: string;
}

@Injectable()
export class SearchModalController {
  constructor(private modalCtrl: ModalController) {}

  public async show(opts: SearchOpts): Promise<{ name?: string }> {
    const modal = await this.modalCtrl.create({
      component: SearchModalComponent,
      cssClass: 'search-modal',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    return data;
  }
}
