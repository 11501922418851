import { FulfillmentMode, InventoryMode } from '../../enums';
import { IImageFile } from '../../interfaces';
import { AbstractModel } from '../abstract.model';
import { LocationCategoryData } from './location-category';

export interface LocationData {
  _id: string;
  name: string;
  slug: string;
  type: string;
  category: string | LocationCategoryData;
  orderQuantityValidation: string;
  isPublic: boolean;
  acceptingOrders: boolean;
  inventoryTracked: boolean;
  fulfillmentMode: FulfillmentMode;
  kdsEnabled: boolean;
  inventoryMode: InventoryMode;
  trackCoverCounts: boolean;
  tippingEnabled: boolean;
  collectTipsOnTerminal: boolean;
  trackCash: boolean;
  images: IImageFile[];
  alcoholEnabled: boolean;
  alcoholLimit?: number;
  venue?: string;
  orgId?: string;
}

export class Location extends AbstractModel<LocationData> {
  constructor(public data: LocationData) {
    super(data);
  }
}
