import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '../../clients';
import {
  ApiDomain,
  ApiResources,
  ApiVersion,
  InstanceType,
  PaymentTermimalModel,
  PaymentTermimalStatus,
  TransactionCardScheme,
} from '../../enums';
import { PaymentHelper } from '../../helpers';
import { ApiResponse, Order, PosCardReader } from '../../models';
import { ModeService } from '../config';
import { CardReaderData } from './payment.service';
import { StripeLazyLoadService } from './stripe.lazy-load.service';
import { CardPresentSetupBody } from './types';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private stripeEcomm: any;

  private stripeTerminal: any;

  private paymentI9n: any;

  private discoveredReaders: any[] = [];

  private apiClient: ApiClient;

  private processingPaymentTimeout: any;

  constructor(
    private stripeLazyLoad: StripeLazyLoadService,
    httpClient: HttpClient,
    modeService: ModeService
  ) {
    this.apiClient = new ApiClient(httpClient, modeService.getMode()).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.PAYMENTS
    );
  }

  public async init(paymentI9n: any) {
    this.paymentI9n = paymentI9n;
    const promise = new Promise<any>((resolve, reject) => {
      if (window.Stripe) {
        resolve({ loaded: true });
      } else {
        this.stripeLazyLoad
          .init(paymentI9n)
          .then(success => {
            this.stripeEcomm = success.stripe;
            resolve(success);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
    return promise;
  }

  public getStripe(): any {
    return this.stripeEcomm;
  }

  public clearTerminal() {
    this.stripeTerminal = null;
  }

  public async initTerminal(paymentI9n: any) {
    this.paymentI9n = paymentI9n;
    const promise = new Promise<any>((resolve, reject) => {
      if (this.stripeTerminal) {
        console.log('Stripe Terminal SDK already loaded');
        resolve({ loaded: true });
        return;
      }
      this.stripeLazyLoad
        .loadTerminalSdk()
        .then(success => {
          this.stripeTerminal = success.terminal;
          console.log('Stripe Terminal SDK loaded', success);
          resolve(success);
        })
        .catch(error => {
          reject(error);
        });
    });
    return promise;
  }

  public getStripeTerminal(): any {
    return this.stripeTerminal;
  }

  private static getTerminalModelFromStripeModel(stripeModel: string): PaymentTermimalModel {
    switch (stripeModel) {
      case 'bbpos_wisepos_e':
        return PaymentTermimalModel.BBPOS_WISEPOS_E;
      default:
        return PaymentTermimalModel.UNKNOWN;
    }
  }

  public async discoverReaders(): Promise<CardReaderData[]> {
    this.discoveredReaders = [];
    const simulated = this.paymentI9n.instanceType === InstanceType.SANDBOX;
    const config = { simulated: false, location: this.paymentI9n.stripeLocationId };
    const discoverResult = await this.stripeTerminal.discoverReaders(config).catch((err: any) => {
      console.log('Failed to discover: ', err);
      return [];
    });
    if (simulated) {
      const simulatedReaders = await this.stripeTerminal
        .discoverReaders({ ...config, simulated })
        .catch((err: any) => {
          console.log('Failed to discover: ', err);
          return [];
        });
      if (simulatedReaders.discoveredReaders?.length) {
        this.discoveredReaders.push(...simulatedReaders.discoveredReaders);
      }
    }
    if (discoverResult.error) {
      console.log('Failed to discover: ', discoverResult.error);
      return [];
    }
    if (discoverResult.discoveredReaders?.length) {
      this.discoveredReaders.push(...discoverResult.discoveredReaders);
      return this.discoveredReaders.map((reader: any) => {
        return {
          name: reader.label,
          serialNumber: reader.serial_number,
          model: StripeService.getTerminalModelFromStripeModel(reader.device_type),
          status:
            reader.status === 'online'
              ? PaymentTermimalStatus.ONLINE
              : PaymentTermimalStatus.OFFLINE,
        };
      });
    }
    return [];
  }

  public async connectToReader(selectedReader: PosCardReader): Promise<boolean> {
    if (!this.discoveredReaders.length) {
      await this.discoverReaders();
    }
    const stripeReader = this.discoveredReaders.find(
      (reader: any) => reader.serial_number === selectedReader.serialNumber
    );
    if (!stripeReader) {
      throw new Error(
        `Can't connected to reader ${selectedReader.model} ${selectedReader.serialNumber}`
      );
    }
    try {
      const alreadyConnectedResult = await this.getConnectionStatus();
      if (alreadyConnectedResult.isConnected) {
        return true;
      }
      const connectResult = await this.stripeTerminal.connectReader(stripeReader);
      if (connectResult.error) {
        throw connectResult;
      }
      return !!connectResult.reader;
    } catch (err) {
      if (!err.error) {
        console.log('Failed to connect - no .error: ', err);
        throw new Error(err);
      }
      console.log('Failed to connect: ', err.error.code, err.error.message);
      switch (err.error.code) {
        case 'already_connected':
          return true;
        case 'reader_error':
          throw new Error(err.error.message);
        default:
          throw new Error(err.error);
      }
    }
  }

  public async disconnectReader(): Promise<boolean> {
    try {
      await this.stripeTerminal.clearCachedCredentials();
      const disconnectResult = await this.stripeTerminal.disconnectReader();
      return !disconnectResult.error;
    } catch (err) {
      console.log('Failed to disconnect:');
      console.log(err);
      return false;
    }
  }

  public async getConnectionStatus(): Promise<{ isConnected: boolean }> {
    try {
      const status = await this.stripeTerminal.getConnectionStatus();
      switch (status) {
        case 'connected':
          return { isConnected: true };
        case 'connecting':
        case 'not_connected':
        default:
          return { isConnected: false };
      }
    } catch (err) {
      console.log('Failed to get connection status: ', err);
      return { isConnected: false };
    }
  }

  public async setReaderDisplay(order: Order): Promise<boolean> {
    try {
      const displayResult = await this.stripeTerminal.setReaderDisplay({
        type: 'cart',
        cart: {
          line_items: order.data.items.map(item => {
            return {
              description: item.name,
              amount: item.price,
              quantity: item.quantity,
            };
          }),
          tax: order.data.taxTotal + order.data.taxIncludedTaxTotal,
          total: order.data.total,
          currency: 'usd',
        },
      });
      if (displayResult.error) {
        throw displayResult.error;
      }
      return true;
    } catch (err) {
      console.log('Failed to set reader display: ', err);
      return false;
    }
  }

  public async readReusableCard(): Promise<any> {
    try {
      const response = await this.stripeTerminal.readReusableCard();
      if (response.payment_method) {
        return {
          value: response.payment_method.id,
          card: response.payment_method,
        };
      }
      if (response.error) {
        return {
          error: response.error,
        };
      }
    } catch (error) {
      return { error };
    }
  }

  public async cancelReadReusableCard(): Promise<any> {
    try {
      await this.stripeTerminal.cancelReadReusableCard();
    } catch (error) {
      console.log(error);
    }
  }

  public static doesPaymentSupportTabs(paymentIntent: any): boolean {
    const charge = paymentIntent.charges.data[0];
    return charge?.payment_method_details?.card_present?.incremental_authorization_supported;
  }

  private timeoutProcessingPayment(): void {
    this.processingPaymentTimeout = setTimeout(() => {
      if (this.processingPaymentTimeout) {
        this.cancelProcessPayment();
      }
    }, 1000 * 60 * 2);
  }

  public async cancelProcessPayment(): Promise<any> {
    if (this.processingPaymentTimeout) {
      clearTimeout(this.processingPaymentTimeout);
    }
    return this.stripeTerminal.cancelCollectPaymentMethod();
  }

  public async processPayment(
    options: CardPresentSetupBody,
    collectTipsOnTerminal: boolean
  ): Promise<{ paymentMethod: string; cardScheme: TransactionCardScheme; supportsTab: boolean }> {
    const paymentIntentResult = await this.apiClient
      .post<ApiResponse.RequestResult<any>, CardPresentSetupBody>(options, 'card-present/setup')
      .toPromise();
    console.log('paymentIntentResult', paymentIntentResult);
    // Timeout the payment after 2 minutes
    this.timeoutProcessingPayment();
    const response = await this.stripeTerminal.collectPaymentMethod(
      paymentIntentResult.data.client_secret,
      {
        ...(!collectTipsOnTerminal && {
          config_override: {
            skip_tipping: true,
          },
        }),
      }
    );
    // Clear the timeout when we succeed
    if (this.processingPaymentTimeout) {
      clearTimeout(this.processingPaymentTimeout);
    }
    console.log('processPayment response', response);
    if (response.paymentIntent) {
      const processedPayment = await this.stripeTerminal.processPayment(response.paymentIntent);
      if (processedPayment.paymentIntent) {
        return {
          paymentMethod: processedPayment.paymentIntent,
          supportsTab: StripeService.doesPaymentSupportTabs(processedPayment.paymentIntent),
          cardScheme: PaymentHelper.getStripeCardSchemeFromStripePaymentIntent(
            processedPayment.paymentIntent
          ),
        };
      }
      if (processedPayment.error) {
        throw processedPayment.error;
      }
    }
    if (response.error) {
      throw response.error;
    }
  }
}
