export enum OrderDetailsView {
  DINING_OPTION = 'dining-option',
  DESTINATION = 'destination',
  GUEST = 'guest',
}

export enum OrderSortKey {
  ORDER_NUMBER = 'orderNumber',
  GUEST_NAME = 'name',
}

export enum OrderFilterKey {
  SHIFT_ID = 'shiftId',
  GUEST_NAME = 'guestName',
}
