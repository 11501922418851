import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-success-icon',
  templateUrl: './payment-success-icon.component.html',
  styleUrls: ['./payment-success-icon.component.scss'],
})
export class PaymentSuccessIconComponent {
  constructor() {}
}
