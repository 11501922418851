export enum OrgState {
  LIVE = 'live',
  NON_LIVE = 'non-live',
  STALE = 'stale',
}

export enum OrgFeature {
  POS = 'pos',
  KDS = 'kds',
  ONLINE_ORDERING = 'online-ordering',
  EVENTS = 'events',
  GAMES = 'games',
  FANTASY_SPORTS = 'fantasy-sports',
  ADVANCED_REPORTING = 'advanced-reporting',
  MARKETING = 'marketing',
  TEE_SHEET = 'tee-sheet',
  LOYALTY = 'loyalty',
  MENU_SECTIONS = 'menu-sections',
  MEMBER_CHARGES = 'member-charges',
}
