import { Component } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ApiResources } from '../../enums';
import { ApiResponse, Member, MemberData } from '../../models';

@Component({
  selector: 'app-member-lookup',
  templateUrl: './member-lookup.component.html',
  styleUrls: ['./member-lookup.component.scss'],
})
export class MemberLookupComponent extends BaseComponent {
  public searchOption: 'fullName' | 'memberId' = 'fullName';

  public searchValue: string;

  public members: Member[] = [];

  public hasSearched = false;

  constructor() {
    super();
    this.apiClient.setResource(ApiResources.MEMBER);
  }

  public async search() {
    const searchResult = await this.makeSafeRequestWithLoading<
      ApiResponse.SearchResult<MemberData>
    >(
      this.apiClient.get({
        [this.searchOption]: this.searchValue,
      })
    );
    this.hasSearched = true;
    if (searchResult.success) {
      this.members = searchResult.data.data.map(memberData => new Member(memberData));
    }
  }

  public async selectMember(member: any) {
    this.modalCtrl.dismiss({ member });
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }
}
