import { MemberStatus } from '../../enums';
import { AbstractModel } from '../abstract.model';

export interface MemberData {
  fullName: string;
  firstName: string;
  lastName: string;
  memberId: string;
  status: MemberStatus;
  comment: string;
  orgId: string;
  venueId: string;
  i9nId?: string;
  userId?: string;
  isDeleted: boolean;
}

export class Member extends AbstractModel<MemberData> {
  constructor(public data: MemberData) {
    super(data);
  }
}
