import { createReducer, on } from '@ngrx/store';
import { PointOfSale } from '../../../models';
import { retrievedPointOfSale } from './point-of-sale.actions';

export const pointOfSaleInitialState: Readonly<PointOfSale> = null;

export const pointOfSaleReducer = createReducer(
  pointOfSaleInitialState,
  on(retrievedPointOfSale, (state, { pointOfSale }) => pointOfSale)
);
