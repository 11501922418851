export enum IntegrationSystems {
  CLOUDFLARE = 'cloudflare',
  EZ_LINKS = 'ez-links',
  STRIPE = 'stripe',
  PAY_PAL = 'paypal',
  PAYLOAD = 'payload',
  STATS = 'stats',
  FANTASY_DATA = 'fantasy-data',
}

export enum IntegrationTypes {
  ECOMM_PAYMENT = 'ecomm-payment',
  PAYMENT = 'payment',
  POS = 'pos',
  LOYALTY = 'loyalty',
  GIFT_CARD = 'gift-card',
  EMAIL = 'email',
  TEXT = 'text',
  PUSH = 'push',
  DNS = 'dns',
}

export enum InstanceType {
  PRODUCTION = 'production',
  SANDBOX = 'sandbox',
}
