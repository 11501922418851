<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons
      slot="start"
      (click)="dismiss(false)"
    >
      <ion-button>
        <ion-icon
          slot="icon-only"
          name="close"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Enter Pin</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row class="code-container">
      <ion-col
        class="code-input"
        [class.full]="code.length > 0"
        >&bull;</ion-col
      >
      <ion-col
        class="code-input"
        [class.full]="code.length > 1"
        >&bull;</ion-col
      >
      <ion-col
        class="code-input"
        [class.full]="code.length > 2"
        >&bull;</ion-col
      >
      <ion-col
        class="code-input"
        [class.full]="code.length > 3"
        >&bull;</ion-col
      >
    </ion-row>
    <ion-row
      class="ion-no-padding"
      class="keypad"
    >
      <ion-col class="ion-no-padding">
        <ion-row class="ion-no-padding">
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="primary"
              (click)="addNum('1')"
              >1</ion-button
            >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="primary"
              (click)="addNum('2')"
              >2</ion-button
            >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="primary"
              (click)="addNum('3')"
              >3</ion-button
            >
          </ion-col>
        </ion-row>
        <ion-row class="ion-no-padding">
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="primary"
              (click)="addNum('4')"
              >4</ion-button
            >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="primary"
              (click)="addNum('5')"
              >5</ion-button
            >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="primary"
              (click)="addNum('6')"
              >6</ion-button
            >
          </ion-col>
        </ion-row>
        <ion-row class="ion-no-padding">
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="primary"
              (click)="addNum('7')"
              >7</ion-button
            >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="primary"
              (click)="addNum('8')"
              >8</ion-button
            >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="primary"
              (click)="addNum('9')"
              >9</ion-button
            >
          </ion-col>
        </ion-row>
        <ion-row class="ion-no-padding">
          <ion-col
            size="4"
            class="digit-container"
          >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="primary"
              (click)="addNum('0')"
              >0</ion-button
            >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="primary"
              (click)="back()"
            >
              <ion-icon name="backspace"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
