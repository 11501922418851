import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { ApiClient } from '../../../clients';
import { ApiDomain, ApiResources, ApiVersion } from '../../../enums';
import { PointOfSale, PointOfSaleUpdateParams } from '../../../models';
import { ModeService } from '../../../providers/config/mode.service';
import { AppState, errorHandler, showToast } from '../app.state';
import {
  loadPointOfSale,
  PointOfSaleActions,
  retrievedPointOfSale,
  updatePaymentDevice,
} from './point-of-sale.actions';

@Injectable()
export class PointOfSaleEffects {
  private apiClient: ApiClient;

  loadPointOfSale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PointOfSaleActions.RETRIEVE_POINT_OF_SALE),
      mergeMap((payload: { deviceId: string }) => {
        return this.apiClient.findById(payload.deviceId).pipe(
          mergeMap((pointOfSaleResult: any) => {
            if (!pointOfSaleResult.data) {
              return [{ type: PointOfSaleActions.NEED_TO_SETUP_POINT_OF_SALE }];
            }
            return [
              retrievedPointOfSale({
                pointOfSale: new PointOfSale(pointOfSaleResult.data),
              }),
            ];
          }),
          catchError(errorHandler)
        );
      })
    )
  );

  updatePointOfSale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PointOfSaleActions.UPDATE_POINT_OF_SALE),
      mergeMap((payload: { posId: string; update: PointOfSaleUpdateParams; skipToast?: true }) => {
        return this.apiClient.update(payload.posId, payload.update).pipe(
          mergeMap((updateResult: any) => {
            const actions: any[] = [loadPointOfSale({ deviceId: updateResult.data.deviceId })];
            if (!payload.skipToast) {
              actions.push(
                showToast({
                  toast: {
                    message: 'Update succeeded.',
                    color: 'success',
                  },
                })
              );
            }
            return actions;
          }),
          catchError(errorHandler)
        );
      })
    )
  );

  updatePaymentDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePaymentDevice),
      withLatestFrom(this.store$.select((state: AppState) => state.pointOfSale)),
      switchMap(([action, pointOfSale]) => {
        return [
          {
            type: PointOfSaleActions.UPDATE_POINT_OF_SALE,
            posId: pointOfSale.id,
            update: {
              ...(action.update && {
                reader: {
                  ...pointOfSale.data.reader,
                  ...action.update,
                },
              }),
              ...(!action.update && {
                reader: null,
              }),
            },
          },
        ];
      })
    )
  );

  constructor(
    httpClient: HttpClient,
    modeService: ModeService,
    private actions$: Actions,
    private store$: Store
  ) {
    this.apiClient = new ApiClient(httpClient, modeService.getMode()).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.POINT_OF_SALE
    );
  }
}
