import { createAction, props } from '@ngrx/store';
import { DiningOption } from '../../../models';

export enum DiningOptionAction {
  LOAD_DINING_OPTIONS = '[Dining Options] Load Dining Options',
  LOAD_DINING_OPTIONS_SUCCESS = '[Dining Options] Load Dining Options Success',
}

export const retrievedDiningOptions = createAction(
  DiningOptionAction.LOAD_DINING_OPTIONS_SUCCESS,
  props<{ diningOptions: DiningOption[] }>()
);
