import { createAction, props } from '@ngrx/store';
import { Printer } from '../../../models';

export enum PrinterActions {
  GET_PRINTERS = 'get-printers',
  SET_PRINTERS = 'set-printers',
}

export const getPrinters = createAction(PrinterActions.GET_PRINTERS);

export const setPrinters = createAction(
  PrinterActions.SET_PRINTERS,
  props<{ printers: Printer[] }>()
);
