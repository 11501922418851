import { createReducer, on } from '@ngrx/store';
import { retrievedShift, retrievedAllShifts } from './shift.actions';

export const shiftInitialState: Readonly<any> = null;
export const allShiftsInitialState: Readonly<any[]> = [];

export const shiftReducer = createReducer(
  shiftInitialState,
  on(retrievedShift, (state, { shift }) => shift)
);

export const allShiftsReducer = createReducer(
  allShiftsInitialState,
  on(retrievedAllShifts, (state, { shifts }) => shifts)
);
