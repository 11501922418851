import { createAction, props } from '@ngrx/store';
import { OrderFilterKey, OrderSortKey } from '../../../enums';
import { OrderData } from '../../../interfaces';
import { LocationData, Order, Venue } from '../../../models';

export enum OpenOrderAction {
  LOAD_ORDERS = 'load-orders',
  SET_ORDERS = 'set-orders',
  GET_ORDERS = 'get-orders',
  CLEAR_ORDERS = 'clear-orders',
  ADD_ORDER = 'add-order',
  REMOVE_ORDER = 'remove-order',
  UPDATE_ORDER = 'update-order',
  NEW_MESSAGE_FOR_ORDER = 'new-message-for-order',
  SORT_ORDERS = 'sort-orders',
  FILTER_ORDERS = 'filter-orders',
}

export const getOrders = createAction(OpenOrderAction.GET_ORDERS);

export const setOrders = createAction(OpenOrderAction.SET_ORDERS, props<{ orders: Order[] }>());

export const loadOrders = createAction(
  OpenOrderAction.GET_ORDERS,
  props<{
    location: LocationData;
    venue: Venue;
    hideLoading?: true;
  }>()
);

export const clearOrders = createAction(OpenOrderAction.CLEAR_ORDERS);

export const sortOrders = createAction(
  OpenOrderAction.SORT_ORDERS,
  props<{ sort: OrderSortKey }>()
);

export const filterOrders = createAction(
  OpenOrderAction.FILTER_ORDERS,
  props<{ filter: Partial<Record<OrderFilterKey, string>> }>()
);

export const addOrder = createAction(OpenOrderAction.ADD_ORDER, props<{ order: Order }>());

export const removeOrder = createAction(
  OpenOrderAction.REMOVE_ORDER,
  props<{ orderId: Order['id'] }>()
);

export const updateOrder = createAction(
  OpenOrderAction.UPDATE_ORDER,
  props<{ orderId: Order['id']; update: Partial<OrderData> }>()
);

export const newMessageForOrder = createAction(
  OpenOrderAction.NEW_MESSAGE_FOR_ORDER,
  props<{ orderId: Order['id'] }>()
);
