import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { PaymentSuccessIconComponent } from './payment-success-icon/payment-success-icon.component';
import { StripeReaderConnectionModalComponent } from './stripe-reader-connection-modal/stripe-reader-connection-modal.component';
import { StripeReaderModalController } from './stripe-reader-connection-modal/stripe-reader-connection-controller';
import { SelectLocationComponent } from './select-location/select-location.component';
import { PipesModule } from '../pipes/pipes.module';
import { SelectStationComponent } from './select-station/select-station.component';
import { NumPadModalComponent } from './num-pad-modal/num-pad-modal.component';
import { SelectStandSheetComponent } from './select-stand-sheet/select-stand-sheet.component';
import { SelectOrgComponent } from './select-org/select-org.component';
import { EmailModalComponent } from './email-modal/email-modal.component';
import { SelectPrinterModalComponent } from './select-printer-modal/select-printer-modal.component';
import { MemberLookupComponent } from './member-lookup/member-lookup.component';
import { MemberLookupModalController } from './member-lookup/member-lookup-modal.controller';
import { EditGuestModalComponent } from './edit-guest-modal/edit-guest-modal.component';
import { EditQuantityPopoverComponent } from './edit-quantity-popover/edit-quantity-popover.component';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { SearchModalController } from './search-modal/search-modal.controller';
import { PaymentGratuityComponent } from './payment-gratuity/payment-gratuity.component';
import { GratuityModalController } from './payment-gratuity/payment-gratuity-modal.controller';
import { PaymentCustomAmountModalComponent } from './payment-custom-amount-modal/payment-custom-amount-modal.component';
import { SelectEntityModalComponent } from './ select-entity-modal/select-entity-modal.component';

@NgModule({
  declarations: [
    ConfirmModalComponent,
    EditGuestModalComponent,
    EditQuantityPopoverComponent,
    EmailModalComponent,
    MemberLookupComponent,
    NumPadModalComponent,
    PaymentSuccessIconComponent,
    SearchModalComponent,
    SelectLocationComponent,
    SelectStationComponent,
    SelectStandSheetComponent,
    SelectOrgComponent,
    SelectPrinterModalComponent,
    SelectEntityModalComponent,
    StripeReaderConnectionModalComponent,
    PaymentGratuityComponent,
    PaymentCustomAmountModalComponent,
  ],
  exports: [
    ConfirmModalComponent,
    EditGuestModalComponent,
    EditQuantityPopoverComponent,
    EmailModalComponent,
    NumPadModalComponent,
    MemberLookupComponent,
    PaymentSuccessIconComponent,
    SelectLocationComponent,
    SelectStationComponent,
    SelectStandSheetComponent,
    SelectOrgComponent,
    SelectPrinterModalComponent,
    SelectEntityModalComponent,
    StripeReaderConnectionModalComponent,
    PaymentGratuityComponent,
    PaymentCustomAmountModalComponent,
  ],
  imports: [CommonModule, FormsModule, PipesModule, ReactiveFormsModule, IonicModule],
  providers: [
    StripeReaderModalController,
    MemberLookupModalController,
    SearchModalController,
    GratuityModalController,
    TitleCasePipe,
    CurrencyPipe,
  ],
})
export class SharedModule {}
