import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { PaymentState } from './payment.reducer';

export const selectPaymentState = createSelector(
  (state: AppState) => state.payment,
  (payment: PaymentState) => payment
);

export const selectServiceFee = createSelector(
  (state: AppState) => state.payment,
  (payment: PaymentState) => ({ serviceFee: payment.serviceFee })
);
