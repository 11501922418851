import { Injectable } from '@angular/core';
import { NativeAudio } from '@ionic-native/native-audio/ngx';

@Injectable({
  providedIn: 'root',
})
export class BeepService {
  private _loaded: boolean;

  constructor(private _nativeAudio: NativeAudio) {}

  public start() {
    this.notify();
  }

  public async stop() {
    if (this._loaded) {
      await this._nativeAudio.stop('music');
    }
  }

  private async notify() {
    if (this._loaded) {
      await this._nativeAudio.loop('music');
    } else {
      try {
        const result = await this._nativeAudio.preloadComplex('music', 'audio/Beep.mp3', 1, 1, 0);
        if (result) {
          this._loaded = true;
          await this._nativeAudio.loop('music');
        }
      } catch (e) {
        console.log('beep error');
        console.log(e);
      }
    }
  }
}
