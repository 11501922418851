<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button
        fill="clear"
        color="medium"
        size="small"
        (click)="cancel()"
      >
        <ion-icon
          slot="start"
          name="close"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-row>
    <ion-col class="ion-text-center">
      <p>{{ message }}</p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-button
        expand="block"
        color="medium"
        fill="outline"
        (click)="cancel()"
      >
        Cancel
      </ion-button>
    </ion-col>
    <ion-col>
      <ion-button
        expand="block"
        color="secondary"
        (click)="confirm()"
      >
        Confirm
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>
