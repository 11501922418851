import { createSelector } from '@ngrx/store';
import { FulfillmentMode } from '../../../enums';
import { AppState } from '../app.state';

export const selectLocation = createSelector(
  (state: AppState) => state.location,
  location => location
);

export const selectLocations = (opts: { fulfillmentMode?: FulfillmentMode }) =>
  createSelector(
    (state: AppState) => state.locations,
    locations => {
      if (!opts?.fulfillmentMode) {
        return locations;
      }
      return locations.filter(location => {
        return location.fulfillmentMode === opts.fulfillmentMode && location.kdsEnabled;
      });
    }
  );

export const selectLocationWithId = (locationId: string) => {
  return createSelector(selectLocations({}), locations => {
    return locations.find(location => {
      return location._id === locationId;
    });
  });
};
