import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppVersion } from '@ionic-native/app-version/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Device } from '@ionic-native/device/ngx';

import { PipesModule } from '@app/pipes/pipes.module';

import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';

import { AuthInterceptor } from '@app/interceptors/auth-interceptor';
import { AuthErrorInterceptor } from '@app/interceptors/auth-error-interceptor';

import { SharedModule } from '@app/shared/shared.module';
import { AuthCodeModalComponent } from './auth-code-modal/auth-code-modal.component';

import { GlobalErrorHandler } from './providers/global-error-handler';
import { appEffects, rootReducer } from './state';

@NgModule({
  declarations: [AppComponent, AuthCodeModalComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      platform: {
        /** The default `mobile` function returns true for devices with a touchscreen.
         * This is not always wanted, so this function tests the User Agent instead.
         * */
        mobile: win => {
          return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            win.navigator.userAgent
          );
        },
      },
    }),
    StoreModule.forRoot(rootReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot(appEffects),
    AppRoutingModule,
    PipesModule,
    SharedModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    NativeAudio,
    Device,
    AppVersion,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
