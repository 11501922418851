/* eslint-disable class-methods-use-this */
import _ from 'lodash';
import {
  DiningOptionsBehavior,
  DiningOptionsOrderType,
  DiscountType,
  OrderSource,
  OrderStatus,
  ServiceChargeBehavior,
} from '../enums';
import {
  CompItemData,
  OrderData,
  OrderDestinationData,
  OrderedItem,
  OrderedItemDiscountVoucher,
  OrderedItemModifier,
  OrderedItemTax,
  OrderServiceCharge,
} from '../interfaces';
import {
  DiningOption,
  DiscountData,
  MenuItem,
  MenuItemData,
  ModifierGroup,
  Order,
  ServiceChargeData,
  TaxCodeData,
} from '../models';
import TaxCalculator from './tax-calculator';

export default class OrderHelper {
  private activeDiningOption: DiningOption;

  constructor(
    private menuItemById: Record<string, MenuItem>,
    private taxesById: Record<string, any>,
    private modifierGroupsById: Record<string, ModifierGroup>,
    private usesV2 = false
  ) {}

  public static nameFromStatus(status: OrderStatus): string {
    switch (status) {
      case OrderStatus.POINT_OF_SALE:
        return 'tab';
      default:
        return status;
    }
  }

  public static nameFromOrderType(orderType: DiningOptionsOrderType): string {
    switch (orderType) {
      case DiningOptionsOrderType.FUTURE:
        return 'Future';
      default:
        return 'ASAP';
    }
  }

  public updateMenuItemsById(menuItemsById: Record<string, any>) {
    this.menuItemById = menuItemsById;
  }

  public setActiveDiningOption(diningOption: DiningOption) {
    this.activeDiningOption = diningOption;
  }

  private canAddV2(item: OrderedItem): boolean {
    const menuItem = _.get(this.menuItemById, item.item) || { data: {} as MenuItemData };
    const { availableQuantity } = menuItem.data;
    if (availableQuantity < 0) {
      return true;
    }
    if (availableQuantity >= item.quantity) {
      return true;
    }
    return false;
  }

  public canAdd(item: OrderedItem): boolean {
    if (this.usesV2) {
      return this.canAddV2(item);
    }
    const menuItem = _.get(this.menuItemById, item.item) || { data: {} as MenuItemData };
    const { inventoryItem } = menuItem.data;
    if (!inventoryItem?.isTracked) {
      return true;
    }
    if (inventoryItem?.isTracked && inventoryItem.quantity >= item.quantity) {
      return true;
    }
    return false;
  }

  private getTaxesFromOrderedItem(orderedItem: OrderedItem): TaxCodeData[] {
    const menuItem = _.get(this.menuItemById, orderedItem.item) || { data: { taxCodes: [] } };
    return menuItem.data.taxCodes.map((taxId: string) => {
      return this.taxesById[taxId];
    });
  }

  public setItemTaxes(orderedItem: OrderedItem) {
    const itemDiscountTotal = orderedItem.discountTotal;
    const menuItem = _.get(this.menuItemById, orderedItem.item) || { data: { taxCodes: [] } };
    const { unitPrice, quantity } = orderedItem;
    const itemPrice = unitPrice * quantity - itemDiscountTotal;
    orderedItem.taxes = menuItem.data.taxCodes.map((tax: any) => {
      const rate = (tax?.taxRate || 0) * 0.01;
      const total = Math.ceil(rate * itemPrice);
      return {
        id: tax._id,
        total,
      };
    });
    orderedItem.itemTaxTotal = orderedItem.taxes.reduce((prev: number, curr: OrderedItemTax) => {
      return prev + curr.total;
    }, 0);
  }

  public setTaxes(orderedItem: OrderedItem) {
    if (!this.usesV2) {
      this.setItemTaxes(orderedItem);
      orderedItem.taxTotal = orderedItem.itemTaxTotal;
      return;
    }
    const taxCodes = this.getTaxesFromOrderedItem(orderedItem);
    TaxCalculator.setTaxesForOrderedItem(orderedItem, taxCodes);
    TaxCalculator.setTaxesForOrderedItemModifiers(
      orderedItem,
      this.taxesById,
      this.modifierGroupsById
    );
    orderedItem.taxTotal = orderedItem.itemTaxTotal + orderedItem.modifierTaxTotal;
    orderedItem.taxIncludedTaxTotal = orderedItem.taxIncluded ? orderedItem.itemTaxTotal : 0;
    orderedItem.taxIncludedTaxTotal += orderedItem.modifiers.reduce(
      (taxIncludedTotal, modifier) => {
        if (modifier.taxIncluded) {
          return taxIncludedTotal + modifier.taxTotal;
        }
        return taxIncludedTotal;
      },
      0
    );
  }

  public canUseDiscount(item: OrderedItem['item'], discount: DiscountData): boolean {
    const menuItem = _.get(this.menuItemById, item);
    if (!_.size(menuItem?.data.discounts)) {
      return false;
    }
    return _.reduce(
      menuItem?.data.discounts,
      (prev: boolean, curr: DiscountData) => {
        if (discount._id === curr._id) {
          return true;
        }
        return prev;
      },
      false
    );
  }

  private getItemDiscountTotal(item: OrderedItem): number {
    const itemDiscountTotal = item.discounts?.reduce(
      (currTotal: number, itemDiscount: OrderedItemDiscountVoucher) => {
        return currTotal + itemDiscount.total || 0;
      },
      0
    );
    const modifierDiscountTotal = item.modifiers?.reduce(
      (modDiscountTotal: number, modifier: OrderedItemModifier) => {
        return (
          modDiscountTotal +
          modifier.discounts?.reduce(
            (currTotal: number, modifierDiscount: OrderedItemDiscountVoucher) => {
              return currTotal + modifierDiscount.total || 0;
            },
            0
          )
        );
      },
      0
    );
    return itemDiscountTotal + modifierDiscountTotal;
  }

  public calculateItemDiscount(item: OrderedItem, order: Order, discount: DiscountData): number {
    switch (discount.type) {
      case DiscountType.FIXED_AMOUNT: {
        const total = order.data.items.reduce((prev: number, curr: OrderedItem) => {
          return prev + this.getItemDiscountTotal(curr);
        }, 0);
        if (item.unitPrice * item.quantity + total <= discount.value) {
          return item.unitPrice * item.quantity;
        }
        return discount.value - total;
      }
      case DiscountType.PERCENT:
        return Math.ceil(item.unitPrice * item.quantity * (discount.value * 0.01));
      case DiscountType.COMPLIMENTARY:
        return item.unitPrice;
    }
    return 0;
  }

  public calculateModifierDiscount(
    modifier: OrderedItemModifier,
    order: Order,
    discount: DiscountData,
    itemQuantity: number
  ): number {
    switch (discount.type) {
      case DiscountType.FIXED_AMOUNT: {
        const total = order.data.items.reduce((prev: number, curr: OrderedItem) => {
          return prev + this.getItemDiscountTotal(curr);
        }, 0);
        if (modifier.unitPrice * modifier.quantity * itemQuantity + total <= discount.value) {
          return modifier.unitPrice * modifier.quantity * itemQuantity;
        }
        return discount.value - total;
      }
      case DiscountType.PERCENT:
        return Math.ceil(
          modifier.unitPrice * modifier.quantity * itemQuantity * (discount.value * 0.01)
        );
      case DiscountType.COMPLIMENTARY:
        return modifier.unitPrice;
    }
    return 0;
  }

  public clearDiscount(order: Order) {
    order.data.items.forEach((item: OrderedItem) => {
      item.discounts = [];
      item.itemDiscountTotal = 0;
      item.modifierDiscountTotal = 0;
      item.discountTotal = 0;
      item.modifiers.forEach(modifier => {
        modifier.discounts = [];
        modifier.discountTotal = 0;
      });
      this.setTaxes(item);
    });
  }

  public setDiscount(order: Order, discount: DiscountData): void {
    order.data.items.forEach((item: OrderedItem) => {
      item.discounts = [];
      item.itemDiscountTotal = 0;
      item.modifierDiscountTotal = 0;
      item.discountTotal = 0;
      item.modifiers.forEach(modifier => {
        modifier.discounts = [];
        modifier.discountTotal = 0;
      });
      if (this.canUseDiscount(item.item, discount)) {
        const itemDiscountValue = this.calculateItemDiscount(item, order, discount);
        if (itemDiscountValue) {
          item.itemDiscountTotal = itemDiscountValue;
          item.discounts.push({
            id: discount._id,
            total: item.itemDiscountTotal,
            type: discount.type,
          });
        }
        item.modifiers.forEach(modifier => {
          const modififierDiscountValue = this.calculateModifierDiscount(
            modifier,
            order,
            discount,
            item.quantity
          );
          if (modififierDiscountValue) {
            modifier.discountTotal = modififierDiscountValue;
            modifier.discounts.push({
              id: discount._id,
              total: modifier.discountTotal,
              type: discount.type,
            });
          }
        });
        item.modifierDiscountTotal = item.modifiers.reduce(
          (prev: number, curr: OrderedItemModifier) => {
            return prev + curr.discountTotal;
          },
          0
        );
        item.discountTotal = item.itemDiscountTotal + item.modifierDiscountTotal;
        this.setTaxes(item);
      }
    });
  }

  public setCompDiscount(order: Order, discount: DiscountData, compItems: CompItemData[]): any {
    order.data.items.forEach((item: OrderedItem, i: number) => {
      if (compItems[i].compQuantity && this.canUseDiscount(item.item, discount)) {
        item.discounts = [];
        const itemDiscountValue = this.calculateItemDiscount(item, order, discount);
        if (itemDiscountValue) {
          item.itemDiscountTotal = itemDiscountValue * compItems[i].compQuantity;
          item.discounts.push({
            id: discount._id,
            total: item.itemDiscountTotal,
            type: discount.type,
          });
        }
        item.modifiers.forEach(modifier => {
          const modififierDiscountValue = this.calculateModifierDiscount(
            modifier,
            order,
            discount,
            item.quantity
          );
          if (modififierDiscountValue) {
            modifier.discountTotal = modififierDiscountValue * compItems[i].compQuantity;
            modifier.discounts.push({
              id: discount._id,
              total: modifier.discountTotal,
              type: discount.type,
            });
          }
        });
        item.modifierDiscountTotal = item.modifiers.reduce(
          (prev: number, curr: OrderedItemModifier) => {
            return prev + curr.discountTotal;
          },
          0
        );
        item.discountTotal = item.itemDiscountTotal + item.modifierDiscountTotal;
        this.setTaxes(item);
      }
    });
  }

  /**
   * @todo TransactionData
   */
  public updateGratuityWithPercentage(transaction: any, gratuity: any) {
    const gratValue = gratuity.value * 0.01;
    transaction.gratuity = Math.ceil((transaction.total - transaction.gratuity) * gratValue);
    transaction.amount =
      transaction.subTotal - transaction.discount + transaction.taxTotal + transaction.serviceFee;
    transaction.total = transaction.amount + transaction.gratuity;
    return transaction;
  }

  /**
   * @todo TransactionData
   */
  public updateGratuityWithAmount(transaction: any, amount: number) {
    transaction.gratuity = amount;
    transaction.amount =
      transaction.subTotal - transaction.discount + transaction.taxTotal + transaction.serviceFee;
    transaction.total = transaction.amount + transaction.gratuity;
    return transaction;
  }

  public static getServiceChargeAmount(order: Order, serviceCharge: ServiceChargeData): number {
    let subtractTotal = 0;
    if (serviceCharge.excludeMenuItems?.length) {
      subtractTotal = order.data.items
        .filter((orderedItem: OrderedItem) => {
          return serviceCharge.excludeMenuItems.includes(orderedItem.item);
        })
        .reduce((acc: number, item: OrderedItem) => acc + item.price, 0);
    }
    switch (serviceCharge.behavior) {
      case ServiceChargeBehavior.FIXED_AMOUNT:
        return serviceCharge.value;
      case ServiceChargeBehavior.PERCENTAGE_ON_SUB_TOTAL:
        return Math.ceil((order.data.subTotal - subtractTotal) * serviceCharge.value);
      case ServiceChargeBehavior.PERCENTAGE_ON_TOTAL:
        return Math.ceil((order.data.total - subtractTotal) * serviceCharge.value);
      case ServiceChargeBehavior.PERCENTAGE_PRE_TAX:
        return Math.ceil(
          (order.data.subTotal - subtractTotal - order.data.discount) * serviceCharge.value
        );
    }
  }

  public applyServiceCharges(order: Order) {
    if (!this.activeDiningOption) {
      return;
    }
    if (!order.data.items.length) {
      order.data.serviceCharges = [];
      order.data.serviceFee = 0;
      order.data.partakeServiceFee = 0;
      return;
    }
    const serviceCharges = this.activeDiningOption.data.serviceCharges;
    const orderServiceCharges: OrderServiceCharge[] = [];
    let serviceChargeTotal = 0;
    let partakeServiceChargeTotal = 0;
    (serviceCharges as ServiceChargeData[])?.forEach(serviceCharge => {
      if (serviceCharge.isPartake || serviceCharge.isTenderCharge) {
        return;
      }
      const orderServiceCharge = {
        name: serviceCharge.name,
        amount: OrderHelper.getServiceChargeAmount(order, serviceCharge),
        serviceChargeId: serviceCharge._id,
        isPartake: !!serviceCharge.isPartake,
        isTenderCharge: false,
      };
      orderServiceCharges.push(orderServiceCharge);
      serviceChargeTotal += orderServiceCharge.amount;
      if (orderServiceCharge.isPartake) {
        partakeServiceChargeTotal += orderServiceCharge.amount;
      }
    });
    order.data.serviceCharges = orderServiceCharges;
    order.data.serviceFee = serviceChargeTotal;
    order.data.partakeServiceFee = partakeServiceChargeTotal;
  }

  public updateOrderDestination(
    order: Order,
    diningOption: DiningOption,
    orderDestination: OrderDestinationData
  ) {
    this.activeDiningOption = diningOption;
    order.data.orderDestination = orderDestination;
    this.applyServiceCharges(order);
  }

  public static updateItemPrice(
    unitPrice: number,
    quantity: number,
    modifiers: OrderedItemModifier[]
  ) {
    let price = quantity * unitPrice;
    if (modifiers.length) {
      modifiers.forEach(modifier => {
        if (modifier.price) {
          const modPrice = modifier.unitPrice * modifier.quantity;
          price += quantity * modPrice;
        }
      });
    }
    return price;
  }

  public defaultCart(
    restaurant: any,
    shiftId: string,
    orderDestination: OrderDestinationData,
    diningOption: DiningOption,
    guest?: any,
    member?: string
  ): Order {
    this.activeDiningOption = diningOption;
    const order = new Order({
      venue: restaurant.venue?._id || restaurant.venue,
      restaurant: restaurant._id,
      shift: shiftId,
      isConsumerOrder: false,
      status: OrderStatus.POINT_OF_SALE,
      source: OrderSource.POS_APP, // Make based on BUILD_SETTING
      orderDestination,
      guest,
      member,
      user: null,
      items: [],
      removed: [],
      void: [],
      subTotal: 0,
      discount: 0,
      taxTotal: 0,
      partakeTax: 0,
      gratuity: 0,
      serviceFee: 0,
      partakeServiceFee: 0,
      serviceCharges: [],
      roundUpTotal: 0,
      total: 0,
      amountPaid: 0,
      due: 0,
      alcoholCount: 0,
      shouldRoundUp: false,
      isFirstOrder: false,
      created: new Date(),
    } as OrderData);
    this.applyServiceCharges(order);
    return order;
  }

  static filterOpenOrders(orders: Order[], locationId: string): Order[] {
    return orders.filter(order => {
      const orderLocationId = (order.data.restaurant as any)?._id || order.data.restaurant;
      if (!order.data.isConsumerOrder) {
        return true;
      }
      if (order.data.isConsumerOrder && orderLocationId === locationId) {
        return true;
      }
      return false;
    });
  }

  static filterOpenOrdersByBehavior(orders: Order[], behaviors: DiningOptionsBehavior[]): Order[] {
    return orders.filter(order => {
      return behaviors.includes(order.data.orderDestination.behaviour);
    });
  }
}
