/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-promise-reject-errors */
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { ApiClient } from '../../clients';
import { ApiDomain, ApiHeader, ApiResources, ApiVersion } from '../../enums';
import { LocalStorage } from '../../helpers';
import { updatePaymentDevice } from '../../state';

declare const Stripe: any;
declare const StripeTerminal: any;

@Injectable({
  providedIn: 'root',
})
export class StripeLazyLoadService {
  private loadingPromise: Promise<any>;

  private stripeTerminalScript: HTMLScriptElement;

  constructor(private store: Store) {}

  private async getStripeKey(instanceType: string) {
    const liveMode = (await LocalStorage.getItem(ApiHeader.LIVE_MODE)) || 'true';
    return instanceType === 'production' && liveMode === 'true'
    ? environment.stripeProdPublicKey
    : environment.stripeSandboxPublicKey;
  }

  public async init(i9n: any): Promise<any> {
    if (!this.loadingPromise) {
      
      this.loadingPromise = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'stripejs';
        script.src = 'https://js.stripe.com/v3/';
        script.onload = async () => {
          // STRIPE INSTANIATION
          const i9nPublicKey = i9n.accountType === 'standard' ? i9n.syncData?.authData?.stripe_publishable_key : undefined;
          const stripekey = i9nPublicKey || (await this.getStripeKey(i9n.instanceType));
          const stripe = Stripe(stripekey);
          resolve({ stripe });
        };
        script.onerror = () => {
          reject({ error: 'Unable to load Stripe SDK at this time.' });
        };
        document.body.appendChild(script);
      });
    }
    return this.loadingPromise.then(success => {
      this.loadingPromise = null;
      return success;
    });
  }

  private async fetchConnectionToken() {
    const apiClient = new ApiClient().setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.TERMINAL
    );
    try {
      const result = await apiClient.create<any, any>({}).toPromise();
      return result.secret;
    } catch (err) {
      console.log(err);
      return '';
    }
  }

  public async loadTerminalSdk(): Promise<any> {
    if (!this.loadingPromise) {
      if (this.stripeTerminalScript) {
        this.stripeTerminalScript.remove();
      }
      this.loadingPromise = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'stripe-terminal-js';
        script.src = 'https://js.stripe.com/terminal/v1/';
        script.onload = () => {
          // STRIPE INSTANIATION
          const terminal = StripeTerminal.create({
            onFetchConnectionToken: this.fetchConnectionToken,
            onUnexpectedReaderDisconnect: () => {
              console.log('onUnexpectedReaderDisconnect');
              this.store.dispatch(updatePaymentDevice({ update: { isConnected: false } }));
            },
            onConnectionStatusChange: (event: any) => {
              console.log('onConnectionStatusChange', event);
            },
          });
          resolve({ terminal });
        };
        script.onerror = () => {
          reject({ error: 'Unable to load Stripe SDK at this time.' });
        };
        this.stripeTerminalScript = script;
        document.body.appendChild(script);
      });
    }
    return this.loadingPromise.then(success => {
      this.loadingPromise = null;
      return success;
    });
  }
}
