import { createReducer, on } from '@ngrx/store';
import { Printer } from '../../../models';
import { setPrinters } from './printer.actions';

export const printersInitialState: Readonly<Printer[]> = [];

export const printerReducer = createReducer(
  printersInitialState,
  on(setPrinters, (state, { printers }) => printers)
);
