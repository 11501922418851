import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderStatus',
})
export class OrderStatusPipe implements PipeTransform {
  transform(status: string): string {
    let orderStatus = '';
    switch (status) {
      case 'authorizing':
        orderStatus = 'Authorizing';
        break;
      case 'pending':
        orderStatus = 'Pending';
        break;
      case 'point-of-sale':
        orderStatus = 'POS';
        break;
      case 'out-for-delivery':
        orderStatus = 'Out for Delivery';
        break;
      case 'ready-for-pickup':
        orderStatus = 'Ready for Pickup';
        break;
      case 'cancelled':
        orderStatus = 'Cancelled';
        break;
      case 'delivered':
        orderStatus = 'Closed';
        break;
    }
    return orderStatus;
  }
}
