import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent {
  @Input() title: string;

  @Input() message: string;

  public name: string;

  constructor(private modalCtrl: ModalController) {}

  public search() {
    const cleanedName = (this.name || '').trim();
    if (!cleanedName) {
      return;
    }
    this.modalCtrl.dismiss({ name: this.name });
  }

  public cancel() {
    this.modalCtrl.dismiss();
  }
}
