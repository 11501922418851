import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { environment } from '@env/environment';

import { ModeService } from '../config/mode.service';
import { ApiResponse, Order } from '../../models';
import { OrderData, OrderedItem } from '../../interfaces';

@Injectable({ providedIn: 'root' })
export class OrderService {
  private _statusListener = new Subject<OrderData>();

  private domain: string;
  private path = 'order';
  private baseUrl: string;

  constructor(private http: HttpClient, private modeService: ModeService) {
    const mode = this.modeService.getMode();
    this.domain = environment[mode].url;
    this.baseUrl = `${this.domain}/${this.path}`;
  }

  public getStatusListener(): Observable<any> {
    return this._statusListener.asObservable();
  }

  public notifyStatusChange(order: Order) {
    this._statusListener.next(order.data);
  }

  /**
   * @description Create a cart for a user or shift
   * @param order The basic info needed to create an empty cart
   * @example
   * @returns Observable<any>
   */
  create(order: OrderData): Observable<OrderData> {
    return this.http.post<OrderData>(`${this.baseUrl}`, order);
  }

  /**
   * @description Get the logged in users order count
   * @returns Observable<any>
   */
  count(): Observable<any> {
    return this.http.get(`${this.baseUrl}/count`);
  }

  /**
   * @description Search for orders for the account with varias query params
   * @param query properties to search by
   * @example
   * {
   *    date: '2019-01-31',// the date you want orders for
   *    page: 1, // the current page used for pagination
   *    limit: 20, // the number of orders to be returned (default=20)
   *    shift: 'dkhgdfkg8953tdhg3580fdlgjl', // the specific shift (venues only)
   *    restaurant: 'dkhgdfkg8953tdhg3580fdlgjl', // the specific restaurant
   * }
   * @returns Observable<any>
   */
  find(query?: any): Observable<any> {
    if (query) {
      return this.http.get<any>(`${this.baseUrl}`, { params: query });
    }
    return this.http.get<any>(`${this.baseUrl}`);
  }

  /**
   * @description Get closed orders for account
   * @param query properties to search by
   * @example
   * {
   *    user: 'dkhgdfkg8953tdhg3580fdlgjl', // required
   *    limit: 20, // the number of orders to be returned (default=20)
   * }
   * @returns Observable<any>
   */
  history(query: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/history`, { params: query });
  }

  /**
   * @description Get the details of a single order
   * @param id The id of the order
   * @returns Observable<any>
   */
  findById(id: string): Observable<any> {
    if (!id) {
      throw new Error('Order ID is required');
    }
    return this.http.get<any>(`${this.baseUrl}/${id}`);
  }

  /**
   * @description Returns the open orders based on the query.
   * @param query properties to search by
   * @example
   * {
   *    user: '', // required - by consumer applications. `self` for logged in user
   *    restaurant: '', // optional - venue accounts only
   *    venue: '', // optional - venue accounts only
   * }
   *
   * @returns Observable<any>
   */
  getOpenOrders(query: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/open-orders`, { params: query });
  }

  /**
   * @description Add a menu item to an existing cart / order
   * @param id This is the order/cart id
   * @param item This is the details of the item being added to the order
   * @example
   * @returns Observable<any>
   */
  addItem(id: string, item: OrderedItem): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}/add-item`, item);
  }

  /**
   * @description Edit a line item in the order
   * @param id This is the order/cart id
   * @param item This is the details of the line item that needs to be edited
   * @example
   */
  editItem(id: string, item: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}/update-item`, item);
  }

  /**
   * @description Remove a specific line item from the order
   * @param id This is the order/cart id
   * @param item This is the details of the line item that needs to be removed
   * @example
   */
  removeItem(id: string, item: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}/remove-item`, item);
  }

  /**
   * @description Voids a line item from the order - only for venues/management
   * @param id This is the order/cart id
   * @param item This is the line item in the order's items array
   * @example
   */
  voidItem(id: string, item: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}/void-item`, item);
  }

  /**
   * @description Update order
   * @param id This is the order `_id`
   * @param body These are the properties to be updated - properties are limited
   */
  update(id: string, body: Partial<OrderData>): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}`, body);
  }

  save(id: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}/save-order`, {});
  }

  split(
    id: string,
    payload: { newOrderData: OrderData; originalOrderItems: OrderedItem[] }
  ): Observable<ApiResponse.RequestResult<{ order: OrderData; newOrder: OrderData }>> {
    return this.http.put<ApiResponse.RequestResult<{ order: OrderData; newOrder: OrderData }>>(
      `${this.baseUrl}/${id}/split-order`,
      payload
    );
  }

  mergeOrders(
    primaryOrderId: string,
    orderToMergeId: string
  ): Observable<ApiResponse.RequestResult<{ order: OrderData; newOrder: OrderData }>> {
    return this.http.post<ApiResponse.RequestResult<{ order: OrderData; newOrder: OrderData }>>(
      `${this.baseUrl}/merge-orders`,
      {
        primaryOrderId,
        orderToMergeId,
      }
    );
  }

  /**
   * @description Set the status of the order
   * @param id The order `_id`
   * @param update The object with the valid properties
   * @example
   * {
   *    status: 'accepted',
   *    expectedPickup: Date,
   *    utcOffset: '-5',
   *    acceptedTime: Date
   * }
   */
  setStatus(id: string, update: Partial<OrderData>): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/update_status/${id}`, update);
  }

  printCloudReceipt(
    orderId: string,
    transactionId?: string,
    printerId?: string,
    quantity?: number
  ): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/${orderId}/print-receipt`, {
      transactionId,
      printerId,
      quantity,
    });
  }
  printCloudFulfillment(orderId: string, printerId?: string, quantity?: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/${orderId}/print-fulfillment`, {
      printerId,
      quantity,
    });
  }
}
