import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiClient } from '../../../clients';
import { ApiDomain, ApiResources, ApiVersion } from '../../../enums';
import { OrderHelper } from '../../../helpers';
import { OrderData } from '../../../interfaces';
import { Order } from '../../../models';
import { ModeService } from '../../../providers/config/mode.service';
import { ActiveOrderAction } from './active-order.actions';
import { errorHandler } from '../app.state';

@Injectable()
export class ActiveOrderEffects {
  private apiClient: ApiClient;

  loadActiveOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveOrderAction.LOAD_ACTIVE_ORDER),
      mergeMap((payload: any) => {
        return this.apiClient.findById(payload.orderId).pipe(
          map((orderData: OrderData) => {
            return {
              type: ActiveOrderAction.LOAD_ACTIVE_ORDER_SUCCESS,
              order: new Order(orderData),
            };
          }),
          catchError(errorHandler)
        );
      })
    )
  );

  startActiveOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveOrderAction.START_ACTIVE_ORDER),
      map((payload: any) => {
        const { shiftId, location, guest, orderDestination, diningOption, member } = payload;
        const orderHelper = new OrderHelper({}, {}, {});
        return {
          type: ActiveOrderAction.LOAD_ACTIVE_ORDER_SUCCESS,
          order: orderHelper.defaultCart(
            location,
            shiftId,
            orderDestination,
            diningOption,
            guest,
            member
          ),
        };
      })
    )
  );

  constructor(httpClient: HttpClient, modeService: ModeService, private actions$: Actions) {
    this.apiClient = new ApiClient(httpClient, modeService.getMode()).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.ORDERS
    );
  }
}
