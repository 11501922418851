import { createReducer, on } from '@ngrx/store';
import { setGratuity, setServiceCharge } from './payment.actions';

export interface PaymentState {
  gratuity?: number;
  gratuityId?: string;
  serviceFee?: number;
  serviceFeeId?: string;
}

export const paymentInitialState: PaymentState = {};

export const paymentReducer = createReducer(
  paymentInitialState,
  on(setGratuity, setServiceCharge, (state, { type, ...update }) => {
    return {
      ...state,
      ...update,
    };
  })
);
