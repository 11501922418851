<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">
        <ion-icon
          slot="icon-only"
          name="close"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding container">
  <ion-row>
    <ion-col class="ion-text-center">
      <p>{{ message }}</p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="ion-text-center">
      <ion-list lines="none">
        <ion-item>
          <ion-input
            type="name"
            placeholder="Name"
            [(ngModel)]="name"
          ></ion-input>
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
</ion-content>
<ion-footer>
  <ion-row class="ion-padding">
    <ion-col>
      <ion-button
        expand="block"
        color="secondary"
        [disabled]="!name"
        (click)="search()"
      >
        Search
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
