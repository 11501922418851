import { createReducer, on } from '@ngrx/store';
import _ from 'lodash';
import { OrderFilterKey, OrderSortKey } from '../../../enums';
import { GuestData, Order } from '../../../models';
import {
  addOrder,
  removeOrder,
  updateOrder,
  setOrders,
  newMessageForOrder,
  sortOrders,
  filterOrders,
} from './open-order.actions';

export const openOrdersInitialState: {
  sort: OrderSortKey;
  filter: Partial<Record<OrderFilterKey, string>>;
  data: Order[];
  filteredData: Order[];
  lastUpdated: Date;
} = {
  sort: OrderSortKey.ORDER_NUMBER,
  filter: null,
  data: [],
  filteredData: [],
  lastUpdated: null,
};

function applySort(orders: Order[], sort: OrderSortKey): Order[] {
  return orders.sort((a, b) => {
    if (sort === 'orderNumber') {
      return b.data.orderNumber - a.data.orderNumber;
    }
    if (sort === 'name') {
      const tableA = _.lowerCase(a.getTableName() || '');
      const tableB = _.lowerCase(b.getTableName() || '');
      const guestAName = _.lowerCase((a.data.guest as GuestData)?.name);
      const guestBName = _.lowerCase((b.data.guest as GuestData)?.name);
      if (tableA && tableB) {
        return tableA.localeCompare(tableB);
      }
      if (tableA && !tableB) {
        return tableA.localeCompare(guestBName);
      }
      if (!tableA && tableB) {
        return guestAName.localeCompare(tableB);
      }
      return guestAName.localeCompare(guestBName);
    }
    return a.id.localeCompare(b.id);
  });
}

function getDiff(newOrders: Order[], stateOrders: Order[]): Order[] {
  const diff = [...stateOrders];
  // Only add new orders
  newOrders.forEach(newOrder => {
    const existingOrder = stateOrders.find(o => o.id === newOrder.id);
    if (!existingOrder) {
      diff.push(newOrder);
    }
  });
  /**
   * We want to filter out any orders which are not in the new orders list as this new order list
   * is the correct list of new orders. The state orders might be stale.
   */
  return diff.filter(order => {
    const isStateOrder = stateOrders.find(o => o.id === order.id);
    if (!isStateOrder) {
      return true;
    }
    const existingOrder = newOrders.find(o => o.id === order.id);
    if (!existingOrder) {
      return false;
    }
    return true;
  });
}

function applyFilter(orders: Order[], filter: Partial<Record<OrderFilterKey, string>>): Order[] {
  if (!filter) {
    return orders;
  }
  let filtered = orders;
  if (filter[OrderFilterKey.SHIFT_ID]) {
    filtered = orders.filter(order => {
      return order.data.items.find(item => item.shift === filter[OrderFilterKey.SHIFT_ID]);
    });
  }
  if (filter[OrderFilterKey.GUEST_NAME]) {
    filtered = orders.filter(order => {
      const guest = order.data.guest as GuestData;
      if (!guest) {
        return false;
      }
      return guest.name.toLowerCase().includes(filter[OrderFilterKey.GUEST_NAME].toLowerCase());
    });
  }
  return filtered;
}

export const openOrdersReducer = createReducer(
  openOrdersInitialState,
  on(setOrders, (state, { orders }) => {
    const data = applySort(getDiff(orders, state.data), state.sort);
    return {
      ...state,
      data,
      filteredData: applyFilter(data, state.filter),
      lastUpdated: new Date(),
    };
  }),
  on(addOrder, (state, { order }) => {
    const data = applySort([...state.data, order], state.sort);
    return {
      ...state,
      data,
      filteredData: applyFilter(data, state.filter),
      lastUpdated: new Date(),
    };
  }),
  on(removeOrder, (state, { orderId }) => {
    const data = state.data.filter(o => o.id !== orderId);
    return {
      ...state,
      data,
      filteredData: applyFilter(data, state.filter),
      lastUpdated: new Date(),
    };
  }),
  on(updateOrder, (state, { orderId, update }) => {
    const index = state.data.findIndex(o => o.id === orderId);
    if (index === -1) {
      return state;
    }
    const order = state.data[index];
    if (!order) {
      return state;
    }
    order.extend(update);
    const data = [...state.data.slice(0, index), order, ...state.data.slice(index + 1)];
    return {
      ...state,
      data,
      filteredData: applyFilter(data, state.filter),
      lastUpdated: new Date(),
    };
  }),
  on(sortOrders, (state, { sort }) => {
    const data = applySort([...state.data], sort);
    return {
      ...state,
      data,
      filteredData: applyFilter(data, state.filter),
    };
  }),
  on(filterOrders, (state, { filter }) => {
    return {
      ...state,
      filteredData: applyFilter(state.data, filter),
    };
  }),
  on(newMessageForOrder, (state, { orderId }) => {
    const order = state.data.find(openOrder => {
      if (openOrder.id === orderId) {
        return true;
      }
      return false;
    });
    if (order) {
      if (!order.messages) {
        order.messages = {
          _id: order.id,
          count: 0,
          unread: 0,
        };
      }
      order.messages.count++;
      order.messages.unread++;
      return state;
    }
  })
);
