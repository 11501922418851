import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { ModeService } from '../config/mode.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private _KEY = 'pt-employee';

  private domain: string;
  private path = 'employee';
  private baseUrl: string;

  constructor(private _http: HttpClient, private _modeService: ModeService) {
    const mode = this._modeService.getMode();
    this.domain = environment[mode].url_v2;
    this.baseUrl = `${this.domain}/${this.path}`;
  }

  create(body: any): Observable<any> {
    return this._http.post(`${this.baseUrl}`, body);
  }

  update(id: string, user: any): Observable<any> {
    return this._http.put(`${this.baseUrl}/${id}`, user);
  }

  find(query: any): Observable<any> {
    return this._http.get(`${this.baseUrl}`, { params: query });
  }

  findOne(id: string): Observable<any> {
    return this._http.get(`${this.baseUrl}/${id}`);
  }

  self(): Observable<any> {
    return this._http.get(`${this.baseUrl}/self`);
  }

  newEmployeeId(): Observable<any> {
    return this._http.get(`${this.baseUrl}/new-employee-id`);
  }

  newAccessCode(): Observable<any> {
    return this._http.get(`${this.baseUrl}/new-access-code`);
  }

  authenticate(body: any): Observable<any> {
    return this._http.post(`${this.baseUrl}/authenticate`, body);
  }

  getEmployee() {
    try {
      return JSON.parse(localStorage.getItem(this._KEY));
    } catch (e) {
      return null;
    }
  }

  setEmployee(employee: any) {
    if (employee) {
      localStorage.setItem(this._KEY, JSON.stringify(employee));
    } else {
      localStorage.removeItem(this._KEY);
    }
  }
}
