/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';

import { Device } from '@ionic-native/device/ngx';
import { DeviceSettings } from '@app/interfaces';
import { AppMode, OpenOrdersView } from '../../enums';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private _device: Device) {}

  private s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  private guid(): string {
    if (localStorage.getItem('guid')) {
      return localStorage.getItem('guid');
    }
    const guid = `${
      this.s4() + this.s4()
    }-${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}${this.s4()}${this.s4()}`.toUpperCase();
    localStorage.setItem('guid', guid);
    return guid;
  }

  public getUUID(): string {
    if (this._device.uuid) {
      return this._device.uuid;
    }
    return this.guid();
  }

  public getDefaultDeviceSettings(appMode: AppMode = AppMode.POS): DeviceSettings {
    return {
      posColumns: 4,
      categoryAsItem: false,
      mode: appMode,
      openOrdersView: OpenOrdersView.GRID,
      usesFullScreenModsViews: true,
    };
  }

  public saveSettings(settings: DeviceSettings): void {
    localStorage.setItem('device-settings', JSON.stringify(settings));
  }

  public getSettings(): DeviceSettings {
    try {
      const settingsStr = localStorage.getItem('device-settings');
      const settings = JSON.parse(settingsStr) as DeviceSettings;
      /**
       * @todo - remove in some future version
       */
      if (!Object.hasOwnProperty.call(settings, 'openOrdersView')) {
        settings.openOrdersView = OpenOrdersView.GRID;
      }
      return settings;
    } catch (e) {
      return null;
    }
  }

  public setDeviceMode(mode: AppMode): void {
    let settings = this.getSettings();
    if (!settings) {
      settings = this.getDefaultDeviceSettings(mode);
    }
    settings.mode = mode;
    this.saveSettings(settings);
  }
}
