export enum AppMode {
  POS = 'pos',
  KDS = 'kds',
  CDS = 'cds',
}

export enum OpenOrdersView {
  TABLE = 'table',
  ONLINE_ORDERING = 'online-ordering',
  GRID = 'grid',
}
