import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Order } from '../../models';
import { PaymentGratuityComponent } from './payment-gratuity.component';

interface GratuityModalOptions {
  order: Order;
  amount: number;
  buttonText?: string;
}

@Injectable({
  providedIn: 'root'
})
export class GratuityModalController {
  constructor(private modalCtrl: ModalController) {}

  public async show(componentProps: GratuityModalOptions): Promise<any> {
    const modal = await this.modalCtrl.create({
      component: PaymentGratuityComponent,
      cssClass: 'gratuity-modal',
      componentProps,
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    return data;
  }
}
