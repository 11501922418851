<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button
        fill="clear"
        color="medium"
        (click)="dismiss()"
      >
        <ion-icon
          slot="start"
          name="close"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Custom {{ title }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row class="custom-amount">
      <ion-col class="custom-value">
        <div class="icon-container">
          <ion-icon
            name="cash"
            color="light"
          ></ion-icon>
        </div>
        <div class="value">{{ value * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}</div>
      </ion-col>
    </ion-row>
    <ion-row class="ion-no-padding keypad">
      <ion-col class="ion-no-padding">
        <ion-row class="ion-no-padding">
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="light"
              (click)="addNum('1')"
              >1</ion-button
            >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="light"
              (click)="addNum('2')"
              >2</ion-button
            >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="light"
              (click)="addNum('3')"
              >3</ion-button
            >
          </ion-col>
        </ion-row>
        <ion-row class="ion-no-padding">
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="light"
              (click)="addNum('4')"
              >4</ion-button
            >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="light"
              (click)="addNum('5')"
              >5</ion-button
            >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="light"
              (click)="addNum('6')"
              >6</ion-button
            >
          </ion-col>
        </ion-row>
        <ion-row class="ion-no-padding">
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="light"
              (click)="addNum('7')"
              >7</ion-button
            >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="light"
              (click)="addNum('8')"
              >8</ion-button
            >
          </ion-col>
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="light"
              (click)="addNum('9')"
              >9</ion-button
            >
          </ion-col>
        </ion-row>
        <ion-row class="ion-no-padding">
          <ion-col
            size="4"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="light"
              (click)="addNum('0')"
              >0</ion-button
            >
          </ion-col>
          <ion-col
            size="8"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="light"
              (click)="addNum('00')"
              >00</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col
        class="ion-no-padding"
        size="3"
      >
        <ion-row class="ion-no-padding right ion-justify-content-around">
          <ion-col
            size="12"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="light"
              (click)="back()"
            >
              <ion-icon name="backspace"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col
            size="12"
            class="digit-container"
          >
            <ion-button
              class="digit"
              color="light"
              (click)="clear()"
              >C</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>

    <ion-row class="ion-margin-vertical">
      <ion-col>
        <ion-button
          expand="block"
          color="secondary"
          (click)="apply()"
        >
          Continue
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
