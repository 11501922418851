import { BaseModelData } from '../../interfaces';
import { AbstractModel } from '../abstract.model';

export interface StandSheetData extends BaseModelData {
  name: string;
  locationId: string;
  normalizedLocationNmae: string;
}

export class StandSheet extends AbstractModel<StandSheetData> {
  constructor(public data: StandSheetData) {
    super(data);
  }
}
