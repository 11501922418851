import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-payment-custom-amount-modal',
  templateUrl: './payment-custom-amount-modal.component.html',
  styleUrls: ['./payment-custom-amount-modal.component.scss'],
})
export class PaymentCustomAmountModalComponent {
  public title: string;

  public value = 0;

  constructor(private _modalCtrl: ModalController) {}

  public dismiss(val?: number) {
    this._modalCtrl.dismiss(val);
  }

  public apply() {
    this.dismiss(this.value);
  }

  public addNum(val: string) {
    const temp = this.value.toString() + val;
    this.value = Number(temp);
  }

  public clear() {
    this.value = 0;
  }

  public back() {
    let temp = this.value.toString();
    if (temp.length) {
      temp = temp.slice(0, -1);
    }
    this.value = Number(temp);
  }
}
