import { createSelector } from '@ngrx/store';
import { MenuItemStation } from '../../../models';
import { AppState } from '../app.state';

export const selectStations = createSelector(
  (state: AppState) => state.stations,
  (stations: MenuItemStation[]) => stations
);

export const selectedStation = createSelector(
  (state: AppState) => state.station,
  (station: MenuItemStation) => station
);

export const selectStationWithId = (stationId: string) => {
  return createSelector(
    (state: AppState) => state.stations,
    (stations: MenuItemStation[]) => {
      return stations.find(station => {
        return station.id === stationId;
      });
    }
  );
};
