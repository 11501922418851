import { createSelector } from '@ngrx/store';
import { Org } from '../../../models';
import { AppState } from '../app.state';

export const selectOrg = createSelector(
  (state: AppState) => state.org,
  (org: Org) => org
);

export const selectUserOrgs = createSelector(
  (state: AppState) => state.orgs,
  (orgs: Org[]) => orgs
);
