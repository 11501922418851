import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _loader: any;
  private showing = false;

  constructor(private _loaderCtrl: LoadingController) {}

  public async show(message?: string): Promise<any> {
    if (this.showing) {
      return true;
    }
    this.showing = true;
    this._loader = await this._loaderCtrl.create({ message });
    await this._loader.present();
    return true;
  }

  public async hide() {
    try {
      if (this._loader) {
        await this._loader.dismiss();
        this.showing = false;
      }
    } catch (err) {
      console.log(err);
      this.showing = false;
    }
  }
}
