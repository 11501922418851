import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemDeliveredCount',
})
export class ItemDeliveredCountPipe implements PipeTransform {
  transform(items: Array<any>): number {
    let count = 0;
    if (items && items.length) {
      count = items.reduce((prev, curr) => {
        return prev + curr.isDelivered ? curr.quantity : 0;
      }, 0);
    }
    return count;
  }
}
