// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import pkg from '../../package.json';

// const baseUrl = 'https://partakeapi.com';
const baseUrl = 'https://dev.partakeapi.com';
// const baseUrl = 'http://localhost:8080';

export const environment = {
  production: false,
  app: pkg.name,
  appVersion: pkg.version,
  apiVersion: '2',
  realmDbName: 'partake',
  stripeProdPublicKey: 'pk_live_QRYkRf0P7pfqMjn5dcrUJvaF',
  stripeSandboxPublicKey: 'pk_test_we0TWA247otFa2At2LgjKfdG',
  prod: {
    url: `${baseUrl}/api`,
    url_v2: `${baseUrl}/v2`,
    socket_url: 'https://demo.socket.partakeapi.com',
    stripePK: 'pk_test_we0TWA247otFa2At2LgjKfdG',
    realmAppId: 'partake-pos-demo-kntgk',
  },
  demo: {
    url: `${baseUrl}/api`,
    url_v2: `${baseUrl}/v2`,
    socket_url: 'https://demo.socket.partakeapi.com',
    stripePK: 'pk_test_we0TWA247otFa2At2LgjKfdG',
    realmAppId: 'partake-pos-demo-kntgk',
  },

  /**
   * NEW FORMAT
   */
  urls: {
    domains: {
      prod: {
        api: baseUrl,
        socket: 'https://demo.socket.partakeapi.com',
      },
      demo: {
        api: baseUrl,
        socket: 'https://demo.socket.partakeapi.com',
      },
    },
    versions: {
      v1: 'api',
      legacy_v2: 'api/v2',
      v2: 'v2',
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
