import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CartUtilService } from '@app/providers/cart/cart.util.service';
import { select } from '@ngrx/store';
import _ from 'lodash';
import { PaymentCustomAmountModalComponent } from '../payment-custom-amount-modal/payment-custom-amount-modal.component';
import { BaseComponent } from '../../base.component';
import { GratuityData, Order } from '../../models';
import { AppState } from '../../state';

const selectGratuities = (state: AppState) => {
  return state.menu?.gratuities || [];
};

@Component({
  selector: 'app-payment-gratuity',
  templateUrl: './payment-gratuity.component.html',
  styleUrls: ['./payment-gratuity.component.scss'],
})
export class PaymentGratuityComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() order: Order;

  @Input() amount: number;

  @Input() buttonText = 'Complete Payment';

  private state$ = this.store.pipe(select(selectGratuities));

  public gratOptions: GratuityData[];

  public selectedGratuity: { gratuityId: string; gratuity: number } = {
    gratuityId: null,
    gratuity: 0,
  };

  public loaded = false;

  constructor(private cartUtil: CartUtilService) {
    super();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  protected async init() {
    this.subscriptions.push(
      this.state$.subscribe(gratuities => {
        this.gratOptions = gratuities;
        if (this.gratOptions) {
          this.loaded = true;
          this.selectedGratuity.gratuityId = this.gratOptions[0]._id;
        }
      })
    );
  }

  public async setGratuity(gratuity: GratuityData) {
    this.selectedGratuity.gratuityId = gratuity._id;
    if (gratuity.type === 'percentage') {
      this.selectedGratuity.gratuity = this.cartUtil.getGratuityFromPercentage(
        this.amount,
        gratuity
      );
    } else {
      const modal = await this.modalCtrl.create({
        component: PaymentCustomAmountModalComponent,
        componentProps: {
          title: 'Gratuity',
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (_.isNumber(data)) {
        this.selectedGratuity.gratuity = data;
      }
    }
  }

  public async continueToPayment() {
    this.modalCtrl.dismiss(this.selectedGratuity);
  }
}
