/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { OrderedItemModifier } from '../../interfaces';
import { GratuityData } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class CartUtilService {
  public getModifiersTotal(modifiers: OrderedItemModifier[]): number {
    return modifiers.reduce((modTotals, mod) => {
      const modTotal = (mod.unitPrice || 0) * (mod.quantity || 1);
      return modTotals + modTotal;
    }, 0);
  }

  public getGratuityFromPercentage(amount: number, gratuity: GratuityData): number {
    const gratValue = gratuity.value * 0.01;
    return Math.ceil(amount * gratValue);
  }
}
