import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '@env/environment';
import { ApiResources, ApiDomain, ApiVersion } from '@app/enums';

import CacheClient from './cache-client';
import { ApiQuery } from './api-client.types';
import { ApiQueryParams } from './api-query-params';
import { ModeService } from '../providers/config/mode.service';
import { AppInjector } from '../app-injector.service';

export default class ApiClient {
  private http: HttpClient;
  private base: string;
  private domain: string;
  private version: string;
  private resource: string;
  private ttl = 15;
  private env = 'prod';

  private cachClient: CacheClient;
  private CACHE_COUNT = 1;

  constructor(httpClient?: HttpClient, env?: string) {
    this.http = httpClient || AppInjector.getInjector()?.get(HttpClient);
    this.env = env || AppInjector.getInjector()?.get(ModeService)?.getMode();
    this.cachClient = CacheClient.getInstance();
    return this;
  }

  private createUrl(parts: Array<string> = [], useDomain?: boolean) {
    if (useDomain) {
      if (!this.domain) {
        throw new Error('Invalid domain set');
      }
      return [this.domain, ...parts].filter(x => x).join('/');
    }
    return parts.filter(x => x).join('/');
  }

  public setDomain(domain: ApiDomain, version: ApiVersion, resource?: ApiResources) {
    this.base = environment.urls.domains[this.env][domain];
    this.version = environment.urls.versions[version];
    this.resource = resource || null;
    this.domain = this.createUrl([this.base, this.version, this.resource]);
    return this;
  }

  public setVersion(version: ApiVersion) {
    this.version = version;
    this.domain = this.createUrl([this.base, this.version, this.resource]);
    return this;
  }

  public setResource(resource: ApiResources) {
    this.resource = resource;
    this.domain = this.createUrl([this.base, this.version, this.resource]);
    return this;
  }

  public setCacheTtl(ttl: number) {
    this.ttl = ttl;
    return this;
  }

  public self<Response>(
    query: ApiQuery,
    subCollectionResource?: ApiResources | string
  ): Observable<Response> {
    const url = this.createUrl([subCollectionResource, 'self'], true);
    const params = new ApiQueryParams(query);
    const cacheUrl = `${url}?${params.toString()}`;
    const cached = this.cachClient.getRequest<Response>(cacheUrl);

    if (!cached) {
      const toCache$ = this.http.get<Response>(url, { params }).pipe(shareReplay(this.CACHE_COUNT));
      this.cachClient.cacheRequest({
        url,
        request: toCache$,
        ttl: this.ttl,
        cached: Date.now(),
      });
      return toCache$;
    }
    return cached;
  }

  public findById<Response>(
    id: string,
    subCollectionResource?: ApiResources | string
  ): Observable<Response> {
    const url = this.createUrl([subCollectionResource, id], true);
    const cached = this.cachClient.getRequest<Response>(url);
    if (!cached) {
      const toCache$ = this.http.get<Response>(url).pipe(shareReplay(this.CACHE_COUNT));
      this.cachClient.cacheRequest({
        url,
        request: toCache$,
        ttl: this.ttl,
        cached: Date.now(),
      });
      return toCache$;
    }
    return cached;
  }

  public get<Response>(
    query: ApiQuery,
    subCollectionResource?: ApiResources | string
  ): Observable<Response> {
    const url = this.createUrl([subCollectionResource], true);
    const params = new ApiQueryParams(query);
    const cacheUrl = `${url}?${params.toString()}`;
    const cached = this.cachClient.getRequest<Response>(cacheUrl);

    if (!cached) {
      const toCache$ = this.http.get<Response>(url, { params }).pipe(shareReplay(this.CACHE_COUNT));
      this.cachClient.cacheRequest({
        url: cacheUrl,
        request: toCache$,
        ttl: this.ttl,
        cached: Date.now(),
      });
      return toCache$;
    }
    return cached;
  }

  public create<Response, Payload>(
    body: Payload,
    subCollectionResource?: ApiResources | string
  ): Observable<Response> {
    this.cachClient.invalidateCache();
    const url = this.createUrl([subCollectionResource], true);
    return this.http.post<Response>(url, body);
  }

  public post<Response, Payload>(
    body: Payload,
    subCollectionResource?: ApiResources | string
  ): Observable<Response> {
    return this.create<Response, Payload>(body, subCollectionResource);
  }

  public update<Response, Payload>(
    id: string,
    body: Payload,
    subCollectionResource?: ApiResources | string
  ): Observable<Response> {
    this.cachClient.invalidateCache();
    const url = this.createUrl([subCollectionResource, id], true);
    return this.http.put<Response>(url, body);
  }

  public put<Response, Payload>(
    id: string,
    body: Payload,
    subCollectionResource?: ApiResources | string
  ): Observable<Response> {
    return this.update<Response, Payload>(id, body, subCollectionResource);
  }

  public delete<Response>(
    id: string,
    subCollectionResource?: ApiResources | string
  ): Observable<Response> {
    this.cachClient.invalidateCache();
    const url = this.createUrl([subCollectionResource, id], true);
    return this.http.delete<Response>(url);
  }

  public makeGraphQlRequest<Response, Variables = any>(
    query: string,
    variables?: Variables
  ): Observable<Response> {
    const url = this.createUrl([ApiResources.GRAPHQL], true);
    return this.http.post<Response>(url, {
      query,
      ...(variables && { variables }),
    });
  }
}
