export enum PointOfSaleType {
  POS = 'pos',
  KIOSK = 'kiosk',
}

export enum PointOfSaleStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export enum PrinterPaperWidth {
  MM32 = 32,
  MM42 = 42,
  MM48 = 48,
}
