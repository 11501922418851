<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button
        fill="clear"
        color="medium"
        size="small"
        (click)="cancel()"
      >
        <ion-icon
          slot="start"
          name="close"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-row>
    <ion-col
      size-xs="12"
      size-sm="6"
      *ngFor="let printer of printers"
    >
      <ion-button
        class="printer-button"
        expand="block"
        color="primary"
        size="large"
        (click)="confirm(printer)"
      >
        {{ printer.data.name }}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>
