import { createAction, props } from '@ngrx/store';

export enum PaymentActions {
  SET_GRATUITY = '[Payment] Set Gratuity',
  SET_SERVICE_CHARGE = '[Payment] Set Service Charge',
}

export const setGratuity = createAction(
  PaymentActions.SET_GRATUITY,
  props<{ gratuity: number; gratuityId: string }>()
);

export const setServiceCharge = createAction(
  PaymentActions.SET_SERVICE_CHARGE,
  props<{ serviceFee: number; serviceFeeId: string }>()
);
