import { createAction, props } from '@ngrx/store';

export enum LiveModeActions {
  SET_LIVE_MODE = 'set-live-mode',
}

export const setLiveMode = createAction(
  LiveModeActions.SET_LIVE_MODE,
  props<{ liveMode: 'true' | 'false' }>()
);
