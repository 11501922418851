import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameAbbr',
})
export class NameAbbreviationPipe implements PipeTransform {
  transform(name: string): string {
    if (!name) {
      return '';
    }
    const split = name.split(' ');
    const chars = split.slice(0, 2).map(x => x.charAt(0));
    return chars.join('').toUpperCase();
  }
}
