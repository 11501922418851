import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Member } from '../../models';
import { MemberLookupComponent } from './member-lookup.component';

@Injectable()
export class MemberLookupModalController {
  constructor(private modalCtrl: ModalController) {}

  public async show(): Promise<{ member?: Member }> {
    const modal = await this.modalCtrl.create({
      component: MemberLookupComponent,
      cssClass: 'member-lookup-modal',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    return data;
  }
}
