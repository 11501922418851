import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiClient } from '../../../clients';
import { ApiDomain, ApiResources, ApiVersion } from '../../../enums';
import { StateHelper } from '../../../helpers';
import { ApiResponse, Venue, VenueData } from '../../../models';
import { ModeService } from '../../../providers/config/mode.service';
import { errorHandler } from '../app.state';
import { VenueActions, retrievedVenue } from './venue.actions';

@Injectable()
export class VenueEffects {
  private apiClient: ApiClient;

  loadVenue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VenueActions.LOAD_VENUE),
      mergeMap(() => {
        return this.apiClient.self({}).pipe(
          map((venueResult: ApiResponse.LegacyRequestResult<VenueData>) => {
            if (venueResult) {
              const venue = new Venue(venueResult);
              StateHelper.setVenue(venue);
              return retrievedVenue({ venue });
            }
            return retrievedVenue({ venue: null });
          }),
          catchError(errorHandler)
        );
      })
    )
  );

  constructor(httpClient: HttpClient, modeService: ModeService, private actions$: Actions) {
    this.apiClient = new ApiClient(httpClient, modeService.getMode()).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.VENUES
    );
  }
}
