import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { ModeService } from '../config/mode.service';
import { IShift } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ShiftService {
  private _KEY = 'pt-shift';

  private domain: string;
  private path = 'shift';
  private baseUrl: string;

  constructor(private _http: HttpClient, private _modeService: ModeService) {
    const mode = this._modeService.getMode();
    this.domain = environment[mode].url;
    this.baseUrl = `${this.domain}/${this.path}`;
  }

  self(): Observable<any> {
    return this._http.get(`${this.baseUrl}/self`);
  }

  create(body: any): Observable<any> {
    return this._http.post(`${this.baseUrl}`, body);
  }

  find(query: any): Observable<any> {
    return this._http.get(`${this.baseUrl}`, { params: query });
  }

  findOne(id: string): Observable<any> {
    return this._http.get(`${this.baseUrl}/${id}`);
  }

  update(id: string, body: any): Observable<any> {
    return this._http.put(`${this.baseUrl}/${id}`, body);
  }

  heartbeat(id: any): Observable<any> {
    return this._http.put(`${this.baseUrl}/${id}/heartbeat`, {});
  }

  setShift(shift?: IShift): void {
    if (shift) {
      localStorage.setItem(this._KEY, JSON.stringify(shift));
    } else {
      localStorage.removeItem(this._KEY);
    }
  }

  getShift(): IShift {
    try {
      return JSON.parse(localStorage.getItem(this._KEY));
    } catch (e) {
      return null;
    }
  }
}
