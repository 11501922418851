import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IntegrationTypes } from '../../../enums/integrations';
import { AppState } from '../app.state';

export const selectIntegrations = createSelector(
  (state: AppState) => state.integrations,
  (integrations: Array<any>) => integrations
);

export const selectIntegrationsState = createFeatureSelector< Readonly<IntegrationTypes>>(
  'integrations'
);

export const selectIntegrationType = (integrationType: IntegrationTypes) =>
  createSelector(selectIntegrations, selectIntegrationsState, (integrations: Array<any>) => {
    return integrations?.find(integration => {
      return integration.type === integrationType;
    });
  });
