import { DiningOptionEffects, diningOptionsReducer } from './dining-options';
import { LocationEffects, locationReducer, allLocationsReducer } from './locations';
import { ShiftEffects, allShiftsReducer, shiftReducer } from './shift';
import { integrationsReducer } from './integrations';
import { activeOrderReducer, ActiveOrderEffects } from './order';
import { OrgEffects, orgReducer, orgsReducer } from './org';
import { PointOfSaleEffects, pointOfSaleReducer } from './point-of-sale';
import { toastReducer } from './app.state';
import { paymentReducer } from './payment';
import { StationEffects, stationsReducer } from './stations';
import { MenuEffects, menuReducer } from './menu';
import { PrinterEffects, printerReducer } from './printers';
import { liveModeReducer } from './live-mode';
import { VenueEffects, venueReducer } from './venue';
import { OpenOrderEffects, openOrdersReducer } from './open-orders';

export const appEffects: any[] = [
  ActiveOrderEffects,
  DiningOptionEffects,
  LocationEffects,
  StationEffects,
  OpenOrderEffects,
  OrgEffects,
  PointOfSaleEffects,
  ShiftEffects,
  MenuEffects,
  PrinterEffects,
  VenueEffects,
];

export const rootReducer = {
  pointOfSale: pointOfSaleReducer,
  diningOptions: diningOptionsReducer,
  integrations: integrationsReducer,
  shift: shiftReducer,
  shifts: allShiftsReducer,
  stations: stationsReducer,
  location: locationReducer,
  locations: allLocationsReducer,
  order: activeOrderReducer,
  orders: openOrdersReducer,
  org: orgReducer,
  orgs: orgsReducer,
  payment: paymentReducer,
  toast: toastReducer,
  menu: menuReducer,
  printers: printerReducer,
  liveMode: liveModeReducer,
  venue: venueReducer,
};

export * from './app.state';
export * from './dining-options';
export * from './integrations';
export * from './live-mode';
export * from './locations';
export * from './menu';
export * from './order';
export * from './open-orders';
export * from './org';
export * from './payment';
export * from './point-of-sale';
export * from './printers';
export * from './shift';
export * from './stations';
export * from './venue';
