import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { ApiClient } from '../../../clients';
import { ApiDomain, ApiVersion, ApiResources } from '../../../enums';
import { ModeService } from '../../../providers/config/mode.service';
import { ShiftService } from '../../../providers/shift/shift.service';
import { errorHandler } from '../app.state';
import { clearMenu, loadMenu } from '../menu';
import { ShiftAction, retrievedShift, retrievedAllShifts } from './shift.actions';
import { IShift } from '../../../models';
import { clearOrders } from '../open-orders';

@Injectable()
export class ShiftEffects {
  private apiClient: ApiClient;

  /**
   * This is essentially the start of the day
   */
  loadShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftAction.LOAD_SHIFT),
      mergeMap(() => {
        const shiftId = this.shiftService.getShift()?._id;
        if (!shiftId) {
          return EMPTY;
        }
        return this.apiClient.findById(shiftId).pipe(
          mergeMap((shift: IShift) => {
            return [retrievedShift({ shift }), loadMenu()];
          }),
          catchError(errorHandler)
        );
      })
    )
  );

  loadAllShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftAction.LOAD_ALL_SHIFTS),
      mergeMap(() => {
        return this.apiClient.self({}).pipe(
          mergeMap((shifts: any[]) => {
            return [retrievedAllShifts({ shifts })];
          }),
          catchError(errorHandler)
        );
      })
    )
  );

  exitShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftAction.EXIT_SHIFT),
      mergeMap(() => {
        Sentry.setContext('shift', {});
        this.shiftService.setShift();
        return [retrievedShift({ shift: null }), clearMenu(), clearOrders()];
      })
    )
  );

  constructor(
    httpClient: HttpClient,
    modeService: ModeService,
    private actions$: Actions,
    private shiftService: ShiftService
  ) {
    this.apiClient = new ApiClient(httpClient, modeService.getMode()).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.SHIFT
    );
  }
}
