import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderSortProperty',
})
export class OrderSortProperyPipe implements PipeTransform {
  transform(property: string): string {
    let name = '';
    switch (property) {
      case 'orderNumber':
        name = 'Order';
        break;
      case 'name':
        name = 'Name';
        break;
    }
    return name;
  }
}
