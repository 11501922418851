import { createReducer, on } from '@ngrx/store';
import { Order } from '../../../models';
import { retrievedActiveOrder } from './active-order.actions';

export const activeOrderInitialState: Order = null;

export const activeOrderReducer = createReducer(
  activeOrderInitialState,
  on(retrievedActiveOrder, (state, { order }) => order)
);
