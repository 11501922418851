import { createAction, props } from '@ngrx/store';
import { PointOfSale, PosCardReader } from '../../../models';

export enum PointOfSaleActions {
  RETRIEVE_POINT_OF_SALE = 'Retrieve POS',
  RETRIEVED_POINT_OF_SALE = 'Retrieved POS',
  UPDATE_POINT_OF_SALE = 'Update POS',
  UPDATE_PAYMENT_DEVICE = 'Update POS Payment Device',
  NEED_TO_SETUP_POINT_OF_SALE = 'POS needs to be setup',
}

export const loadPointOfSale = createAction(
  PointOfSaleActions.RETRIEVE_POINT_OF_SALE,
  props<{ deviceId: string }>()
);

export const updatePaymentDevice = createAction(
  PointOfSaleActions.UPDATE_PAYMENT_DEVICE,
  props<{ update: Partial<PosCardReader> }>()
);

export const retrievedPointOfSale = createAction(
  PointOfSaleActions.RETRIEVED_POINT_OF_SALE,
  props<{ pointOfSale: PointOfSale }>()
);
