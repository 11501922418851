import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError } from 'rxjs/operators';
import { ApiClient } from '../../../clients';
import { ApiDomain, ApiResources, ApiVersion } from '../../../enums';
import {
  ApiResponse,
  DiningOption,
  FloorPlan,
  Location,
  Menu,
  MenuItem,
  MenuResponse,
  MenuSection,
  ModifierGroup,
  Venue,
} from '../../../models';
import { ModeService } from '../../../providers/config/mode.service';
import { errorHandler } from '../app.state';
import { retrievedDiningOptions } from '../dining-options';
import { retrievedLocation } from '../locations';
import { loadOrders } from '../open-orders';
import { loadedMenu, MenuActions } from './menu.actions';

@Injectable()
export class MenuEffects {
  private apiClient: ApiClient;

  loadMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.LOAD_MENU),
      mergeMap(() => {
        return this.apiClient.get<ApiResponse.RequestResult<MenuResponse>>({}).pipe(
          mergeMap(menuResult => {
            const {
              location,
              menu,
              menuSections,
              menuItems,
              modifierGroups,
              diningOptions: diningOptionsData,
              floorPlans,
              serviceCharges,
              taxCodes,
              discounts,
              gratuities,
              tenderMap,
              gratuityMap,
              venue: venueData,
            } = menuResult.data;
            const diningOptions = diningOptionsData.map(
              diningOptionData => new DiningOption(diningOptionData)
            );
            const venue = new Venue(venueData);
            return [
              loadedMenu({
                menu: menu
                  ? {
                      updatedAt: new Date(),
                      location: new Location(location),
                      menu: menu ? new Menu(menu) : null,
                      menuSections: menuSections.map(section => new MenuSection(section)),
                      menuItems: menuItems.map(menuItemData => new MenuItem(menuItemData)),
                      modifierGroups: modifierGroups.map(modData => new ModifierGroup(modData)),
                      floorPlans: floorPlans.map(floorPlanData => new FloorPlan(floorPlanData)),
                      serviceCharges,
                      diningOptions,
                      taxCodes,
                      discounts,
                      tenderMap,
                      gratuities,
                      gratuityMap,
                      venue,
                    }
                  : null,
              }),
              retrievedDiningOptions({ diningOptions }),
              retrievedLocation({ location }),
              loadOrders({ location, venue }),
            ];
          }),
          catchError(errorHandler)
        );
      })
    )
  );

  clearMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.CLEAR_MENU),
      mergeMap(() => {
        return [
          loadedMenu({
            menu: null,
          }),
          retrievedDiningOptions({ diningOptions: [] }),
          retrievedLocation({ location: null }),
        ];
      })
    )
  );

  constructor(httpClient: HttpClient, modeService: ModeService, private actions$: Actions) {
    this.apiClient = new ApiClient(httpClient, modeService.getMode())
      .setDomain(ApiDomain.API, ApiVersion.V2, ApiResources.MENU)
      .setCacheTtl(0);
  }
}
