import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from './guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'kds',
    loadChildren: () => import('./kds/kds.module').then(m => m.KdsModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'shift',
    loadChildren: () => import('./shift/shift.module').then(m => m.ShiftModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'orders',
    loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'concessions',
    loadChildren: () => import('./concession/concession.module').then(m => m.ConcessionModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
    canActivate: [LoggedInGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
