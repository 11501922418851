import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiClient } from '../../../clients';
import { ApiDomain, ApiResources, ApiVersion } from '../../../enums';
import { ApiResponse, LocationData } from '../../../models';
import { ModeService } from '../../../providers/config/mode.service';
import { errorHandler } from '../app.state';
import { LocationAction, loadedAllLocations, retrievedLocation } from './locations.actions';

@Injectable()
export class LocationEffects {
  private apiClient: ApiClient;

  loadLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationAction.LOAD_ALL_LOCATIONS),
      mergeMap(() => {
        return this.apiClient
          .get<ApiResponse.RequestResult<LocationData[]>>({
            limit: 100,
          })
          .pipe(
            map(locationResult => {
              return loadedAllLocations({
                locations: locationResult.data,
              });
            }),
            catchError(errorHandler)
          );
      })
    )
  );

  loadLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationAction.LOAD_LOCATION),
      mergeMap((payload: { locationId: string }) => {
        return this.apiClient.findById(payload.locationId).pipe(
          map((locationResult: any) => {
            return retrievedLocation({
              location: locationResult,
            });
          }),
          catchError(errorHandler)
        );
      })
    )
  );

  constructor(httpClient: HttpClient, modeService: ModeService, private actions$: Actions) {
    this.apiClient = new ApiClient(httpClient, modeService.getMode()).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.LOCATION
    );
  }
}
