import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '@app/providers/user/user.service';
import { AuthService } from '../providers/auth/auth.service';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private _router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this._authService.setToken();
          this._userService.setMe();
          this._router.navigate(['/']);
        }
        return throwError(error);
      })
    );
  }
}
