<ion-app>
  <ion-split-pane
    [when]="false"
    contentId="main"
  >
    <ion-menu
      class="side-menu"
      contentId="main"
    >
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>
            <div>{{ shift?.restaurant?.name }}</div>
            <div
              class="employee"
              *ngIf="shift?.openingBy"
            >
              {{ shift?.openingBy?.name }}
            </div>
          </ion-title>
          <ion-buttons slot="end">
            <ion-menu-toggle auto-hide="false">
              <ion-button
                id="change-shift"
                [routerDirection]="'root'"
                [routerLink]="['/shift/open-shifts']"
                color="secondary"
                fill="solid"
                size="small"
                >Change</ion-button
              >
            </ion-menu-toggle>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list
          lines="none"
          color="primary"
        >
          <ion-item-group *ngFor="let g of appPages">
            <ion-menu-toggle
              auto-hide="false"
              *ngFor="let p of g.urls"
            >
              <ion-item
                [id]="p.id"
                [routerDirection]="'root'"
                [routerLink]="[p.url]"
                routerLinkActive="active"
              >
                <ion-icon
                  slot="start"
                  [name]="p.icon"
                ></ion-icon>
                <ion-label>
                  {{ p.title }}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-item-group>
          <ion-item-group *ngIf="shift">
            <ion-menu-toggle
              auto-hide="false"
              *ngIf="pointOfSale?.data?.cashDrawer?.port"
            >
              <ion-item
                id="pop-cash-drawer"
                detail
                (click)="popCashdrawer()"
              >
                <ion-icon
                  slot="start"
                  name="cash-outline"
                ></ion-icon>
                <ion-label> Pop Cashdrawer </ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item
                id="exit-shift"
                detail
                (click)="exitShift()"
              >
                <ion-icon
                  slot="start"
                  name="exit-outline"
                ></ion-icon>
                <ion-label> Exit Shift </ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item
                id="close-shift"
                (click)="closeShift()"
              >
                <ion-icon
                  slot="start"
                  name="close-circle-outline"
                ></ion-icon>
                <ion-label>
                  Close Register
                  <ng-container *ngIf="shiftOrders">({{ shiftOrders }} open orders)</ng-container>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-item-group>
        </ion-list>
        <ion-list
          lines="none"
          color="primary"
          class="version"
        >
          <ion-item>
            <ion-label class="ion-text-center">
              v{{ version }}<span *ngIf="nativeAppVersion">&nbsp;({{ nativeAppVersion }})</span>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main">
      <div
        class="connection-status"
        *ngIf="liveMode === 'false' && !dowloadingUpdate"
      >
        <ion-row class="ion-no-padding">
          <ion-col class="ion-no-padding ion-text-center">Training Mode</ion-col>
          <ion-col class="ion-no-padding ion-text-center">Training Mode</ion-col>
          <ion-col class="ion-no-padding ion-text-center">Training Mode</ion-col>
        </ion-row>
      </div>
      <div
        class="connection-status updating"
        *ngIf="dowloadingUpdate"
      >
        <ion-row class="ion-no-padding">
          <ion-col class="ion-no-padding ion-text-center"
            >{{ progressState }} {{ progress }}%</ion-col
          >
        </ion-row>
      </div>
    </ion-router-outlet>
  </ion-split-pane>
</ion-app>
