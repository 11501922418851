import _ from 'lodash';
import { OrderDestinationData } from '../interfaces';
import { DiningOptionsBehavior, DiningOptionsOrderType } from '../enums';
import { DiningOption } from '../models';

export default class OrderDestinationHelper {
  public static findOption(options: any[], name: string) {
    return options.find((opt: any) => {
      return opt.name === name;
    });
  }

  public static emptyLocation() {
    return {
      option1: null,
      option2: null,
      option3: null,
      option4: null,
    };
  }

  public static emptyTimeInfo() {
    return {
      selectedDate: null,
      selectedTime: null,
      selectedTimeString: null,
    };
  }

  public static emptyRawOrderDestination() {
    return {
      diningOption: null,
      behaviour: null,
      orderType: null,
      location: OrderDestinationHelper.emptyLocation(),
      ...OrderDestinationHelper.emptyTimeInfo(),
    };
  }

  public static rawOrderDestinationFromDiningOption(diningOption: DiningOption) {
    const orderDestination = OrderDestinationHelper.emptyRawOrderDestination();
    orderDestination.behaviour = diningOption.data.behaviour;
    orderDestination.diningOption = diningOption;
    orderDestination.orderType = DiningOptionsOrderType.NOW;
    return orderDestination;
  }

  public static getOrderDestinationFromDiningOptions(
    diningOption: DiningOption
  ): OrderDestinationData {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { diningOption: ignoredField, ...orderDestinationData } =
      OrderDestinationHelper.emptyRawOrderDestination();
    return {
      ...orderDestinationData,
      diningOptionId: diningOption.id,
      behaviour: diningOption.data.behaviour,
      orderType: DiningOptionsOrderType.NOW,
    };
  }

  public static rawDestinationFromDestination(
    diningOptions: Array<DiningOption>,
    destination: OrderDestinationData
  ) {
    const rawDestination: any = {
      behaviour: destination.behaviour,
      orderType: destination.orderType,
      location: OrderDestinationHelper.emptyLocation(),
      ...(destination.selectedDate && { selectedDate: destination.selectedDate }),
      ...(destination.selectedTime && { selectedTime: destination.selectedTime }),
      ...(destination.selectedTimeString && { selectedTimeString: destination.selectedTimeString }),
    } as any;
    const diningOption = diningOptions?.find((option: DiningOption) => {
      return option.id === destination.diningOptionId;
    });
    rawDestination.diningOption = diningOption;
    // Delivery
    if (_.size(rawDestination.diningOption?.get('deliveryOptions.options'))) {
      const opt1 = OrderDestinationHelper.findOption(
        diningOption.get('deliveryOptions.options'),
        destination.location?.option1
      );
      if (opt1) {
        rawDestination.location.option1 = opt1;
        const opt2 = OrderDestinationHelper.findOption(opt1.options, destination.location?.option2);
        if (opt2) {
          rawDestination.location.option2 = opt2;
          const opt3 = OrderDestinationHelper.findOption(
            opt2.options,
            destination.location?.option3
          );
          if (opt3) {
            rawDestination.location.option3 = opt3;
            const opt4 = OrderDestinationHelper.findOption(
              opt3.options,
              destination.location?.option4
            );
            if (opt4) {
              rawDestination.location.option4 = opt4;
            }
          }
        }
      }
    }
    return rawDestination;
  }

  public static isValid(destination: OrderDestinationData, rawOrderDestination: any): boolean {
    // Basic requirements
    if (!destination?.orderType || !destination.behaviour) {
      return false;
    }
    // Future Order Requirments
    if (destination?.orderType === DiningOptionsOrderType.FUTURE) {
      if (!destination?.selectedDate) {
        return false;
      }
      if (
        !rawOrderDestination.diningOption.get('futureSchedule.timeGapConfig.skipTimeRequirement') &&
        !destination?.selectedTime
      ) {
        return false;
      }
    }

    if (DiningOptionsBehavior.TABLE && rawOrderDestination.diningOption.data.floorPlanId) {
      return true;
    }

    // Everything except a tab, fast-close will have at least 1 option to choose from
    // Event will always have 3 - see below
    if (
      ![DiningOptionsBehavior.TAB, DiningOptionsBehavior.FAST_CLOSE, DiningOptionsBehavior.EVENT].includes(destination?.behaviour)
    ) {
      if (!destination?.location?.option1) {
        return false;
      }
      // Nested options - we only enable 4 levels deep
      if (
        _.size(rawOrderDestination.location.option1?.options) &&
        !destination?.location?.option2
      ) {
        return false;
      }
      if (
        _.size(rawOrderDestination.location.option2?.options) &&
        !destination?.location?.option3
      ) {
        return false;
      }
      if (
        _.size(rawOrderDestination.location.option3?.options) &&
        !destination?.location?.option4
      ) {
        return false;
      }
    }
    if ([DiningOptionsBehavior.EVENT].includes(destination?.behaviour)) {
      if (!destination?.location?.option1) {
        return false;
      }
      if (!destination?.location?.option2) {
        return false;
      }
      if (!destination?.location?.option3) {
        return false;
      }
    }
    return true;
  }
}
