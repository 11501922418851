import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  // eslint-disable-next-line class-methods-use-this
  handleError(err: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(err.message)) {
      window.location.reload();
    }
    console.log('GlobalErrorHandler.handleError', err);
  }
}
