import moment from 'moment';
import { ShiftStatus } from '../../enums';
import { BaseModelData } from '../../interfaces';
import { AbstractModel } from '../abstract.model';
import { IInventoryItem } from './inventory-item';
import { LocationData } from './location';
import { ProductData } from './product';

export interface IShiftInventoryItem {
  name: string;
  inventoryItemId: string;
  inventoryItem?: IInventoryItem;
  usesDayCounts: boolean;
  productId: string;
  product?: ProductData;
  openQuantity: number;
  closeQuantity: number;
  soldQuantity: number;
  additions: number;
  openVerified: boolean;
  closeVerified: boolean;
  discrepancy?: number;
  comment?: string;
  /** @deprecated */
  menuItem?: string;
}

export interface IShiftInventoryItemWithCurrentCount extends IShiftInventoryItem {
  currentCount: number;
  parQuantity?: number;
}

export interface IShiftInventoryItemWithAdjustment extends IShiftInventoryItemWithCurrentCount {
  adjustment: number;
}

export interface IShift extends BaseModelData {
  shiftId?: number;
  name?: string;

  restaurant: string | LocationData;
  standSheetId?: string;
  status: ShiftStatus;
  inventoryTracked: boolean;
  items: IShiftInventoryItem[];

  openingCash?: number;
  closingCash?: number;

  openTime?: Date;
  openingBy?: string;

  closeTime?: Date;
  closeBy?: string;

  lastOrder?: Date;
  created?: Date;
  lastUpdated?: Date;
  heartbeat?: Date;
}

export class Shift extends AbstractModel<IShift> {
  public readonly dayPart: string;

  constructor(public data: IShift) {
    super(data);
    this.dayPart = moment(this.data.created).format('dddd').toLowerCase();
  }

  public setOpenVerified(): void {
    this.data.items.forEach(item => {
      item.openVerified = true;
    });
  }

  public setCloseVerified(): void {
    this.data.items.forEach(item => {
      item.closeVerified = true;
    });
  }
}
