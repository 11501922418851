import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiClient } from '../../../clients';
import { ApiDomain, ApiResources, ApiVersion } from '../../../enums';
import { ApiResponse, Printer, PrinterData } from '../../../models';
import { ModeService } from '../../../providers/config/mode.service';
import { errorHandler } from '../app.state';
import { PrinterActions, setPrinters } from './printer.actions';

@Injectable()
export class PrinterEffects {
  private apiClient: ApiClient;

  loadPrinters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrinterActions.GET_PRINTERS),
      mergeMap(() => {
        return this.apiClient
          .get<ApiResponse.SearchResult<PrinterData>>({
            limit: 100,
            expand:
              'locationIds,stationIds,locationStationIds.locationId,locationStationIds.stationId',
          })
          .pipe(
            map(printersResult => {
              return setPrinters({
                printers: printersResult.data.data.map((printerData: PrinterData) => {
                  return new Printer(printerData);
                }),
              });
            }),
            catchError(errorHandler)
          );
      })
    )
  );

  constructor(httpClient: HttpClient, modeService: ModeService, private actions$: Actions) {
    this.apiClient = new ApiClient(httpClient, modeService.getMode()).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.PRINTER
    );
  }
}
