import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colSize',
})
export class ColSizePipe implements PipeTransform {
  transform(numerator: number, denominator: number, minSize = 3): string {
    if (denominator === 0) {
      return `${numerator}`;
    }

    return `${Math.max(Math.floor(numerator / denominator), minSize)}`;
  }
}
