import { PromptForMember } from '../../enums';
import { AbstractModel } from '../abstract.model';

export interface VenueData {
  orgId: string;
  shareTabs: boolean;
  trackEmployees: boolean;
  authForRefunds: boolean;
  authForVoids: boolean;
  promptForMember: PromptForMember;
  promptForEmployee: boolean;
  usesFullScreenModsViews: boolean;
  guestReceiptConfig: {
    header: string;
    footer: string;
    showTipOptions: boolean;
    tipOptions: number[];
    useTransactionAmountForTip: boolean;
    useAdditionalTipText: boolean;
  };
}

export class Venue extends AbstractModel<VenueData> {
  constructor(public data: VenueData) {
    super(data);
  }
}
