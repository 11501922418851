import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { IntegrationSystems, PaymentTermimalModel, PaymentTermimalStatus } from '../../enums';
import { Order, PosCardReader } from '../../models';
import { PointOfSaleActions } from '../../state';
import { PayloadCardReaderService } from './payload-card-reader.service';
import { StripeCreditCardReaderService } from './stripe-card-reader.service';
import { StripeService } from './stripe.service';
import { CardPresentSetupBody } from './types';

export interface CardReaderData {
  name: string;
  serialNumber: string;
  model: PaymentTermimalModel;
  status: PaymentTermimalStatus;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private paymentI9n: any;

  private isNative: boolean;

  private connectedReader: PosCardReader;

  private posId: string;

  private readerInterval: any;

  constructor(
    private platform: Platform,
    // Payment
    private stripeService: StripeService,
    private stripeReaderService: StripeCreditCardReaderService,
    private payloadReaderService: PayloadCardReaderService,
    private store: Store
  ) {
    this.isNative = this.platform.platforms().includes('cordova');
  }

  public startCheckReaderStatus(posId: string, reader: PosCardReader) {
    if (this.readerInterval) {
      this.stopCheckReaderStatus();
    }
    this.connectedReader = reader;
    this.posId = posId;
    this.readerInterval = setInterval(async () => {
      console.log('Going to check reader status');
      const status = await this.getStatus(reader);
      if (status?.isConnected && !this.connectedReader.isConnected) {
        this.connectedReader.isConnected = true;
        this.store.dispatch({
          type: PointOfSaleActions.UPDATE_POINT_OF_SALE,
          posId: this.posId,
          skipToast: true,
          update: {
            reader: {
              ...this.connectedReader,
              status: PaymentTermimalStatus.ONLINE,
            },
          },
        });
        return;
      }
      if (!status?.isConnected && this.connectedReader.isConnected) {
        this.connectedReader.isConnected = false;
        this.store.dispatch({
          type: PointOfSaleActions.UPDATE_POINT_OF_SALE,
          posId: this.posId,
          skipToast: true,
          update: {
            reader: {
              ...this.connectedReader,
              status: PaymentTermimalStatus.OFFLINE,
            },
          },
        });
      }
    }, 15000);
  }

  public stopCheckReaderStatus() {
    if (this.readerInterval) {
      clearInterval(this.readerInterval);
      this.connectedReader = null;
      this.posId = null;
    }
  }

  public paymentI9nSupportsTipAdjustment(): boolean {
    return ['5812'].includes(this.paymentI9n?.mcc);
  }

  /**
   * @todo Type payment integration
   */
  async initPaymentSdks(
    paymentI9n: any,
    liveMode: 'true' | 'false',
    posId: string,
    shiftId: string
  ) {
    this.paymentI9n = paymentI9n;
    switch (paymentI9n?.system) {
      case IntegrationSystems.PAYLOAD: {
        await this.payloadReaderService.init({
          payloadClientKey: paymentI9n.payloadClientKey, // 'test_client_key_3cfY1XnzRTvrQWZ79h8M5',
          processingAccountId: paymentI9n.processingAccountId, // 'acct_3ciUpLP17m0DX6qLDbNrY',
        });
        break;
      }
      case IntegrationSystems.STRIPE: {
        await this.stripeService.init(paymentI9n);
        // We need to alias the native version of the SDK until the corcova plugin namespace is changed
        if (window.StripeTerminal?.init) {
          window.StripeTerminalNative = window.StripeTerminal;
        }
        if (this.isNative) {
          // Native SDK
          console.log('Init stripe native SDK');
          await this.stripeReaderService.initTerminal(paymentI9n, liveMode, posId, shiftId);
        }
        console.log('Init stripe web SDK');
        await this.stripeService.initTerminal(paymentI9n);
      }
    }
  }

  async discoverDevices(readerType: 'web' | 'bluetooth' = 'web'): Promise<CardReaderData[]> {
    switch (this.paymentI9n?.system) {
      case IntegrationSystems.PAYLOAD: {
        return this.payloadReaderService.connectToReader();
      }
      case IntegrationSystems.STRIPE: {
        const devices: CardReaderData[] = [];
        if (readerType === 'web') {
          const webDevices = await this.stripeService.discoverReaders();
          devices.push(...webDevices);
        }
        if (this.isNative && readerType === 'bluetooth') {
          this.stripeService.clearTerminal();
          const connectedBluetoothReader = await this.stripeReaderService.discoverReaders();
          if (connectedBluetoothReader) {
            devices.push(connectedBluetoothReader);
          }
        }
        return devices;
      }
    }
  }

  async getStatus(
    reader: PosCardReader
  ): Promise<{ isConnected: boolean; status?: string; message?: string }> {
    switch (this.paymentI9n?.system) {
      case IntegrationSystems.PAYLOAD: {
        return { isConnected: true, status: PaymentTermimalStatus.ONLINE, message: '' };
      }
      case IntegrationSystems.STRIPE: {
        switch (reader.model) {
          case PaymentTermimalModel.BBPOS_BLUETOOTH:
          case PaymentTermimalModel.STRIPE_M2:
            return this.stripeReaderService.getConnectionStatus(reader);
          default:
            return this.stripeService.getConnectionStatus();
        }
      }
    }
  }

  public static getReaderTypeFromModel(model: PaymentTermimalModel) {
    switch (model) {
      case PaymentTermimalModel.BBPOS_BLUETOOTH:
      case PaymentTermimalModel.STRIPE_M2:
        return 'bluetooth';
      default:
        return 'web';
    }
  }

  async connectReader(reader: PosCardReader): Promise<boolean> {
    switch (this.paymentI9n?.system) {
      case IntegrationSystems.PAYLOAD: {
        return this.payloadReaderService.connectToReader();
      }
      case IntegrationSystems.STRIPE: {
        switch (reader.model) {
          case PaymentTermimalModel.BBPOS_BLUETOOTH:
          case PaymentTermimalModel.STRIPE_M2: {
            const connectedReader = await this.stripeReaderService.discoverReaders();
            return !!connectedReader;
          }
          default:
            return this.stripeService.connectToReader(reader);
        }
      }
    }
  }

  async disconnectReader(reader: PosCardReader) {
    switch (this.paymentI9n?.system) {
      case IntegrationSystems.PAYLOAD: {
        return true;
      }
      case IntegrationSystems.STRIPE: {
        switch (reader.model) {
          case PaymentTermimalModel.BBPOS_BLUETOOTH:
          case PaymentTermimalModel.STRIPE_M2: {
            const connectedReader = await this.stripeReaderService.discoverReaders();
            return !connectedReader;
          }
          default:
            return this.stripeService.disconnectReader();
        }
      }
      default:
        throw new Error(`Unknown system: ${this.paymentI9n?.system}`);
    }
  }

  async setReaderDisplay(order: Order, reader: PosCardReader): Promise<boolean> {
    switch (this.paymentI9n?.system) {
      case IntegrationSystems.PAYLOAD: {
        return false;
      }
      case IntegrationSystems.STRIPE: {
        switch (reader.model) {
          case PaymentTermimalModel.BBPOS_WISEPOS_E:
            return this.stripeService.setReaderDisplay(order);
          default:
            return false;
        }
      }
    }
  }

  async processPayment(
    options: CardPresentSetupBody,
    reader: PosCardReader,
    collectTipsOnTerminal = false
  ) {
    switch (this.paymentI9n?.system) {
      case IntegrationSystems.PAYLOAD: {
        throw new Error('Not implemented');
      }
      case IntegrationSystems.STRIPE: {
        switch (reader.model) {
          case PaymentTermimalModel.BBPOS_WISEPOS_E:
            return this.stripeService.processPayment(options, collectTipsOnTerminal);
          default:
            throw new Error('Not implemented');
        }
      }
    }
  }
}
