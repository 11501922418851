import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { environment } from '@env/environment';
import { ModeService } from '../config/mode.service';
import { DeviceService } from '../point-of-sale';
import { LocalStorage } from '../../helpers';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private domain: string;
  private baseUrl: string;

  private authStatusListener = new Subject<boolean>();

  constructor(
    private _http: HttpClient,
    private modeService: ModeService,
    private deviceService: DeviceService
  ) {
    const mode = this.modeService.getMode();
    this.domain = environment[mode].url_v2;
    this.baseUrl = `${this.domain}/auth`;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  login(login: any) {
    return this._http.post<any>(`${this.baseUrl}/login`, login);
  }

  loginPos(deviceId: string) {
    return this._http.post<any>(`${this.baseUrl}/login-point-of-sale`, { deviceId});
  }

  // Save the auth token in local storage
  setToken(token?: string) {
    if (token) {
      localStorage.setItem('token', token);
      this.authStatusListener.next(true);
    } else {
      localStorage.removeItem('token');
      this.authStatusListener.next(false);
    }
  }

  // Get token from local storage
  // eslint-disable-next-line class-methods-use-this
  getToken() {
    const token = localStorage.getItem('token');
    return token;
  }

  /*
    Check if we are logged in
    - This is a cheap and dirty way of checking if we are logged in.
    - The backed needs to ensure that we are authorized access certain endpoints.
  */
  isAuthenticated() {
    const token = this.getToken();
    if (token) {
      return true;
    }
    return false;
  }

  async logout() {
    const deviceId = this.deviceService.getUUID();
    localStorage.clear();
    await LocalStorage.clear();
    localStorage.setItem('guid', deviceId);
    await LocalStorage.addObject('autoLogin', { loggedOut: Date.now() });
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }
}
