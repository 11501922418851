import { createReducer, on } from '@ngrx/store';
import { Venue } from '../../../models';
import { retrievedVenue } from './venue.actions';

export const venueInitialState: Readonly<Venue> = null;

export const venueReducer = createReducer(
  venueInitialState,
  on(retrievedVenue, (state, { venue }) => venue)
);
