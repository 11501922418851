/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PayloadCardReaderService {
  private _readerEventListener = new Subject<any>();

  constructor() {}

  public init(i9n: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (window.PartakePayload) {
        window.PartakePayload.init(
          i9n.payloadClientKey,
          i9n.processingAccountId,
          success => {
            resolve(success);
          },
          error => {
            reject(error);
          }
        );
      } else {
        resolve({ success: false, message: 'Payload Plugin not inialized' });
      }
    });
  }

  public connectToReader(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (window.PartakePayload) {
        window.PartakePayload.connectToReader(
          (success: any) => {
            resolve(success);
          },
          (error: any) => {
            reject(error);
          }
        );
      } else {
        resolve({ success: false, message: 'Payload Reader not inialized' });
      }
    });
  }

  /**
   *
   * @param paymentInfo
   * - amount: 1.00 for $1.00
   * @returns
   */
  public makePayment(paymentInfo: { amount: number }): Observable<any> {
    if (window.PartakePayload) {
      window.PartakePayload.makePayment(
        paymentInfo,
        (data: any) => {
          this._readerEventListener.next({ event: data });
        },
        (error: any) => {
          this._readerEventListener.next({ error });
        }
      );
      return this._readerEventListener;
    }
    return null;
  }

  public isConnected(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (window.PartakePayload) {
        window.PartakePayload.isConnected(
          (success: any) => {
            resolve(success);
          },
          (error: any) => {
            reject(error);
          }
        );
      } else {
        resolve({ success: false, message: 'Payload Reader not inialized' });
      }
    });
  }

  public collectPaymentSource(): Observable<any> {
    if (window.PartakePayload) {
      window.PartakePayload.collectPaymentSource(
        (data: any) => {
          this._readerEventListener.next({ event: data });
        },
        (error: any) => {
          this._readerEventListener.next({ event: error });
        }
      );
      return this._readerEventListener.asObservable();
    }
    return null;
  }
}
