import { Pipe, PipeTransform } from '@angular/core';
import { DiningOptionsBehavior } from '../enums';
import { Order } from '../models';

@Pipe({
  name: 'cardOnFile',
})
export class CardOnFilePipe implements PipeTransform {
  private defaultText = 'No Card on File';

  transform(order: Order): string {
    if (!order) {
      return this.defaultText;
    }
    if (order.data.orderDestination.behaviour === DiningOptionsBehavior.TABLE) {
      return '';
    }
    if (order.data.paid) {
      return 'PAID';
    }
    if (order.data.cardOnFile) {
      const cardOnFile = order.data.cardOnFile;
      return `${cardOnFile.cardScheme} ${cardOnFile.last4}`;
    }
    if (order.data.guestCardsOnFile?.length) {
      const cardOnFile = order.data.guestCardsOnFile[0];
      return `${cardOnFile.cardScheme} ${cardOnFile.last4}`;
    }
    if (order.data.member) {
      return 'MEMBER';
    }
    return this.defaultText;
  }
}
