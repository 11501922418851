import { OrgFeature, TransactionCardScheme } from '../enums';
import { Order, Org, TenderOption } from '../models';

export default class PaymentHelper {
  static getStripeCardSchemeFromStripeCard(stripeCard: any): TransactionCardScheme {
    const brand = stripeCard?.brand?.toLowerCase();
    switch (brand) {
      case 'amex':
      case 'american express':
        return TransactionCardScheme.AMEX;
      case 'visa':
        return TransactionCardScheme.VISA;
      case 'mastercard':
        return TransactionCardScheme.MASTERCARD;
      case 'discover':
        return TransactionCardScheme.DISCOVER;
      case 'diners':
        return TransactionCardScheme.DINERS;
      case 'maestro':
        return TransactionCardScheme.MAESTRO;
      case 'jcb':
        return TransactionCardScheme.JCB;
    }
    return TransactionCardScheme.OTHER;
  }

  static getStripeCardSchemeFromStripePaymentIntent(paymentIntent: any): TransactionCardScheme {
    const charge = paymentIntent?.charges?.data[0];
    const card = charge?.payment_method_details?.card_present;
    return PaymentHelper.getStripeCardSchemeFromStripeCard(card);
  }

  static getNameOnCardFromStripePaymentMethod(paymentMethod: any): string {
    const card =
      paymentMethod?.card || paymentMethod?.charges?.data[0]?.payment_method_details?.card_present;
    return (
      card?.generated_from?.payment_method_details?.card_present?.cardholder_name ||
      card?.payment_method_details?.card_present?.cardholder_name ||
      card?.cardholder_name
    );
  }

  static getTenderOptionsForOrder(order: Order, org: Org): TenderOption[] {
    if (order.isTab && !order.id) {
      return [];
    }
    const tenderOptions: TenderOption[] = [
      { key: 'cash', name: 'Cash', icon: 'cash' },
      { key: 'card', name: 'Credit / Debit', icon: 'card' },
    ];
    if (!order.data.member) {
      tenderOptions.push({ key: 'split', name: 'Split Payment', icon: 'albums' });
    }
    if (order.isEvent) {
      tenderOptions.push({ key: 'event', name: 'Event', icon: 'receipt-outline' });
    }
    if (org?.hasFeature(OrgFeature.MEMBER_CHARGES) && !order.data.transactions?.length) {
      tenderOptions.push({ key: 'member', name: 'Member Charge', icon: 'person-add-outline' });
    }
    if (order.data.cardOnFile) {
      tenderOptions.unshift({
        key: 'card-on-file',
        name: `Card On File ${order.data.cardOnFile.cardScheme} ${order.data.cardOnFile.last4}`,
        icon: 'wallet',
        color: 'secondary',
      });
    }
    if (!order.data.cardOnFile && order.data.guestCardsOnFile?.length) {
      const cardOnFile = order.data.guestCardsOnFile[0];
      tenderOptions.unshift({
        key: 'card-on-file',
        name: `Card On File ${cardOnFile.cardScheme} ${cardOnFile.last4}`,
        icon: 'wallet',
      });
    }
    return tenderOptions;
  }
}
