import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  public title: string;
  public message: string;

  constructor(private _modalCtrl: ModalController) {}

  public confirm() {
    this._modalCtrl.dismiss({ result: true });
  }

  public cancel() {
    this._modalCtrl.dismiss();
  }
}
