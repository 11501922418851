import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';

export const selectShift = createSelector(
  (state: AppState) => state.shift,
  (shift: any) => shift
);

export const selectAllShifts = createSelector(
  (state: AppState) => state.shifts,
  (shifts: any) => shifts
);
