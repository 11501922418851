/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as Realm from 'realm-web';
import { ModeService } from '../config/mode.service';
import { environment } from '../../../environments/environment';
import { OrderData, RealmEvent } from '../../interfaces';

@Injectable({ providedIn: 'root' })
export class RealmService {
  private realmListener = new Subject<RealmEvent>();

  private app: Realm.App;

  private realmUser: Realm.User;

  private ordersChangeStream: AsyncGenerator<any>;

  constructor(modeService: ModeService) {
    const mode = modeService.getMode();
    this.app = new Realm.App({ id: environment[mode].realmAppId });
  }

  public async init(): Promise<Observable<any>> {
    this.realmUser = this.app.currentUser;
    if (!this.realmUser) {
      const credentials = Realm.Credentials.anonymous();
      this.realmUser = await this.app.logIn(credentials);
    }
    if (!this.realmUser.isLoggedIn) {
      this.realmUser.logOut();
      this.init();
      return;
    }
    this.realmUser.refreshAccessToken();
    return this.realmListener.asObservable();
  }

  public async listenToOrders(query: {
    orgId: string;
    venueId: string;
    locationId?: string;
    stationId?: string;
    dateString?: string;
  }) {
    try {
      const mongodb = this.app.currentUser.mongoClient('mongodb-atlas');
      const ordersCollection = mongodb
        .db(environment.realmDbName)
        .collection<OrderData & { _id: string }>('orders');
      const filter = {
        'fullDocument.orgId': new Realm.BSON.ObjectId(query.orgId),
        'fullDocument.venue': new Realm.BSON.ObjectId(query.venueId),
        ...(query.dateString && { dateString: query.dateString }),
        ...(query.locationId &&
          !query.stationId && {
            'fullDocument.items.restaurant': new Realm.BSON.ObjectId(query.locationId),
          }),
        ...(query.stationId &&
          !query.locationId && {
            $or: [
              { 'fullDocument.items.stations': new Realm.BSON.ObjectId(query.stationId) },
              { 'fullDocument.items.modifiers.stations': new Realm.BSON.ObjectId(query.stationId) },
            ],
          }),
        ...(query.stationId &&
          query.locationId && {
            $or: [
              {
                'fullDocument.items.restaurant': new Realm.BSON.ObjectId(query.locationId),
                'fullDocument.items.stations': new Realm.BSON.ObjectId(query.stationId),
              },
              {
                'fullDocument.items.restaurant': new Realm.BSON.ObjectId(query.locationId),
                'fullDocument.items.modifiers.stations': new Realm.BSON.ObjectId(query.stationId),
              },
            ],
          }),
      };
      this.ordersChangeStream = ordersCollection.watch({ filter });
      for await (const change of this.ordersChangeStream) {
        const { operationType, fullDocument: order } = change as any;
        const orderJsonString = JSON.stringify(order);
        const orderJson = JSON.parse(orderJsonString);
        this.realmListener.next({
          collection: 'orders',
          type: operationType,
          data: orderJson,
        });
      }
    } catch (err) {
      console.log('RealmError', err);
    }
  }

  public async stopListeningToOrders() {
    if (this.ordersChangeStream) {
      await this.ordersChangeStream.return(null);
    }
  }

  public async listenToCollection(
    collection: string,
    query: { orgId: string; venueId: string; locationId?: string }
  ) {
    try {
      const mongodb = this.app.currentUser.mongoClient('mongodb-atlas');
      const ordersCollection = mongodb.db(environment.realmDbName).collection(collection);
      for await (const change of ordersCollection.watch({
        filter: {
          'fullDocument.orgId': new Realm.BSON.ObjectId(query.orgId),
          'fullDocument.venueId': new Realm.BSON.ObjectId(query.venueId),
          ...(query.locationId && {
            'fullDocument.locationId': new Realm.BSON.ObjectId(query.locationId),
          }),
        },
      })) {
        const { operationType, fullDocument } = change as any;
        const documentJsonString = JSON.stringify(fullDocument);
        const documentJson = JSON.parse(documentJsonString);
        this.realmListener.next({
          collection,
          type: operationType,
          data: documentJson,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
}
