import { HttpErrorResponse } from '@angular/common/http';
import { createAction, createReducer, createSelector, on, props } from '@ngrx/store';
import { of } from 'rxjs';
import { OrderSortKey } from '../../enums';
import {
  DiningOption,
  IShift,
  LocationData,
  MenuItemStation,
  Order,
  Org,
  PointOfSale,
  Printer,
  Venue,
} from '../../models';
import { MenuData } from './menu';
import { PaymentState } from './payment';

export enum AppActions {
  SHOW_TOAST = 'show-toast',
}

export interface ToastOpts {
  message: string;
  color: string;
}

export const selectToast = createSelector(
  (state: AppState) => state.toast,
  (toast: ToastOpts) => toast
);

export const showToast = createAction(AppActions.SHOW_TOAST, props<{ toast: ToastOpts }>());

export const toastState: any = null;

export const toastReducer = createReducer(
  toastState,
  on(showToast, (state, { toast }) => toast)
);

export const errorHandler = (error: HttpErrorResponse) => {
  console.log(error);
  return of(
    showToast({
      toast: {
        message: error?.error?.message || 'Oops, something unexpected happened. Please try again.',
        color: 'warning ',
      },
    })
  );
};

export interface AppState {
  integrations: ReadonlyArray<any>;
  pointOfSale: Readonly<PointOfSale>;
  shift: Readonly<IShift>;
  shifts: Readonly<any[]>;
  stations: ReadonlyArray<MenuItemStation>;
  station: Readonly<MenuItemStation>;
  diningOptions: ReadonlyArray<DiningOption>;
  location: Readonly<LocationData>;
  locations: ReadonlyArray<LocationData>;
  order: Order;
  orders: {
    sort: OrderSortKey;
    data: Order[];
    filteredData: Order[];
    lastUpdated: Date;
  };
  org: Org;
  orgs: Readonly<Org[]>;
  payment: Readonly<PaymentState>;
  toast: ToastOpts;
  menu: Readonly<MenuData>;
  printers: ReadonlyArray<Printer>;
  liveMode: 'true' | 'false';
  venue: Readonly<Venue>;
}
