import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StripeReaderConnectionModalComponent } from './stripe-reader-connection-modal.component';

@Injectable()
export class StripeReaderModalController {
  constructor(private _modalCtrl: ModalController) {}

  public async show(): Promise<any> {
    const modal = await this._modalCtrl.create({
      component: StripeReaderConnectionModalComponent,
      cssClass: 'stripe-reader-connection-modal',
      backdropDismiss: false,
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    return data;
  }
}
