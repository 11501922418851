<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon
          slot="icon-only"
          name="close"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Member Lookup</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content scrollY="false">
  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center">
        <h1 class="title">Search for Member</h1>
        <p>Search by Member ID or Number</p>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-text-center">
        <ion-segment
          [(ngModel)]="searchOption"
          [value]="searchOption"
        >
          <ion-segment-button value="fullName">
            <ion-label>Name</ion-label>
          </ion-segment-button>
          <ion-segment-button value="memberId">
            <ion-label>Member ID</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-list lines="none">
          <ion-item>
            <ion-input
              type="text"
              [placeholder]="'Search by ' + (searchOption === 'memberId' ? 'Member ID' : 'Name')"
              [(ngModel)]="searchValue"
            ></ion-input>
          </ion-item>
        </ion-list>
        <ion-button
          expand="block"
          color="secondary"
          (click)="search()"
        >
          Search
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="hasSearched">
      <ion-list class="member-list">
        <ion-list-header>Select Member</ion-list-header>
        <ion-item
          *ngFor="let member of members"
          detail
          (click)="selectMember(member)"
        >
          <ion-label>
            {{ member.data.fullName }} ({{ member.data.memberId }})
            <ng-container *ngIf="member.data.status === 'flagged'">
              <p>{{ member.data.comment }}</p>
            </ng-container>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="!members.length">
          <ion-label>No members found</ion-label>
        </ion-item>
      </ion-list>
    </ion-row>
  </ion-grid>
</ion-content>
