import { BaseModelData } from '../../interfaces';
import { AbstractModel } from '../abstract.model';

export interface MenuItemStationData extends BaseModelData {
  name: string;
  isActive: boolean;
}

export class MenuItemStation extends AbstractModel<MenuItemStationData> {
  constructor(public data: MenuItemStationData) {
    super(data);
  }
}
