import { ApiHeader } from '../enums';
import { Org, Venue } from '../models';
import * as LocalStorage from './local-storage.helper';

export default class StateHelper {
  static async setOrg(org: Org) {
    await LocalStorage.addItem(ApiHeader.ORG_ID, org.id);
    const venueId = (org.data.venueIds[0] as any)._id || (org.data.venueIds[0] as string);
    await LocalStorage.addItem(ApiHeader.VENUE_ID, venueId);
  }

  static async setVenue(venue: Venue) {
    await LocalStorage.addItem(ApiHeader.VENUE_ID, venue.id);
  }
}
