import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiClient } from '../../../clients';
import { ApiDomain, ApiResources, ApiVersion } from '../../../enums';
import { StateHelper } from '../../../helpers';
import { Org } from '../../../models';
import { ModeService } from '../../../providers/config/mode.service';
import { errorHandler } from '../app.state';
import { OrgActions, retrevedAllOrgs, retrievedOrg } from './org.actions';

@Injectable()
export class OrgEffects {
  private apiClient: ApiClient;

  loadOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.LOAD_ORG),
      mergeMap(({ orgId }) => {
        return this.apiClient.findById(orgId).pipe(
          map((orgResult: any) => {
            if (orgResult.data) {
              const org = new Org(orgResult.data);
              StateHelper.setOrg(org);
              return retrievedOrg({ org });
            }
            return retrievedOrg({ org: null });
          }),
          catchError(errorHandler)
        );
      })
    )
  );

  loadAllOrgs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgActions.LOAD_ALL_ORGS),
      mergeMap(() => {
        return this.apiClient.get({ limit: 1000, sort: 'name' }).pipe(
          map((orgResult: any) => {
            if (orgResult.data?.total) {
              const orgs = orgResult.data.data.map((orgData: any) => {
                return new Org(orgData);
              });
              return retrevedAllOrgs({ orgs });
            }
            return retrevedAllOrgs({ orgs: [] });
          }),
          catchError(errorHandler)
        );
      })
    )
  );

  constructor(httpClient: HttpClient, modeService: ModeService, private actions$: Actions) {
    this.apiClient = new ApiClient(httpClient, modeService.getMode()).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.ORGS
    );
  }
}
