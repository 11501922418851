import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, UpperCasePipe } from '@angular/common';

import { ConcessionPipe } from './concession.pipe';
import { MenuItemPipe } from './menu-item.pipe';
import { PhonePipe } from './phone.pipe';
import { StatusPipe } from './status.pipe';
import { OrderTimePipe } from './order-time.pipe';
import { OrderInstructionsPipe } from './order-instructions.pipe';
import { CCBrandPipe } from './cc-brand.pipe';
import { ShiftAcceptingPipe } from './shift-accepting.pipe';
import { NameAbbreviationPipe } from './name-abbreviation.pipe';
import { ItemCountPipe } from './item-count.pipe';
import { ItemDeliveredCountPipe } from './item-delivered-count.pipe';
import { OrderTypePipe } from './order-type.pipe';
import { GratuityPipe } from './gratuity.pipe';
import { PaidStatusPipe } from './paid-status.pipe';
import { OrderSortProperyPipe } from './order-sort-property.pipe';
import { OrderStatusPipe } from './order-status.pipe';
import { OrderDestinationPipe } from './order-destination.pipe';
import { TitleCasePipe } from './title-case.pipe';
import { ColSizePipe } from './col-size.pipe';
import { CardOnFilePipe } from './card-on-file.pipe';

@NgModule({
  declarations: [
    CardOnFilePipe,
    ConcessionPipe,
    MenuItemPipe,
    PhonePipe,
    StatusPipe,
    OrderTimePipe,
    OrderInstructionsPipe,
    CCBrandPipe,
    ShiftAcceptingPipe,
    NameAbbreviationPipe,
    OrderDestinationPipe,
    ItemCountPipe,
    ItemDeliveredCountPipe,
    OrderTypePipe,
    GratuityPipe,
    PaidStatusPipe,
    OrderSortProperyPipe,
    OrderStatusPipe,
    TitleCasePipe,
    ColSizePipe,
  ],
  exports: [
    CardOnFilePipe,
    ConcessionPipe,
    MenuItemPipe,
    PhonePipe,
    StatusPipe,
    OrderTimePipe,
    OrderInstructionsPipe,
    CCBrandPipe,
    ShiftAcceptingPipe,
    NameAbbreviationPipe,
    OrderDestinationPipe,
    ItemCountPipe,
    ItemDeliveredCountPipe,
    OrderTypePipe,
    GratuityPipe,
    PaidStatusPipe,
    OrderSortProperyPipe,
    OrderStatusPipe,
    TitleCasePipe,
    ColSizePipe,
  ],
  imports: [CommonModule],
  providers: [
    CardOnFilePipe,
    DatePipe,
    UpperCasePipe,
    ConcessionPipe,
    MenuItemPipe,
    PhonePipe,
    StatusPipe,
    OrderTimePipe,
    OrderInstructionsPipe,
    CCBrandPipe,
    ShiftAcceptingPipe,
    NameAbbreviationPipe,
    ItemCountPipe,
    ItemDeliveredCountPipe,
    OrderTypePipe,
    GratuityPipe,
    CurrencyPipe,
    PaidStatusPipe,
    OrderSortProperyPipe,
    OrderStatusPipe,
    ColSizePipe,
  ],
})
export class PipesModule {}
