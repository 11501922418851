import {
  PointOfSaleType,
  PointOfSaleStatus,
  PaymentTermimalModel,
  PrinterRole,
  PrinterPaperWidth,
} from '../../enums';
import { AbstractModel } from '../abstract.model';

export interface PosCardReader {
  serialNumber: string;
  model: PaymentTermimalModel;
  isConnected: boolean;
}
export interface PosCashDrawer {
  port: string;
}
export interface PosPrinter {
  model: string;
  port: string;
  cashdrawerAttached: boolean;
  paperWidth: PrinterPaperWidth;
  roles: PrinterRole[];
  stations: string[];
}

export interface PointOfSaleData {
  name: string;
  deviceId: string;
  type: PointOfSaleType;
  status: PointOfSaleStatus;
  orgId: string | Record<string, any>;
  venueId: string | Record<string, any>;
  shiftId: string | Record<string, any>;
  reader: PosCardReader;
  cashDrawer: PosCashDrawer;
  printers: PosPrinter[];
  lastActive: Date;
  appVersion: string;
  nativeAppVersion: string;
}

export type PointOfSaleCreateParams = Pick<
  PointOfSaleData,
  'name' | 'deviceId' | 'type' | 'appVersion' | 'nativeAppVersion' | 'venueId'
>;
export type PointOfSaleUpdateParams = Partial<
  Pick<PointOfSaleData, 'reader' | 'cashDrawer' | 'printers'>
>;

export class PointOfSale extends AbstractModel<PointOfSaleData> {
  constructor(public data: PointOfSaleData) {
    super(data);
  }
}
