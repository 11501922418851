import { ApiClient } from '@app/clients';
import { ApiDomain, ApiVersion } from '@app/enums';
import { AppInjector } from '@app/app-injector.service';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Directive, Injector, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UserService } from './providers/user/user.service';
import { LoadingService } from './providers/general/loading.service';
import { DeviceService } from './providers/point-of-sale/device.service';
import { Org, PointOfSale } from './models';
import { selectOrg, selectPointOfSale } from './state';
import { ConfirmModalComponent } from './shared/confirm-modal/confirm-modal.component';

@Directive()
export class BaseComponent implements OnDestroy {
  protected injector: Injector;
  protected router: Router;
  protected apiClient: ApiClient;
  protected deviceService: DeviceService;
  protected userService: UserService;
  protected modalCtrl: ModalController;
  protected toastCtrl: ToastController;
  protected alertCtrl: AlertController;
  protected loader: LoadingService;
  protected store: Store;

  protected pointOfSale$: Observable<PointOfSale>;

  protected org$: Observable<Org>;

  protected subscriptions: Subscription[] = [];

  public pointOfSale: PointOfSale;

  public org: Org;

  constructor() {
    this.injector = AppInjector.getInjector();
    this.router = this.injector.get(Router);
    this.apiClient = new ApiClient().setDomain(ApiDomain.API, ApiVersion.V2);
    this.userService = this.injector.get(UserService);
    this.deviceService = this.injector.get(DeviceService);
    this.modalCtrl = this.injector.get(ModalController);
    this.toastCtrl = this.injector.get(ToastController);
    this.alertCtrl = this.injector.get(AlertController);
    this.loader = this.injector.get(LoadingService);
    this.store = this.injector.get(Store);
    this.pointOfSale$ = this.store.pipe(select(selectPointOfSale));
    this.org$ = this.store.pipe(select(selectOrg));
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  protected init() {
    const posSub = this.pointOfSale$.subscribe(pointOfSale => {
      this.pointOfSale = pointOfSale;
    });
    this.subscriptions.push(posSub);
    const orgSub = this.org$.subscribe(org => {
      this.org = org;
    });
    this.subscriptions.push(orgSub);
  }

  protected clearSubscriptions(): void {
    while (this.subscriptions.length) {
      const sub = this.subscriptions.pop();
      sub.unsubscribe();
    }
  }

  async displayError(error: any) {
    const message = error.error?.message || error.message || 'An unexpected error occured.';
    this.showToast({ message, color: 'danger' });
  }

  async makeSafeRequest<Response = any>(observable: Observable<Response>) {
    try {
      const result = await observable.toPromise();
      return result;
    } catch (err) {
      console.log(err);
      this.displayError(err);
      return null;
    }
  }

  async makeSafeRequestWithLoading<Response = any>(
    observable: Observable<Response>,
    message?: string
  ) {
    await this.loader.show(message);
    const response = await this.makeSafeRequest<Response>(observable);
    await this.loader.hide();
    return response;
  }

  async showToast(opts?: { message: string; color: string }) {
    const toast = await this.toastCtrl.create({
      ...opts,
      position: 'top',
      duration: 6000,
    });
    await toast.present();
  }

  async confirm(title: string, message: string): Promise<boolean> {
    const modal = await this.modalCtrl.create({
      component: ConfirmModalComponent,
      cssClass: 'confirm-modal',
      componentProps: {
        title,
        message,
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data?.result) {
      return true;
    }
    return false;
  }
}
