import localforage from 'localforage';

export async function addItem(key: string, value: string): Promise<void> {
  try {
    await localforage.setItem(key, value);
  } catch (err) {
    console.log('add item error', err);
  }
}

export async function getItem(key: string): Promise<string> {
  try {
    return localforage.getItem(key);
  } catch (err) {
    console.log('get item error', err);
  }
}

export async function addObject(key: string, value: any): Promise<void> {
  try {
    await localforage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.log('add object error', err);
  }
}

export async function getObject(key: string): Promise<any> {
  try {
    const item = (await localforage.getItem(key)) as string;
    const obj = JSON.parse(item);
    return obj;
  } catch (err) {
    console.log('get object error', err);
    return null;
  }
}

export async function clear(): Promise<void> {
  try {
    await localforage.clear();
  } catch (err) {
    console.log('add object error', err);
  }
}
