import { createAction, props } from '@ngrx/store';
import { Venue } from '../../../models';

export enum VenueActions {
  LOAD_VENUE = 'load-venue',
  RETRIEVED_VENUE = 'retrieved-venue',
}

export const retrievedVenue = createAction(VenueActions.RETRIEVED_VENUE, props<{ venue: Venue }>());

export const loadVenue = createAction(VenueActions.LOAD_VENUE);
