import { createAction, props } from '@ngrx/store';
import { OrderChannel } from '../../../enums';
import {
  DiningOption,
  DiscountData,
  FloorPlan,
  GratuityData,
  GratuityMapData,
  Location,
  Menu,
  MenuItem,
  MenuSection,
  ModifierGroup,
  ServiceChargeData,
  TaxCodeData,
  TenderMapData,
  Venue,
} from '../../../models';

export enum MenuActions {
  LOAD_MENU = 'Load Menu',
  LOADED_MENU = 'Loaded Menu',
  CLEAR_MENU = 'Cleared Menu',
}

export interface MenuData {
  updatedAt: Date;
  location: Location;
  menu: null | Menu;
  menuSections: null | MenuSection[];
  menuItems: MenuItem[];
  modifierGroups: ModifierGroup[];
  serviceCharges: ServiceChargeData[];
  diningOptions: DiningOption[];
  taxCodes: TaxCodeData[];
  discounts: DiscountData[];
  tenderMap: TenderMapData;
  gratuities: GratuityData[];
  gratuityMap: GratuityMapData;
  floorPlans: FloorPlan[];
  venue: Venue;
}

export interface MenuInfo {
  updatedAt: Date;
  name: string;
  channel: OrderChannel;
}

export const loadMenu = createAction(MenuActions.LOAD_MENU);
export const clearMenu = createAction(MenuActions.CLEAR_MENU);

export const loadedMenu = createAction(
  MenuActions.LOADED_MENU,
  props<{
    menu: MenuData;
  }>()
);
