/* eslint-disable class-methods-use-this */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Subject, Observable } from 'rxjs';
import { ApiClient } from '../../clients';
import {
  ApiDomain,
  ApiHeader,
  ApiResources,
  ApiVersion,
  PaymentTermimalModel,
  PaymentTermimalStatus,
} from '../../enums';
import { LocalStorage } from '../../helpers';
import { ApiResponse } from '../../models';
import { AuthService } from '../auth/auth.service';
import { ModeService } from '../config/mode.service';
import { PointOfSaleService } from '../point-of-sale';
import { CardPresentSetupBody } from './types';

@Injectable({
  providedIn: 'root',
})
export class StripeCreditCardReaderService {
  private domain: string;

  private _readerEventListener = new Subject<any>();

  private loadingPromise: Promise<any>;

  private stripeSdkLoaded = false;

  private apiClient: ApiClient;

  private paymentI9n: any;

  constructor(
    private authService: AuthService,
    modeService: ModeService,
    httpClient: HttpClient,
    private pointOfSaleService: PointOfSaleService
  ) {
    const mode = modeService.getMode();
    this.domain = environment[mode].url_v2;
    this.apiClient = new ApiClient(httpClient, mode).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.PAYMENTS
    );
  }

  public async initTerminal(
    paymentI9n: any,
    liveMode: 'true' | 'false',
    posId: string,
    shiftId: string
  ): Promise<any> {
    this.paymentI9n = paymentI9n;
    if (this.stripeSdkLoaded) {
      return { success: true };
    }
    if (!this.loadingPromise) {
      const orgId = await LocalStorage.getItem(ApiHeader.ORG_ID);
      const locationId = (await LocalStorage.getItem(ApiHeader.LOCATION_ID)) || '';
      const appVersion = await this.pointOfSaleService.nativeAppVersion();
      this.loadingPromise = new Promise((resolve, reject) => {
        const { stripeLocationId, instanceType = '' } = paymentI9n;
        const token = this.authService.getToken();
        if (window.StripeTerminalNative && token) {
          window.StripeTerminalNative.init(
            {
              apiUrl: this.domain,
              token,
              stripeLocationId,
              instanceType,
              liveMode,
              headers: {
                appVersion,
                orgId,
                locationId,
                posId,
                shiftId,
              },
            },
            success => {
              this.stripeSdkLoaded = true;
              resolve(success);
            },
            error => {
              reject(error);
            }
          );
        } else {
          resolve({ success: false, message: 'Stripe Terminal not inialized' });
        }
      });
    }
    return this.loadingPromise.then(success => {
      this.loadingPromise = null;
      return success;
    });
  }

  private static getTerminalModelFromStripeModel(stripeModel: string): PaymentTermimalModel {
    switch (stripeModel) {
      case 'Stripe M2 - ':
        return PaymentTermimalModel.STRIPE_M2;
      case 'Chipper 2X - ':
        return PaymentTermimalModel.BBPOS_BLUETOOTH;
      case 'WisePOS E - ':
        return PaymentTermimalModel.BBPOS_WISEPOS_E;
      default:
        return PaymentTermimalModel.UNKNOWN;
    }
  }

  public async discoverReaders(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (window.StripeTerminalNative) {
        window.StripeTerminalNative.discoverReaders(
          success => {
            if (success.serialNumber) {
              const reader = {
                name: success.label,
                serialNumber: success.serialNumber,
                model: StripeCreditCardReaderService.getTerminalModelFromStripeModel(
                  success.deviceType
                ),
                status: PaymentTermimalStatus.ONLINE,
              };
              resolve(reader);
            } else {
              resolve(null);
            }
          },
          error => {
            reject(error);
          }
        );
      } else {
        resolve([]);
      }
    });
  }

  public async connectToReader(device: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (window.StripeTerminalNative) {
        window.StripeTerminalNative.connectToReader(
          device,
          success => {
            resolve(success);
          },
          error => {
            reject(error);
          }
        );
      } else {
        resolve({ message: 'Stripe Terminal not inialized' });
      }
    });
  }

  public async getConnectionStatus(
    device: any
  ): Promise<{ isConnected: boolean; message: string; status: string }> {
    return new Promise((resolve, reject) => {
      if (window.StripeTerminalNative) {
        window.StripeTerminalNative.getConnectionStatus(
          device,
          success => {
            resolve(success);
          },
          error => {
            console.log('error');
            console.log(error);
            // The device we have is not connected so we need to reconnect
            if (error.message && 'isConnected' in error) {
              resolve(error);
            } else {
              reject(error);
            }
          }
        );
      } else {
        resolve({
          isConnected: false,
          status: 'not-initialized',
          message: 'Stripe Terminal not inialized',
        });
      }
    });
  }

  public collectPaymentCard(intent: any): Observable<any> {
    if (window.StripeTerminalNative) {
      window.StripeTerminalNative.collectPaymentCard(
        { clientSecret: intent.client_secret },
        (data: any) => {
          this._readerEventListener.next({ event: data });
        },
        (error: any) => {
          this._readerEventListener.next({ event: error });
        }
      );
      return this._readerEventListener.asObservable();
    }
    return null;
  }

  public collectPaymentSource(): Observable<any> {
    if (window.StripeTerminalNative) {
      window.StripeTerminalNative.collectPaymentSource(
        (data: any) => {
          this._readerEventListener.next({ event: data });
        },
        (error: any) => {
          this._readerEventListener.next({ event: error });
        }
      );
      return this._readerEventListener.asObservable();
    }
    return null;
  }

  public async cancelCollectPaymentSource(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (window.StripeTerminalNative) {
        window.StripeTerminalNative.cancelCollectPaymentSource(
          success => {
            resolve(success);
          },
          (error: any) => {
            reject(error);
          }
        );
      } else {
        resolve({ message: 'Stripe Terminal not inialized' });
      }
    });
  }

  public async setupPayment(options: CardPresentSetupBody): Promise<Observable<any>> {
    const paymentIntentResult = await this.apiClient
      .post<ApiResponse.RequestResult<any>, CardPresentSetupBody>(options, 'card-present/setup')
      .toPromise();
    // Timeout the payment after 2 minutes
    return this.collectPaymentCard(paymentIntentResult.data);
  }
}
