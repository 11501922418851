import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiClient } from '../../../clients';
import { ApiDomain, ApiResources, ApiVersion } from '../../../enums';
import { DiningOption, DiningOptionData } from '../../../models';
import { ModeService } from '../../../providers/config/mode.service';
import { errorHandler } from '../app.state';
import { DiningOptionAction, retrievedDiningOptions } from './dining-options.actions';

@Injectable()
export class DiningOptionEffects {
  private apiClient: ApiClient;

  loadDiningOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiningOptionAction.LOAD_DINING_OPTIONS),
      mergeMap((payload: any) => {
        return this.apiClient
          .get({
            restaurant: payload.locationId,
            isActive: true,
          })
          .pipe(
            map((diningOptionsResult: any) => {
              return retrievedDiningOptions({
                diningOptions: diningOptionsResult.data.map(
                  (diningOptionData: DiningOptionData) => {
                    return new DiningOption(diningOptionData);
                  }
                ),
              });
            }),
            catchError(errorHandler)
          );
      })
    )
  );

  constructor(httpClient: HttpClient, modeService: ModeService, private actions$: Actions) {
    this.apiClient = new ApiClient(httpClient, modeService.getMode()).setDomain(
      ApiDomain.API,
      ApiVersion.V2,
      ApiResources.DINING_OPTIONS
    );
  }
}
