import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { environment } from '@env/environment';
import { ModeService } from '../config/mode.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _HASH_KEY = 'device-hash';
  private _USER_KEY = 'user';

  private _userListener = new Subject<any>();

  private domain: string;
  private path = 'users';
  private baseUrl: string;

  constructor(private _http: HttpClient, private _modeService: ModeService) {
    const mode = this._modeService.getMode();
    this.domain = environment[mode].url;
    this.baseUrl = `${this.domain}/${this.path}`;
  }

  public getUserListener(): Observable<any> {
    return this._userListener.asObservable();
  }

  public getMe(): any {
    try {
      return JSON.parse(localStorage.getItem(this._USER_KEY));
    } catch (e) {
      return null;
    }
  }

  public setMe(user?: any) {
    if (user) {
      localStorage.setItem(this._USER_KEY, JSON.stringify(user));
    } else {
      localStorage.removeItem(this._USER_KEY);
    }
    this._userListener.next(user);
  }

  /**
   * @todo - save to database on signup/login
   * @param hash the device hash
   */
  public setDeviceHash(hash?: string) {
    if (hash) {
      localStorage.setItem(this._HASH_KEY, hash);
    } else {
      localStorage.removeItem(this._HASH_KEY);
    }
  }

  public getDeviceHash(): string {
    return localStorage.getItem(this._HASH_KEY);
  }

  public getUUID(user?: any) {
    let id: string;
    if (user) {
      id = user._id;
    } else {
      id = this.getDeviceHash();
    }
    const now = Date.now();
    return `${id}-${now}`;
  }

  /**
   * API Routes for the User
   */

  public me(): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/me`);
  }

  public searchUser(query: any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/search`, { params: query });
  }

  public create(user: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}/new`, user);
  }

  public update(user: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}`, user);
  }

  public addPhone(id: string, phone: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${id}/add-phone`, phone);
  }

  public verifyPhone(id: string, body: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${id}/verify-phone`, body);
  }

  public updateForOrder(id: string, body: any): Observable<any> {
    return this._http.put<any>(`${this.baseUrl}/${id}/update-order`, body);
  }
}
