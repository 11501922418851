import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shiftAccepting',
})
export class ShiftAcceptingPipe implements PipeTransform {
  transform(restaurant: any): string {
    const accepting = [];
    if (!restaurant) {
      return null;
    }

    if (restaurant.isPublic && restaurant.acceptingOrders) {
      accepting.push('POS');
      accepting.push('Mobile');
    }
    if (!restaurant.isPublic || (restaurant.isPublic && !restaurant.acceptingOrders)) {
      accepting.push('POS Only');
    }
    if (restaurant.alcoholEnabled) {
      accepting.push('Alcohol');
    }

    return accepting.join(', ');
  }
}
