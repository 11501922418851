<ion-header>
  <ion-toolbar>
    <ion-title>Gratuity</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content scrollY="false">
  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center">
        <h1 class="title">Would you like to add a gratuity?</h1>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-text-center">
        <div class="subtitle">
          Gratuity for order #{{ order?.get('orderNumber') }} /
          {{ amount * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }} +
          {{ selectedGratuity?.gratuity | gratuity }}
        </div>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col *ngFor="let grat of gratOptions">
        <ion-button
          [id]="'select-gratuity-' + grat._id"
          [disabled]="!loaded"
          color="primary"
          expand="block"
          (click)="setGratuity(grat)"
        >
          {{ grat.name }}
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <div class="grat-amount">
          {{ selectedGratuity?.gratuity * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}
        </div>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-button
          id="gratuity-complete-payment"
          [disabled]="!loaded"
          color="secondary"
          expand="block"
          size="large"
          (click)="continueToPayment()"
        >
          {{ buttonText }} -
          {{ (amount + selectedGratuity?.gratuity) * 0.01 | currency : 'USD' : 'symbol' : '1.2-2' }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
