import { createAction, props } from '@ngrx/store';

export enum ShiftAction {
  LOAD_SHIFT = '[Shift] Load Shift',
  LOAD_SHIFT_SUCCESS = '[Shift] Load Shift Success',
  LOAD_ALL_SHIFTS = '[Shift] Load Shift',
  LOAD_ALL_SHIFTS_SUCCESS = '[Shift] Load All Shift Success',
  EXIT_SHIFT = '[Shift] Exit Shift',
}

export const loadShift = createAction(ShiftAction.LOAD_SHIFT);

export const exitShift = createAction(ShiftAction.EXIT_SHIFT);

export const retrievedShift = createAction(ShiftAction.LOAD_SHIFT_SUCCESS, props<{ shift: any }>());

export const retrievedAllShifts = createAction(
  ShiftAction.LOAD_ALL_SHIFTS_SUCCESS,
  props<{ shifts: any[] }>()
);
