/* eslint-disable class-methods-use-this */
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { select, Store } from '@ngrx/store';
import {
  Platform,
  NavController,
  AlertController,
  ToastController,
  ModalController,
} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Deploy } from 'cordova-plugin-ionic';

import { Observable } from 'rxjs';
import 'jquery';
import pkg from '../../package.json';
import { ShiftService } from './providers/shift/shift.service';
import { ModeService } from './providers/config/mode.service';
import { ApiClient } from './clients';
import { ApiDomain, ApiHeader, ApiResources, ApiVersion } from './enums';
import {
  AppState,
  exitShift,
  getPrinters,
  loadAllLocations,
  loadAllOrgs,
  loadAllStations,
  loadOrg,
  loadPointOfSale,
  loadShift,
  loadVenue,
  retrievedIntegrations,
  selectIntegrations,
  selectLocation,
  selectOrg,
  selectPointOfSale,
  selectShift,
  selectToast,
  setLiveMode,
} from './state';
import { IntegrationHelper, LocalStorage } from './helpers';
import { DeviceService, PaymentService, PointOfSaleService } from './providers';
import { UserService } from './providers/user/user.service';
import { IShift, Order, PointOfSale } from './models';
import { PrintService } from './providers/printer/print.service';
import { ConfirmModalComponent } from './shared/confirm-modal/confirm-modal.component';

declare let window: any;

type CentralState = Pick<
  AppState,
  'org' | 'location' | 'pointOfSale' | 'integrations' | 'shift' | 'liveMode' | 'orders'
>;

const getState = (state: AppState): CentralState => {
  return {
    pointOfSale: selectPointOfSale(state),
    integrations: selectIntegrations(state),
    shift: selectShift(state),
    org: selectOrg(state),
    liveMode: state.liveMode,
    orders: state.orders,
    location: selectLocation(state),
  };
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private platforms: string[] = [];

  private apiClient: ApiClient;

  private user: any;

  private centralState$: Observable<CentralState> = this.store.pipe(select(getState));

  private toast$: Observable<any> = this.store.pipe(select(selectToast));

  private openOrders: Order[] = [];

  public shiftOrders = 0;

  public dowloadingUpdate = false;

  public progress = 0;

  public progressState = 'Downloading';

  public version = pkg.version;

  public nativeAppVersion: string;

  public env = 'prod';

  public liveMode = 'true';

  public shift: IShift;

  public pointOfSale: PointOfSale;

  public appPages = [];

  public shiftPages = [];

  public basePages = [
    {
      group: 2,
      urls: [
        {
          title: 'Order History',
          url: '/orders/history',
          icon: 'pricetags',
          id: 'order-history',
        },
        {
          title: 'Daily Reports',
          url: '/shift/reports',
          icon: 'stats-chart',
          id: 'shift-reports',
        },
        {
          title: 'Menus',
          url: '/concessions',
          icon: 'pint',
          id: 'menus',
        },
      ],
    },
    {
      group: 3,
      urls: [
        {
          title: 'Help',
          url: '/help',
          icon: 'help-circle-outline',
          id: 'help',
        },
        {
          title: 'Settings',
          url: '/settings',
          icon: 'settings',
          id: 'settings',
        },
      ],
    },
  ];

  constructor(
    modeService: ModeService,
    httpClient: HttpClient,
    // Setup
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    // State
    private store: Store,
    private shiftService: ShiftService,
    private deviceService: DeviceService,
    private userService: UserService,
    private pointOfSaleService: PointOfSaleService,
    // Payment
    private paymentService: PaymentService,
    // Core
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private changeDetectorRef: ChangeDetectorRef,
    // Other
    private printService: PrintService
  ) {
    this.env = modeService.getMode();
    this.apiClient = new ApiClient(httpClient, this.env).setDomain(ApiDomain.API, ApiVersion.V2);
    this.user = this.userService.getMe();
    this.initializeApp();
  }

  private appHeight() {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  }

  async initializeApp() {
    this.platforms = this.platform.platforms();
    this.platform.ready().then(async () => {
      window.addEventListener('resize', this.appHeight.bind(this));
      this.appHeight();
      console.log('platform ready');
      console.log('Platforms -->', this.platforms);
      this.statusBar.styleDefault();
      // Initialize the app
      this.checkForUpdates();
      await this.initializeAccount();
      this.splashScreen.hide();
      await this.requestPrivacyPermission();
      this.nativeAppVersion = await this.pointOfSaleService.nativeAppVersion();
    });

    this.platform.resume.subscribe(async () => {
      console.log('platform resuming');
      this.checkForUpdates();
      this.nativeAppVersion = await this.pointOfSaleService.nativeAppVersion();
    });
  }

  private setSentryUser(user: any) {
    Sentry.setUser({ id: user._id, email: user.email });
  }

  async initializeAccount() {
    this.toast$.subscribe((toastOpts: { message: string; color: string }) => {
      (async () => {
        if (toastOpts) {
          const toast = await this.toastCtrl.create({
            ...toastOpts,
            position: 'top',
            duration: 6000,
          });
          await toast.present();
        }
      })();
    });
    if (this.user) {
      this.setSentryUser(this.user);
      this.store.dispatch(loadAllOrgs());
      const orgId = await LocalStorage.getItem(ApiHeader.ORG_ID);
      if (orgId) {
        this.watchState();
        await this.initDevice();
        await this.loadIntegrations();
        /**
         * @todo Move to a more central loading pattern to load the app's sections
         */
        this.store.dispatch(loadOrg({ orgId }));
        this.store.dispatch(loadVenue());
        this.store.dispatch(loadAllLocations());
        this.store.dispatch(loadShift());
        this.store.dispatch(getPrinters());
      }
    } else {
      const userListener = this.userService.getUserListener();
      userListener.subscribe(user => {
        this.user = user;
        this.initializeAccount();
      });
    }
  }

  private async requestPrivacyPermission() {
    try {
      if (this.platforms.includes('ios') && window.cordova?.plugins) {
        const idfaPlugin = window.cordova.plugins.idfa;
        if (idfaPlugin) {
          const info = await idfaPlugin.getInfo();
          if (info.trackingPermission === idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED) {
            const requestResult = await idfaPlugin.requestPermission();
            if (requestResult === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {
              console.log('Permission acquired');
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  private async checkForUpdates() {
    try {
      console.log('Checking for updates --->');
      const update = await Deploy.checkForUpdate();
      console.log('Update is available', JSON.stringify(update, null, 2));
      if (update.available) {
        this.dowloadingUpdate = true;
        const alert = await this.alertCtrl.create({
          backdropDismiss: false,
          keyboardClose: false,
          header: 'App Update',
          message: 'A new app update is available and will be downloaded. Please standby.',
        });
        await alert.present();
        this.progressState = 'Downloading';
        this.progress = 0;
        await Deploy.downloadUpdate(progress => {
          this.progress = progress;
          console.log('Update download progess:', progress);
          this.changeDetectorRef.detectChanges();
        }).catch(err => {
          console.log(err);
          Sentry.captureException(err);
        });
        this.changeDetectorRef.detectChanges();
        this.progressState = 'Extracting';
        this.progress = 0;
        await Deploy.extractUpdate(progress => {
          this.progress = progress;
          console.log('Extracting download progess:', progress);
          this.changeDetectorRef.detectChanges();
        }).catch(err => {
          console.log(err);
          Sentry.captureException(err);
        });
        this.changeDetectorRef.detectChanges();
        await alert.dismiss();
        this.dowloadingUpdate = false;
        await Deploy.reloadApp();
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  }

  private async loadIntegrations() {
    try {
      const integrationsResult: any = await this.apiClient
        .self({ isActive: true }, ApiResources.INTEGRATIONS)
        .toPromise();
      if (integrationsResult?.total) {
        this.store.dispatch(
          retrievedIntegrations({
            integrations: integrationsResult?.data,
          })
        );
      }
    } catch (err) {
      console.log('loadIntegrations ERROR:', err);
    }
  }

  private async initDevice() {
    try {
      const device = this.deviceService.getUUID();
      this.store.dispatch(loadPointOfSale({ deviceId: device }));
      this.store.dispatch(loadAllStations());
      this.liveMode = (await LocalStorage.getItem(ApiHeader.LIVE_MODE)) || 'true';
      this.store.dispatch(setLiveMode({ liveMode: this.liveMode as any }));
    } catch (err) {
      console.log('initDevice ERROR:', err);
    }
  }

  private async watchState() {
    this.centralState$.subscribe(data => {
      const { integrations, pointOfSale, shift, org, location, liveMode, orders } = data;
      this.pointOfSale = pointOfSale;
      this.shift = shift;
      this.liveMode = liveMode;
      this.openOrders = orders.data;
      if (this.shift && this.openOrders) {
        this.shiftOrders = orders.data.filter(order =>
          order.data.items.find(item => item.shift === this.shift._id)
        ).length;
      }
      const paymentI9n = IntegrationHelper.getPaymentI9n(integrations);
      /**
       * Only load the payment SDKs if the point of sale has a reader and a shift is active
       */
      if (
        location &&
        paymentI9n &&
        liveMode &&
        this.pointOfSale?.data.reader?.serialNumber &&
        this.shift
      ) {
        this.paymentService
          .initPaymentSdks(paymentI9n, liveMode, pointOfSale.id, shift._id)
          .then(() => {
            this.paymentService.startCheckReaderStatus(
              this.pointOfSale.id,
              this.pointOfSale?.data.reader
            );
          });
      } else {
        this.paymentService.stopCheckReaderStatus();
      }
      this.generateShiftPages(shift);
      if (this.shift) {
        (async () => {
          const locationId = (data.shift?.restaurant as any)._id || data.shift?.restaurant;
          await LocalStorage.addItem(ApiHeader.LOCATION_ID, locationId);
        })();
        Sentry.setContext('shift', {
          id: shift._id,
          name: shift.name,
          shiftId: shift.shiftId,
        });
      }
      if (org) {
        Sentry.setContext('org', {
          id: org.id,
          name: org.data.name,
        });
      }
      if (pointOfSale) {
        Sentry.setContext('pointOfSale', {
          id: pointOfSale.id,
          name: pointOfSale.data.name,
          deviceId: pointOfSale.data.deviceId,
        });
      }
    });
    this.pointOfSaleService.needsSetup().subscribe(() => {
      this.pointOfSaleService.registerPointOfSale();
    });
  }

  private generateShiftPages(shift: IShift) {
    if (shift) {
      const group = {
        group: 1,
        urls: [
          {
            title: 'Open Orders',
            url: '/orders',
            icon: 'pricetag',
            id: 'open-orders',
          },
          {
            title: 'Shift Summary',
            url: `/shift/${shift._id}`,
            icon: 'pie-chart',
            id: 'shift-summary',
          },
        ],
      };
      if (shift.items.length) {
        group.urls.push({
          title: 'Manage Inventory',
          url: `/shift/${shift._id}/edit-inventory`,
          icon: 'podium',
          id: 'manage-inventory',
        });
      }
      this.appPages = [group, ...this.basePages];
    } else {
      this.appPages = [];
    }
  }

  /**
   * @todo Some clean up here
   * - implement effects
   */
  public exitShift() {
    this.shiftService.setShift();
    this.store.dispatch(exitShift());
    this.navCtrl.navigateRoot(['/dashboard']);
  }

  public async closeShift() {
    if (!this.shift) {
      return;
    }
    if (this.shiftOrders) {
      const modal = await this.modalCtrl.create({
        component: ConfirmModalComponent,
        cssClass: 'confirm-modal',
        componentProps: {
          title: 'Open Orders',
          message: `There are ${this.shiftOrders} open orders related to this shift. Are you sure you want to close the shift?`,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (!data?.result) {
        return;
      }
    }
    this.navCtrl.navigateRoot(['/shift', this.shift._id, 'close']);
  }

  /**
   * @todo Cashdrawer logic might be moved to the backend if using a different printer setup.
   */
  public async popCashdrawer() {
    if (this.pointOfSale.data.cashDrawer?.port) {
      try {
        const result = await this.printService.openCashDrawer(this.pointOfSale.data.cashDrawer);
        console.log(result);
      } catch (e) {
        console.log('error.popCashdrawer');
        console.log(e);
      }
    }
  }
}
