export enum KdsMode {
  LOCATION = 'location',
  STATION = 'station',
  LOCATION_STATION = 'location-station',
}

export enum PrinterRole {
  RECEIPT = 'receipt',
  FULFILLMENT = 'fulfillment',
  SHIFT = 'shift',
}
