export enum ApiResources {
  // API
  ACTIVITY_RECORD = 'activity-record',
  ATTRACTIONS = 'attractions',
  ARENAS = 'arena',
  CATEGORY = 'category',
  CLASSIFICATIONS = 'classifications',
  CLOUDFLARE = 'cloudflare',
  COURSES = 'course',
  CUSTOM_CODE = 'custom-code',
  DINING_OPTIONS = 'dining-options',
  DISCOUNTS = 'discount',
  EMAILS = 'emails',
  EMPLOYEE = 'employee',
  EVENTS = 'events',
  FAVORITES = 'favorite',
  GAMES = 'game',
  GAME_ENTRIES = 'game-entry',
  GAME_RULES = 'game-rules',
  GUESTS = 'guests',
  GRATUITIES = 'gratuities',
  GRAPHQL = 'graphql',
  GRATUITY_MAPS = 'gratuity-map',
  HEADS_UP = 'heads-up',
  HELP = 'help',
  HEROKU = 'heroku',
  IMAGES = 'images',
  INTEGRATIONS = 'integrations',
  INVENTORY = 'inventory-item',
  INVENTORY_LOGS = 'inventory-log',
  LOCATION = 'location',
  MATCHES = 'match',
  MEMBER = 'member',
  MENU = 'menu',
  MENU_ITEMS = 'menu-item',
  MENU_ITEM_MODIFIER = 'menu-item-modifier',
  MENU_ITEM_STATION = 'menu-item-station',
  ORDERS = 'order',
  ORGS = 'org',
  PAYMENT_METHOD = 'payment-method',
  PAYMENTS = 'payment',
  PICK_EMS = 'pick-em',
  POSTS = 'post',
  POINT_OF_SALE = 'point-of-sale',
  PRINTER = 'printer',
  PRIZES = 'prizes',
  PRODUCT = 'product',
  PROMOTIONS = 'promotions',
  PROPS = 'props',
  MORE_LESS = 'more-less',
  PRICING_CONFIGS = 'pricing-config',
  PERIPHERALS = 'peripherals',
  PERIPHERAL_DEVICES = 'peripherals-device',
  RESTAURANTS = 'restaurant',
  REPORTING = 'reporting',
  SERVICE_CHARGE = 'service-charge',
  SHIFT = 'shift',
  STRIPE = 'stripe',
  STAND_SHEET = 'stand-sheet',
  SYNC_ERRORS = 'sync-errors',
  TAGS = 'tags',
  USER = 'user',
  USERS = 'users',
  TAX_CODES = 'tax-codes',
  TENDER_MAPS = 'tender-map',
  TERMINAL = 'terminal',
  TRIVIA = 'trivia',
  VENUES = 'venue',
  WHITE_LABELS = 'white-labels',

  /** @deprecated */
  REPORTS = 'reports',
}
