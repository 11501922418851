/* eslint-disable import/prefer-default-export */
export enum InventoryEventType {
  ORDER = 'order', // Items add to order
  TRANSFER = 'transfer', // When moving inventory from master to a concession
  MANUAL = 'manual', // Manual Override of Inventory Levels
  PURCHASE = 'purchase', // New Stock from Purchase Order,
  SHIFT = 'shift', // When adding items mid shift to the inventory
  SHIFT_OPEN = 'shift-open', // When adding items at start shift to the inventory
  SHIFT_CLOSE = 'shift-close', // When adding items end of shift to the inventory
}
