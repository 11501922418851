import { IntegrationSystems, IntegrationTypes } from '../enums/integrations';

export default class IntegrationHelper {
  static getI9nFromType(i9ns: any[], type: IntegrationTypes): any {
    return i9ns.find((i9n: any) => {
      return i9n.type === type;
    });
  }

  static getI9nFromSystem(i9ns: any[], system: IntegrationSystems): any {
    return i9ns.find((i9n: any) => {
      return i9n.system === system;
    });
  }

  static getPaymentI9n(i9ns: ReadonlyArray<any>): any {
    return i9ns.find((i9n: any) => {
      return i9n.type === IntegrationTypes.PAYMENT;
    });
  }
}
