import { Component, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { LoadingService } from '@app/providers/general/loading.service';
import { EmployeeService } from '@app/providers/employee/employee.service';

@Component({
  selector: 'app-auth-code-modal',
  templateUrl: './auth-code-modal.component.html',
  styleUrls: ['./auth-code-modal.component.scss'],
})
export class AuthCodeModalComponent {
  @Input() type = 'admin';

  public code = '';

  constructor(
    private _toastCtrl: ToastController,
    private _modalCtrl: ModalController,
    private _loading: LoadingService,
    private _employeeService: EmployeeService
  ) {}

  public addNum(val: string) {
    if (this.code.length < 4) {
      this.code += val;
    }
    if (this.code.length === 4) {
      this.checkAuth();
    }
  }

  public back() {
    this.code = this.code.slice(0, -1);
  }

  public dismiss(success: boolean) {
    this._modalCtrl.dismiss(success);
  }

  private async checkAuth() {
    await this._loading.show();
    try {
      const body = {
        accessCode: this.code,
        type: this.type,
      };
      const employeeResult = await this._employeeService.authenticate(body).toPromise();
      if (employeeResult?.data) {
        this.dismiss(employeeResult.data);
        return;
      }
      this.code = '';
      this.showToast('Invalid access code.');
    } catch (e) {
      console.log(e);
      this.code = '';
      const message = e.error ? e.error.message : 'An unexpected error occurred.';
      this.showToast(message);
    } finally {
      this._loading.hide();
    }
  }

  private async showToast(message: string) {
    const toast = await this._toastCtrl.create({
      message,
      color: 'warning',
      position: 'top',
      duration: 3000,
    });
    await toast.present();
  }
}
