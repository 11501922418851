import { createReducer, on } from '@ngrx/store';
import { MenuItemStation } from '../../../models';
import { loadedAllStations, selectStation } from './station.actions';

export const stationsInitialState: ReadonlyArray<MenuItemStation> = [];

export const stationsReducer = createReducer(
  stationsInitialState,
  on(loadedAllStations, (state, { stations }) => stations)
);

export const selectedStationsInitialState: Readonly<MenuItemStation> = null;

export const selectedStationReducer = createReducer(
  selectedStationsInitialState,
  on(selectStation, (state, { station }) => station)
);
