import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';
import { io } from 'socket.io-client';
import { OrderService } from './order.service';
import { ModeService } from '../config/mode.service';
import { LocalStorage } from '../../helpers';
import { ApiHeader, OrderStatus } from '../../enums';
import { OrderData } from '../../interfaces';
import { Order } from '../../models';

export interface NewOrderEvent {
  event: 'new-order';
  data: OrderData;
}

export interface ConnectionEvent {
  event: 'connection';
  data: boolean;
}

export interface JoinRoomEvent {
  event: 'joined-room';
  data: { room: string };
}

export interface NewMessageEvent {
  event: 'new-message';
  data: any;
}

export interface OrderStatusUpdateEvent {
  event: 'order-status-update';
  data: OrderData;
}

export type SocketResponse =
  | NewOrderEvent
  | ConnectionEvent
  | JoinRoomEvent
  | NewMessageEvent
  | OrderStatusUpdateEvent;

export type SocketQuery =
  | {
      venue: string;
    }
  | {
      restaurant: string;
    };

@Injectable({ providedIn: 'root' })
export class OrderSocketService {
  private _socketListener = new Subject<SocketResponse>();

  private _socket: any;

  private connected = false;

  private domain: string;

  private path = 'orders';

  private baseUrl: string;

  constructor(private _orderService: OrderService, private _modeService: ModeService) {
    const mode = this._modeService.getMode();
    this.domain = environment[mode].socket_url;
    this.baseUrl = `${this.domain}/${this.path}`;
  }

  get isConnected() {
    return this.connected;
  }

  public getSocketListener(): Observable<SocketResponse> {
    return this._socketListener.asObservable();
  }

  public setupSocket(query: SocketQuery) {
    if (!this._socket) {
      this._socket = io(this.baseUrl, {
        forceNew: false,
        query,
        withCredentials: false,
      });
      this.listenToEvents();
    }
  }

  private listenToEvents() {
    this._socket.on('connect', () => {
      this.connected = true;
      this._socketListener.next({ event: 'connection', data: true });
    });
    this._socket.on('disconnect', () => {
      this.connected = false;
      this._socketListener.next({ event: 'connection', data: false });
    });
    this._socket.on('joined-room', (data: JoinRoomEvent['data']) => {
      this._socketListener.next({ event: 'joined-room', data });
    });
    this._socket.on('new-order', (data: { order: OrderData }) => {
      this.newOrderHandler('new-order', data);
    });
    this._socket.on(
      'order-status-update',
      (data: { status: OrderStatus; order: Order['id']; expectedPickup?: string }) => {
        this.orderStatusHandler('order-status-update', data);
      }
    );
    this._socket.on('new-message', (message: any) => {
      this.newMessageHandler('new-message', message);
    });
  }

  public disconnect() {
    if (this._socket) {
      this._socket.disconnect();
      this._socket = null;
    }
  }

  private async newOrderHandler(event: 'new-order', data: { order: OrderData }) {
    try {
      const liveMode = (await LocalStorage.getItem(ApiHeader.LIVE_MODE)) || 'true';
      const liveModeBool = liveMode === 'true';
      const order = await this._orderService.findById(data.order._id).toPromise();
      if (order.liveMode !== liveModeBool) {
        return;
      }
      this._socketListener.next({ event, data: order });
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  private newMessageHandler(event: 'new-message', message: any) {
    this._socketListener.next({ event, data: message });
  }

  private async orderStatusHandler(
    event: 'order-status-update',
    data: {
      status: OrderStatus;
      order: Order['id'];
      expectedPickup?: string;
    }
  ) {
    try {
      const liveMode = (await LocalStorage.getItem(ApiHeader.LIVE_MODE)) || 'true';
      const order = await this._orderService.findById(data.order).toPromise();
      const liveModeBool = liveMode === 'true';
      if (order.liveMode !== liveModeBool) {
        return;
      }
      this._socketListener.next({ event, data: order });
    } catch (e) {
      Sentry.captureException(e);
    }
  }
}
