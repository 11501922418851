import { createReducer, on } from '@ngrx/store';
import { Org } from '../../../models';
import { retrevedAllOrgs, retrievedOrg } from './org.actions';

export const orgInitialState: Readonly<Org> = null;
export const orgsInitialState: Readonly<Org[]> = [];

export const orgReducer = createReducer(
  orgInitialState,
  on(retrievedOrg, (state, { org }) => org)
);

export const orgsReducer = createReducer(
  orgsInitialState,
  on(retrevedAllOrgs, (state, { orgs }) => orgs)
);
