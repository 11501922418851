import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthCodeModalComponent } from '@app/auth-code-modal/auth-code-modal.component';

@Injectable({ providedIn: 'root' })
export class AuthCodeService {
  constructor(private _modalCtrl: ModalController) {}

  public async show(type?: string): Promise<boolean> {
    const modal = await this._modalCtrl.create({
      component: AuthCodeModalComponent,
      cssClass: 'auth-code-modal',
      componentProps: {
        ...(type && { type }),
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    return data;
  }
}
