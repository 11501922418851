import _ from 'lodash';
import { DiningOptionAvailability, DiningOptionBehaviorAvailability } from '../interfaces';
import { DiningOption } from '../models';

export default class DiningOptionsHelper {
  static getDiningOptionAvailability(diningOptions: Array<DiningOption>): DiningOptionAvailability {
    const availability: DiningOptionAvailability = { now: [], future: [] };
    diningOptions?.forEach((diningOption: DiningOption) => {
      if (
        diningOption.get('asapSchedule.availableNow') ||
        _.includes(DiningOption.NOW_BEHAVIOURS, diningOption.get('behaviour'))
      ) {
        availability.now.push(diningOption);
      }
      if (_.size(diningOption.get('futureSchedule.dates'))) {
        availability.future.push(diningOption);
      }
    });
    return availability;
  }

  static getAvailableBehaviors(
    diningOptions: Array<DiningOption>
  ): DiningOptionBehaviorAvailability {
    const availability: DiningOptionBehaviorAvailability = {};
    diningOptions?.forEach((diningOption: DiningOption) => {
      const behavior = diningOption.get('behaviour');
      if (!availability[behavior]) {
        availability[behavior] = [];
      }
      availability[behavior].push(diningOption);
    });
    return availability;
  }

  static validateGuest(guest: any, diningOption: DiningOption): boolean {
    if (!diningOption) {
      return false;
    }
    const requiredFields = diningOption.get('requiredGuestFields') || [];
    console.log('requiredFields', requiredFields);
    return _.reduce(
      requiredFields,
      (prev: boolean, curr: string) => {
        if (!guest[curr]) {
          return false;
        }
        return prev;
      },
      true
    );
  }

  static hasAutoCollectingDiningOption(diningOptions: DiningOption[]): boolean {
    return !!diningOptions.find(diningOption => {
      return diningOption.autoCollectsInformation;
    });
  }
}
