export enum DiningOptionsBehavior {
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
  FAST_CLOSE = 'FAST-CLOSE',
  TAB = 'TAB',
  TABLE = 'TABLE',
  EVENT = 'EVENT',
}

export enum DiningOptionsOrderType {
  NOW = 'now',
  FUTURE = 'future',
}

export enum DeliveryLocationType {
  // ADDRESS = 'address',
  OTHER = 'other',
}

export enum PickupLocationType {
  ADDRESS = 'address',
  RESTAURANT = 'restaurant',
  OUTPOST = 'outpost',
}

export enum RequiredGuestFields {
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
}

export enum ServiceChargeBehavior {
  FIXED_AMOUNT = 'fixed-amount',
  PERCENTAGE_ON_TOTAL = 'percentage-on-total',
  PERCENTAGE_ON_SUB_TOTAL = 'percentage-on-sub-total',
  PERCENTAGE_PRE_TAX = 'percentage-pre-tax',
}
