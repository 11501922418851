import { createReducer, on } from '@ngrx/store';
import { DiningOption } from '../../../models';
import { retrievedDiningOptions } from './dining-options.actions';

export const diningOptionsInitialState: ReadonlyArray<DiningOption> = [];

export const diningOptionsReducer = createReducer(
  diningOptionsInitialState,
  on(retrievedDiningOptions, (state, { diningOptions }) => diningOptions)
);
