import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StripeCreditCardReaderService } from '@app/providers/payment/stripe-card-reader.service';
import { BaseComponent } from '../../base.component';
import { PointOfSaleActions } from '../../state';

@Component({
  selector: 'app-stripe-reader-connection-modal',
  templateUrl: './stripe-reader-connection-modal.component.html',
  styleUrls: ['./stripe-reader-connection-modal.component.scss'],
})
export class StripeReaderConnectionModalComponent extends BaseComponent implements OnInit {
  public connected = false;
  public hasError = false;
  public error: string;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _modalCtrl: ModalController,
    private _stripeReaderService: StripeCreditCardReaderService
  ) {
    super();
  }

  ngOnInit() {
    this.connect();
  }

  public async cancel() {
    this.store.dispatch({
      type: PointOfSaleActions.UPDATE_POINT_OF_SALE,
      update: { reader: null },
    });
    this._modalCtrl.dismiss();
  }

  private async connect() {
    try {
      const connectedReader = await this._stripeReaderService.discoverReaders();
      if (connectedReader) {
        this.connected = true;
      }
    } catch (e) {
      const message = e.message ? e.message : 'An unexpected error occured.';
      this.error = message;
      this.hasError = true;
    }
    this._changeDetectorRef.detectChanges();
  }

  public dismiss() {
    this._modalCtrl.dismiss({ isConnected: this.connected });
  }

  public retry() {
    this.hasError = false;
    this.error = '';
    this.connect();
  }
}
